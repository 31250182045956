import { BN, web3 } from 'fbonds-core'
import { LendingTokenType } from 'fbonds-core/lib/fbond-protocol/types'

import { OnboardingModalContentType } from '@banx/components/modals'

import { fetchJlpAprPercent } from '@banx/api/tokens'
import { IS_SDK_ON_DEV_CONTRACT } from '@banx/constants'
import {
  CreateLeverageTxnData,
  CreateSellToRepayTokenLoanTxnData,
  leverage,
  lrtsSOL,
  sanctum,
} from '@banx/transactions/leverage'
import { getTokenDecimals } from '@banx/utils'

export const MIN_MULTIPLIER_VALUE = 2

export type MultiplyPair = {
  collateralTicker: string
  collateralLogoUrl: string

  collateralMint: web3.PublicKey
  getCollateralYield?: () => Promise<BN>
  getNonJupConversionRate?: (connection: web3.Connection) => Promise<number>
  marketTokenType: LendingTokenType
  marketPublicKey: web3.PublicKey
  //? Max loan value user can borrow per 1 loan
  loanValueLimit?: BN
  //? Hardcoded collateral amount to calculate approx max net apr (on LeverageLanding page)
  amountToCalcMaxNetApr?: BN

  createLeverageTxnHandler: CreateLeverageTxnData
  createSellToRepayTxnHandler: CreateSellToRepayTokenLoanTxnData

  onboardingContent: OnboardingModalContentType
}

export const JLP_PAIR: MultiplyPair = {
  collateralTicker: 'JLP',
  collateralLogoUrl: 'https://static.jup.ag/jlp/icon.png',
  collateralMint: new web3.PublicKey('27G8MtK7VtTcCHkpASjSDdkWWYfoqT6ggEuKidVJidD4'),
  getCollateralYield: fetchJlpAprPercent,
  marketTokenType: LendingTokenType.Usdc,
  marketPublicKey: !IS_SDK_ON_DEV_CONTRACT
    ? new web3.PublicKey('ECxo4ZF9zyTGVXq42wwnKboX4hFNmAyhyqnyCgxVAm4S')
    : new web3.PublicKey('GHMX8me6S4RGi5fs3VRpTS7VSrA5AdtwrqfM3uhKYnpr'),
  loanValueLimit: undefined,
  amountToCalcMaxNetApr: new BN(1e6), //? 1 JLP
  createLeverageTxnHandler: leverage.createLeverageTxnData,
  createSellToRepayTxnHandler: leverage.createSellToRepayTokenLoanTxnData,
  onboardingContent: OnboardingModalContentType.MULTIPLY_JLP,
} as const

export const LRTS_PAIR: MultiplyPair = {
  collateralTicker: 'lrtsSOL',
  collateralLogoUrl:
    'https://wsrv.nl/?w=128&h=128&default=1&url=https%3A%2F%2Fi.degencdn.com%2Fipfs%2Fbafkreigoloautosntlteqccwusdjpxvtm3nm3kqvkavwxwickrk5irfety',
  collateralMint: lrtsSOL.LRTS_MINT,
  getCollateralYield: lrtsSOL.fetchSolayerApr,
  getNonJupConversionRate: lrtsSOL.getSolayerConversionRate,
  marketTokenType: LendingTokenType.BanxSol,
  marketPublicKey: !IS_SDK_ON_DEV_CONTRACT
    ? new web3.PublicKey('7EuPa26AjGdnQ7JcqM3kFhwFR4U2NQTU9guHcmaDF2G')
    : new web3.PublicKey('EJXya3FW1T8uZnxjtph7PxTeQJkb44eqYJwJHhTJhms3'),
  loanValueLimit: new BN(120 * getTokenDecimals(LendingTokenType.BanxSol)),
  amountToCalcMaxNetApr: new BN(1e8), //? 0.1 lrtsSOL
  createLeverageTxnHandler: lrtsSOL.createLrtsLeverageTxnData,
  createSellToRepayTxnHandler: lrtsSOL.createLrtsSellToRepayTokenLoanTxnData,
  onboardingContent: OnboardingModalContentType.MULTIPLY_LRTS,
} as const

export const HUBSOL_PAIR: MultiplyPair = {
  collateralTicker: 'hubSOL',
  collateralLogoUrl: 'https://arweave.net/RI0OfNg4Ldn5RRdOp9lE60NqUmweGtJxF5N8JjU_Y0k',
  collateralMint: new web3.PublicKey('HUBsveNpjo5pWqNkH57QzxjQASdTVXcSK7bVKTSZtcSX'),
  getCollateralYield: () =>
    sanctum.fetchSanctumLstApr('HUBsveNpjo5pWqNkH57QzxjQASdTVXcSK7bVKTSZtcSX'),
  marketTokenType: LendingTokenType.BanxSol,
  marketPublicKey: !IS_SDK_ON_DEV_CONTRACT
    ? new web3.PublicKey('GqwqiWtGLQ8K1xTKddGZJwjnRqnRt2hkpiH44xG3GV7P')
    : new web3.PublicKey('wLibZg57pXkVaKtB7ScnH65QLHbFx1CUBB5zDcPHgoa'),
  loanValueLimit: undefined,
  amountToCalcMaxNetApr: new BN(1e8), //? 0.1 hubSOL
  createLeverageTxnHandler: leverage.createLeverageTxnData,
  createSellToRepayTxnHandler: leverage.createSellToRepayTokenLoanTxnData,
  onboardingContent: OnboardingModalContentType.MULTIPLY_HUBSOL,
} as const

export const MULTIPLY_PAIRS = [LRTS_PAIR, JLP_PAIR, HUBSOL_PAIR] as const
