import { useWallet } from '@solana/wallet-adapter-react'
import { useNavigate } from 'react-router-dom'

import EmptyList from '@banx/components/EmptyList'
import { Loader } from '@banx/components/Loader'

import { MESSAGES } from '@banx/constants/messages'
import { PATHS } from '@banx/router'
import { buildUrlWithModeAndToken } from '@banx/store'
import { AssetMode } from '@banx/store/common'

import FilterSection from '../FilterSection'
import { HeaderList } from './components/HeaderList'
import OfferCard from './components/OfferCard'
import { useOffersContent } from './hooks'

import styles from './OffersTabContent.module.less'

const OffersTabContent = () => {
  const { connected } = useWallet()

  const {
    offersToDisplay,
    isLoading,
    marketsPreview,
    isNoOffers,
    isFilteredListEmpty,
    filteredListEmptyMessage,
    visibleOfferPubkey,
    onCardClick,
    sortParams,
    searchQuery,
    setSearchQuery,
    tokenType,
    setTokenType,
  } = useOffersContent()

  if (!connected) return <EmptyList message={MESSAGES.NO_CONNECTED_OFFERS} />

  return (
    <div className={styles.content}>
      <FilterSection
        sortParams={sortParams}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        selectedLendingToken={tokenType}
        handleSelectedTokenChange={setTokenType}
      />

      {!isNoOffers && !isFilteredListEmpty && !isLoading && <HeaderList />}

      {isLoading && <Loader />}
      {isNoOffers && <NoOffers />}
      {isFilteredListEmpty && !isNoOffers && <EmptyList message={filteredListEmptyMessage} />}

      {!isNoOffers && !isLoading && (
        <div className={styles.cardsList}>
          {offersToDisplay.map(({ offer }) => {
            const isOfferVisible = visibleOfferPubkey === offer.publicKey
            const currentMarket = marketsPreview.find(
              (market) => market.marketPubkey === offer.hadoMarket,
            )

            return (
              <OfferCard
                key={offer.publicKey}
                offer={offer}
                market={currentMarket}
                onToggleCard={() => onCardClick(offer.publicKey)}
                isOpen={isOfferVisible}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

export default OffersTabContent

const NoOffers = () => {
  const navigate = useNavigate()

  const goToLendPage = () => {
    navigate(buildUrlWithModeAndToken(PATHS.LEND, AssetMode.NFT, null))
  }

  return (
    <EmptyList
      message={MESSAGES.LENDER_NO_OFFERS}
      buttonProps={{ text: 'Lend', onClick: goToLendPage }}
    />
  )
}
