import { memo } from 'react'

import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { ViewState, useTableView } from '@banx/store/common'

import { Loader } from '../Loader'
import { TableProps } from './types'
import { CardView, SortView, TableView } from './views'

import styles from './Table.module.less'

const Table = <DataType extends object, SortType>({
  data,
  columns,
  sortViewParams,
  rowParams,
  showCard,
  loading,
  emptyMessage,
  className,
  classNameTableWrapper,
  loadMore,
  customJSX,
  loaderSize,
  loaderClassName,
}: TableProps<DataType, SortType>) => {
  const { viewState } = useTableView()

  const ViewComponent = showCard && ViewState.CARD === viewState ? CardView : TableView

  return (
    <>
      {sortViewParams && (
        <SortView columns={columns} showCard={showCard} customJSX={customJSX} {...sortViewParams} />
      )}

      {loading && <Loader className={loaderClassName} size={loaderSize} />}

      {emptyMessage && !loading && emptyMessage}

      {!loading && (
        <div className={classNames(styles.tableWrapper, classNameTableWrapper)}>
          {!isEmpty(data) && (
            <ViewComponent
              data={data}
              columns={columns}
              rowParams={rowParams}
              className={className}
              loadMore={loadMore}
            />
          )}
        </div>
      )}
    </>
  )
}

export default memo(Table) as typeof Table
