import { FC, SVGProps } from 'react'

export const SignOut: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
    <path
      d="M12.486 19.4934C16.7575 19.4934 20.2832 15.9678 20.2832 11.7038C20.2832 7.40974 16.7801 4.07241 12.8024 3.92927C12.26 3.89914 11.8758 4.20801 11.8758 4.68262C11.8758 5.11203 12.1771 5.4435 12.6744 5.47363C15.9966 5.6017 18.7162 8.22336 18.7237 11.7038C18.7313 15.1617 15.9439 17.9415 12.486 17.9415C8.88501 17.9415 6.30856 15.0336 6.2709 11.7942C6.25583 11.297 5.90176 10.9957 5.47988 10.9957C5.00527 10.9957 4.6964 11.3724 4.71147 11.9223C4.7868 15.9452 8.19193 19.4934 12.486 19.4934ZM11.8758 12.0127C12.3278 12.0202 12.6518 11.6812 12.6518 11.2367C12.6518 11.0333 12.5614 10.8299 12.4031 10.6717L8.56861 6.83719L7.22011 5.65444C7.9584 5.74484 8.87748 5.74484 9.24662 5.74484C9.63083 5.74484 9.9397 5.4209 9.9397 5.04423C9.9397 4.65248 9.63083 4.33608 9.24662 4.33608H5.87162C5.29154 4.33608 4.98267 4.64495 4.98267 5.2175V8.58496C4.98267 8.9767 5.29908 9.28558 5.68328 9.28558C6.06749 9.28558 6.3839 8.9767 6.3839 8.58496C6.3839 8.23842 6.37636 7.25154 6.3161 6.57352L7.47625 7.92202L11.3183 11.7641C11.4841 11.9298 11.6724 12.0127 11.8758 12.0127Z"
      fill="black"
    />
  </svg>
)
