import { useMemo, useState } from 'react'

import { filter, size } from 'lodash'

import { filterBySearchQuery } from '@banx/components/Search'

import { core } from '@banx/api/nft'
import { isFreezeLoan, isLoanListed } from '@banx/utils'

export const useFilterLoans = (loans: core.Loan[]) => {
  const [isAuctionFilterEnabled, setAuctionFilterState] = useState(true)
  const [isFreezeFilterEnabled, setFreezeFilterState] = useState(true)

  const [searchQuery, setSearchQuery] = useState<string>('')

  const toggleAuctionFilter = () => {
    setAuctionFilterState(!isAuctionFilterEnabled)
  }

  const toggleFreezeFilter = () => {
    setFreezeFilterState(!isFreezeFilterEnabled)
  }

  const filteredBySearchQuery = useMemo(() => {
    return filterBySearchQuery(loans, searchQuery, [
      (loan) => loan.nft.meta.collectionName,
      (loan) => loan.nft.meta.name,
      (loan) => loan.nft.mint,
    ])
  }, [loans, searchQuery])

  const filteredLoans = useMemo(() => {
    const applyFilter = (loans: core.Loan[]) => {
      const auctionLoans = filter(loans, (loan) => !isLoanListed(loan))
      const listedLoans = filter(loans, (loan) => isLoanListed(loan))
      const listedLoansWithoutFreeze = filter(listedLoans, (loan) => !isFreezeLoan(loan))

      if (!isFreezeFilterEnabled && !isAuctionFilterEnabled) return listedLoansWithoutFreeze
      if (!isAuctionFilterEnabled) return listedLoans
      if (!isFreezeFilterEnabled) return [...listedLoansWithoutFreeze, ...auctionLoans]

      return loans
    }

    return applyFilter(filteredBySearchQuery)
  }, [filteredBySearchQuery, isAuctionFilterEnabled, isFreezeFilterEnabled])

  const auctionLoansAmount = useMemo(
    () => size(filter(filteredBySearchQuery, (loan) => !isLoanListed(loan))) || null,
    [filteredBySearchQuery],
  )

  const freezeLoansAmount = useMemo(
    () => size(filter(filteredBySearchQuery, (loan) => isFreezeLoan(loan))) || null,
    [filteredBySearchQuery],
  )

  return {
    filteredLoans,

    searchQuery,
    setSearchQuery,

    auctionLoansAmount,
    freezeLoansAmount,

    isAuctionFilterEnabled,
    toggleAuctionFilter,

    isFreezeFilterEnabled,
    toggleFreezeFilter,
  }
}
