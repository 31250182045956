import { FC, useMemo } from 'react'

import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { BN, web3 } from 'fbonds-core'
import { BASE_POINTS } from 'fbonds-core/lib/fbond-protocol/constants'
import { BondOfferV3, LendingTokenType } from 'fbonds-core/lib/fbond-protocol/types'
import { chain, uniqueId } from 'lodash'
import { TxnExecutor } from 'solana-transactions-executor'

import { StatInfo, VALUES_TYPES } from '@banx/components/StatInfo'
import { DisplayValue } from '@banx/components/TableComponents'
import { Modal } from '@banx/components/modals/BaseModal'

import { convertBondOfferV3ToCore } from '@banx/api/nft'
import { core } from '@banx/api/tokens'
import { useTokenBondOffers } from '@banx/hooks'
import { useModal } from '@banx/store/common'
import { useTokenLoansOptimistic } from '@banx/store/token'
import {
  TXN_EXECUTOR_DEFAULT_OPTIONS,
  createExecutorWalletAndConnection,
  defaultTxnErrorHandler,
} from '@banx/transactions'
import {
  CreateBorrowTokenRefinanceTxnDataParams,
  createBorrowTokenRefinanceTxnData,
  parseBorrowTokenRefinanceSimulatedAccounts,
} from '@banx/transactions/tokenLending'
import {
  calculateOfferSize,
  destroySnackbar,
  enqueueConfirmationError,
  enqueueSnackbar,
  enqueueTransactionSent,
  enqueueWaitingConfirmation,
} from '@banx/utils'

import { useSelectedTokenLoans } from '../../loansCart'
import OrderBook from './OrderBook'
import { getCurrentLoanInfo } from './helpers'

import styles from './RefinanceTokenModal.module.less'

interface RefinanceTokenModalProps {
  loan: core.TokenLoan
}

const RefinanceTokenModal: FC<RefinanceTokenModalProps> = ({ loan }) => {
  const wallet = useWallet()
  const { connection } = useConnection()

  const { close: closeModal } = useModal()

  const lendingToken = loan.bondTradeTransaction.lendingToken

  const { offers, updateOrAddOptimisticOffer, isLoading } = useTokenBondOffers({
    marketPubkey: loan.fraktBond.hadoMarket
      ? new web3.PublicKey(loan.fraktBond.hadoMarket)
      : undefined,
    lendingTokenType: lendingToken,
    excludeWallet: wallet.publicKey || undefined,
  })

  const { update: updateLoansOptimistic } = useTokenLoansOptimistic()

  const { clear: clearSelection } = useSelectedTokenLoans()

  const refinance = async (offer: BondOfferV3, tokensToRefinance: BN) => {
    const loadingSnackbarId = uniqueId()

    try {
      const walletAndConnection = createExecutorWalletAndConnection({ wallet, connection })

      const txnData = await createBorrowTokenRefinanceTxnData(
        {
          loan,
          offer: convertBondOfferV3ToCore(offer),
          solToRefinance: tokensToRefinance,
          aprRate: offer.loanApr,
          tokenType: lendingToken,
        },
        walletAndConnection,
      )

      await new TxnExecutor<CreateBorrowTokenRefinanceTxnDataParams>(
        walletAndConnection,
        TXN_EXECUTOR_DEFAULT_OPTIONS,
      )
        .addTxnData(txnData)
        .on('sentSome', (results) => {
          results.forEach(({ signature }) => enqueueTransactionSent(signature))
          enqueueWaitingConfirmation(loadingSnackbarId)
        })
        .on('confirmedAll', (results) => {
          const { confirmed, failed } = results

          destroySnackbar(loadingSnackbarId)

          if (failed.length) {
            return failed.forEach(({ signature, reason }) =>
              enqueueConfirmationError(signature, reason),
            )
          }

          return confirmed.forEach(({ params, accountInfoByPubkey, signature }) => {
            if (accountInfoByPubkey && wallet?.publicKey) {
              enqueueSnackbar({
                message: 'Loan successfully refinanced',
                type: 'success',
                solanaExplorerPath: `tx/${signature}`,
              })

              const { bondOffer, bondTradeTransaction, fraktBond } =
                parseBorrowTokenRefinanceSimulatedAccounts(accountInfoByPubkey)

              const optimisticLoan = {
                ...params.loan,
                publicKey: fraktBond.publicKey,
                bondTradeTransaction,
                fraktBond: {
                  ...fraktBond,
                  hadoMarket: params.loan.fraktBond.hadoMarket,
                },
              }

              updateOrAddOptimisticOffer(bondOffer)
              updateLoansOptimistic([optimisticLoan], wallet.publicKey.toBase58())
              clearSelection()
              closeModal()
            }
          })
        })
        .on('error', (error) => {
          throw error
        })
        .execute()
    } catch (error) {
      destroySnackbar(loadingSnackbarId)
      defaultTxnErrorHandler(error, {
        additionalData: loan,
        walletPubkey: wallet?.publicKey?.toBase58(),
        transactionName: 'RefinanceTokenBorrow',
      })
    }
  }

  const { currentLoanDebt, currentLoanBorrowedAmount, currentApr, marketUpfrontFee } =
    getCurrentLoanInfo(loan)

  const filteredOffers = useMemo(() => {
    const upfrontFee = new BN(currentLoanDebt)
      .mul(new BN(marketUpfrontFee))
      .div(new BN(BASE_POINTS))

    return (
      chain(offers)
        //? Filter out user offers
        .filter((offer) => wallet?.publicKey?.toBase58() !== offer.assetReceiver.toBase58())
        //? Filter out offers which can't cover upfront fee of prev loan
        .filter((offer) => upfrontFee.lte(calculateOfferSize(convertBondOfferV3ToCore(offer))))
        .sortBy((offer) => offer.validation.collateralsPerToken.toNumber())
        .value()
    )
  }, [currentLoanDebt, marketUpfrontFee, offers, wallet?.publicKey])

  return (
    <Modal open onCancel={closeModal} width={572} className={styles.refinanceModal}>
      <h4 className={styles.refinanceModalTitle}>Current loan</h4>

      <LoansInfoStats
        apr={currentApr}
        borrowedAmount={currentLoanBorrowedAmount}
        debt={currentLoanDebt}
        lendingToken={lendingToken}
        liquidationLtvBp={loan.liquidationLtvBp}
      />

      <h4 className={styles.refinanceModalSubtitle}>New loan</h4>

      <OrderBook loan={loan} offers={filteredOffers} refinance={refinance} isLoading={isLoading} />
    </Modal>
  )
}

export default RefinanceTokenModal

interface LoansInfoStats {
  borrowedAmount: number //? lamports
  debt: number //? lamports
  apr: number //? base points
  liquidationLtvBp: number //? base points
  lendingToken: LendingTokenType
}

const LoansInfoStats: FC<LoansInfoStats> = ({
  borrowedAmount,
  debt,
  apr,
  liquidationLtvBp,
  lendingToken,
}) => {
  const statsClassName = {
    container: styles.loanInfoStat,
    label: styles.loanInfoLabel,
    value: styles.loanInfoValue,
  }

  return (
    <div className={styles.loanInfoStats}>
      <StatInfo
        label="Borrowed"
        value={<DisplayValue value={borrowedAmount} strictTokenType={lendingToken} />}
        classNamesProps={statsClassName}
      />

      <StatInfo
        label="APR"
        valueType={VALUES_TYPES.PERCENT}
        value={apr / 100}
        classNamesProps={statsClassName}
      />
      {!!liquidationLtvBp && (
        <StatInfo
          label="Liq. LTV"
          value={liquidationLtvBp / 100}
          valueType={VALUES_TYPES.PERCENT}
          classNamesProps={statsClassName}
        />
      )}
      <StatInfo
        label="Debt"
        value={<DisplayValue value={debt} strictTokenType={lendingToken} />}
        classNamesProps={statsClassName}
      />
    </div>
  )
}
