import { FC, SVGProps } from 'react'

export const BanxToken: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_12481_10400)">
      <rect width="14" height="14" rx="7" fill="#98EC2D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.28547 10.7979C9.55426 10.7979 10.5828 9.7631 10.5828 8.48659C10.5828 7.21008 9.55426 6.17527 8.28547 6.17527C7.01669 6.17527 5.98813 7.21008 5.98813 8.48659C5.98813 9.7631 7.01669 10.7979 8.28547 10.7979ZM8.28547 11.8667C10.141 11.8667 11.6452 10.3534 11.6452 8.48659C11.6452 6.61979 10.141 5.10645 8.28547 5.10645C6.42997 5.10645 4.92578 6.61979 4.92578 8.48659C4.92578 10.3534 6.42997 11.8667 8.28547 11.8667Z"
        fill="#1B1B1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6944 3.17377C11.2821 3.74812 11.7385 4.45773 12.0139 5.25265C11.1998 4.45137 10.0856 3.95744 8.85679 3.95744C6.56123 3.95744 4.66579 5.68103 4.37926 7.91208C4.35454 7.71965 4.3418 7.52343 4.3418 7.32423C4.3418 4.81548 6.36324 2.78174 8.85679 2.78174C9.51119 2.78174 10.1331 2.92181 10.6944 3.17377Z"
        fill="#1B1B1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1424 8.3515C12.2512 7.92469 12.3091 7.47733 12.3091 7.01638C12.3091 6.64951 12.2724 6.29125 12.2026 5.94511C11.4548 5.09396 10.3908 4.52225 9.17626 4.42553C8.97887 4.40981 8.78345 4.40711 8.59082 4.41671C10.4989 4.82084 11.928 6.42164 12.1424 8.3515Z"
        fill="#1B1B1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.85754 2.50525C9.50152 2.4237 9.13095 2.38063 8.75038 2.38063C6.00746 2.38063 3.78388 4.61775 3.78388 7.37737C3.78388 9.83926 5.55355 11.8853 7.88286 12.2981C7.59073 12.3481 7.29049 12.3741 6.98422 12.3741C4.04329 12.3741 1.65918 9.9755 1.65918 7.01665C1.65918 4.0578 4.04329 1.65918 6.98422 1.65918C8.04246 1.65918 9.02863 1.96975 9.85754 2.50525Z"
        fill="#1B1B1B"
      />
    </g>
    <defs>
      <clipPath id="clip0_12481_10400">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
