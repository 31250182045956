import { FC, SVGProps } from 'react'

export const BanxSOL: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="1.3125" y="1.3125" width="11.375" height="11.375" rx="5.6875" fill="#98EC2D" />
    <g clipPath="url(#clip0_16662_216717)">
      <path
        d="M4.86678 8.49759C4.90492 8.45378 4.95735 8.42822 5.01297 8.42822H10.0565C10.1486 8.42822 10.1947 8.55601 10.1295 8.63086L9.13325 9.77547C9.09511 9.81928 9.04267 9.84484 8.98706 9.84484H3.94357C3.85141 9.84484 3.80533 9.71705 3.87048 9.6422L4.86678 8.49759Z"
        fill="#1B1B1B"
      />
      <path
        d="M4.86666 4.22416C4.90638 4.18034 4.95882 4.15479 5.01285 4.15479H10.0563C10.1485 4.15479 10.1946 4.28257 10.1294 4.35742L9.13312 5.50203C9.09499 5.54584 9.04255 5.5714 8.98694 5.5714H3.94345C3.85129 5.5714 3.80521 5.44361 3.87036 5.36877L4.86666 4.22416Z"
        fill="#1B1B1B"
      />
      <path
        d="M9.13325 6.3472C9.09511 6.30339 9.04267 6.27783 8.98706 6.27783H3.94357C3.85141 6.27783 3.80533 6.40562 3.87048 6.48047L4.86678 7.62508C4.90492 7.66889 4.95735 7.69445 5.01297 7.69445H10.0565C10.1486 7.69445 10.1947 7.56666 10.1295 7.49181L9.13325 6.3472Z"
        fill="#1B1B1B"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.224 6.99995C12.224 9.88513 9.88513 12.224 6.99995 12.224C4.11478 12.224 1.77588 9.88513 1.77588 6.99995C1.77588 4.11478 4.11478 1.77588 6.99995 1.77588C9.88513 1.77588 12.224 4.11478 12.224 6.99995ZM6.99995 11.8027C9.65245 11.8027 11.8027 9.65245 11.8027 6.99995C11.8027 4.34745 9.65245 2.19718 6.99995 2.19718C4.34745 2.19718 2.19718 4.34745 2.19718 6.99995C2.19718 9.65245 4.34745 11.8027 6.99995 11.8027Z"
      fill="#1B1B1B"
    />
    <defs>
      <clipPath id="clip0_16662_216717">
        <rect width="6.31944" height="5.6902" fill="white" transform="translate(3.84033 4.15479)" />
      </clipPath>
    </defs>
  </svg>
)
