import { MarketCategory } from '@banx/api/tokens'

export const NFT_MARKETS_WITH_CUSTOM_APR: Record<string, number> = {
  ['oeN7bBJAjFqnvFwALPBazEFJeTi9F6tLvKHhuoJjSkk']: 3380, //? ONE collection PUBLIC market
  ['2RSYRoqZ2bKnKGeLtGFyTmDLNEaV9Z9zhmZd5qXxAsr9']: 3380, //? ONE collection PRIVATE market

  ['HpzJyoNhfwbtco6Z8ghtqf85cDwFpYWqkBo1X1ah8cwX']: 3380, //? Lifinity collection PUBLIC market
  ['FFhTvrX3L3QJwZrbjb2BQxV1PXccXfW8cWpz7VVyD3nM']: 3380, //? Lifinity collection PRIVATE market

  ['4XNFTPM6v5fDySb3nsmfcozc2Cf12Xsmvy3nufNfoWUS']: 3000, //? Cloak DAO collection PUBLIC market
  ['HuKW1yTTLFcTdt9cKvGxSBjYhmQgyAXpgyEwqdxAZS7e']: 3000, //? Cloak DAO collection PRIVATE market

  ['3Mo3Go4L2tr94s1FWpktEZpA6n4TH7k2jkVb9F5Fr2Sb']: 3380, //? Pathfinders collection PUBLIC market
  ['94wXzpkKgBPRLCf2nAhhAsy5Zo2tBBBFEAY1dF5wP4ZT']: 3380, //? Pathfinders collection PRIVATE market
}

export const FACELESS_MARKET_PUBKEY = 'BzxKw3JLmPt7aagkHnRQEMQ4Le1rZdwEib5Viuig42hu'

export const MARKETS_CATEGORIES = [
  { key: MarketCategory.All, label: 'All' },
  { key: MarketCategory.LST, label: 'LST' },
  { key: MarketCategory.DeFi, label: 'DeFi' },
  { key: MarketCategory.Meme, label: 'Memes' },
  { key: MarketCategory.Governance, label: 'Governance' },
  { key: MarketCategory.RWA, label: 'RWA' },
  { key: MarketCategory.LP, label: 'LP' },
  { key: MarketCategory.DePin, label: 'DePin' },
  { key: MarketCategory.Gaming, label: 'Gaming' },
]
