import { useRef } from 'react'

import { useWallet } from '@solana/wallet-adapter-react'
import classNames from 'classnames'

import { useOnClickOutside, useWalletAdapters } from '@banx/hooks'
import { CloseModal } from '@banx/icons'
import { useModal } from '@banx/store/common'

import { ClaimSection, EscrowVault } from './LenderVaults'
import { SidebarFooter, WalletAssets, WalletDetails, WalletList } from './components'
import { useWalletSidebar } from './hooks'

import styles from './WalletAccountSidebar.module.less'

export const WalletAccountSidebar = () => {
  const { connected } = useWallet()

  const { visible: isWalletSidebarVisible, setVisible } = useWalletSidebar()
  const { visible: isModalVisible } = useModal()

  const wallets = useWalletAdapters({
    onWalletSelect: () => setVisible(false),
  })

  const containerRef = useRef(null)
  useOnClickOutside(containerRef, () => {
    if (isWalletSidebarVisible && !isModalVisible) {
      setVisible(false)
    }
  })

  return (
    <>
      <div
        ref={containerRef}
        className={classNames(styles.walletSidebarContainer, {
          [styles.visible]: isWalletSidebarVisible,
        })}
      >
        {connected && <WalletAccountOverview />}
        {!connected && <WalletList wallets={wallets} />}
        <SidebarFooter />
      </div>

      <div
        className={classNames(styles.sidebarOverlay, { [styles.visible]: isWalletSidebarVisible })}
      />
    </>
  )
}

const WalletAccountOverview = () => (
  <>
    <CloseIconComponent />
    <div className={styles.accountOverviewContainer}>
      <WalletDetails />
      <EscrowVault />
      <ClaimSection />
    </div>
    <WalletAssets />
  </>
)

const CloseIconComponent = () => {
  const { visible: isVisible, setVisible } = useWalletSidebar()

  return (
    <div
      onClick={() => setVisible(false)}
      className={classNames(styles.closeIcon, { [styles.visible]: isVisible })}
    >
      <CloseModal />
    </div>
  )
}
