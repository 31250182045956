import { useCallback, useEffect, useMemo } from 'react'

import { useWallet } from '@solana/wallet-adapter-react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

import { Button } from '@banx/components/Buttons'
import {
  FilterDropdown,
  MARKET_OPTIONS_WITHOUT_ALL,
  TokenDropdown,
} from '@banx/components/Dropdowns'
import EmptyList from '@banx/components/EmptyList'
import { Search } from '@banx/components/Search'
import Table from '@banx/components/Table'
import Tooltip from '@banx/components/Tooltip'

import { core } from '@banx/api/nft'
import { MESSAGES } from '@banx/constants/messages'
import { Underwater } from '@banx/icons'
import { isLoanAbleToTerminate } from '@banx/pages/nftLending/OffersPage'
import { PATHS } from '@banx/router'
import { buildUrlWithModeAndToken } from '@banx/store'
import { AssetMode, ViewState, useTableView, useTokenType } from '@banx/store/common'
import {
  isLoanLiquidated,
  isLoanListed,
  isLoanRepaymentCallActive,
  isLoanTerminating,
  isUnderWaterLoan,
} from '@banx/utils'

import { Summary } from './Summary'
import { getTableColumns } from './columns'
import { useLoansTable } from './hooks'
import { useSelectedLoans } from './loansState'

import styles from './LoansTable.module.less'

export const LoansTable = () => {
  const { publicKey, connected } = useWallet()
  const walletPubkey = publicKey?.toBase58() || ''

  const { viewState } = useTableView()
  const { tokenType, setTokenType } = useTokenType()

  const {
    loans,
    loading,
    isNoLoans,
    filteredListEmptyMessage,
    sortViewParams,
    hideLoans,
    updateOrAddLoan,
    searchQuery,
    setSearchQuery,
    isUnderwaterFilterActive,
    onToggleUnderwaterFilter,
    underwaterLoansCount,
    loansToClaim,
    loansToTerminate,
  } = useLoansTable()

  const {
    selection,
    toggle: toggleLoanInSelection,
    find,
    clear: clearSelection,
    set: setSelection,
  } = useSelectedLoans()

  //? Clear selection when tokenType changes
  //? To prevent selection transfering from one tokenType to another
  useEffect(() => {
    clearSelection()
  }, [clearSelection, tokenType])

  const walletSelectedLoans = useMemo(() => {
    if (!walletPubkey) return []
    return selection.filter(({ wallet }) => wallet === walletPubkey).map(({ loan }) => loan)
  }, [selection, walletPubkey])

  const hasSelectedLoans = useMemo(() => !!walletSelectedLoans?.length, [walletSelectedLoans])

  const onSelectAll = useCallback(() => {
    return hasSelectedLoans ? clearSelection() : setSelection(loansToTerminate, walletPubkey)
  }, [hasSelectedLoans, clearSelection, setSelection, loansToTerminate, walletPubkey])

  const findLoanInSelection = useCallback(
    (loanPubkey: string) => {
      return find(loanPubkey, walletPubkey)
    },
    [find, walletPubkey],
  )

  const onRowClick = useCallback(
    (loan: core.Loan) => {
      if (!isLoanAbleToTerminate(loan) || isLoanListed(loan)) return
      toggleLoanInSelection(loan, walletPubkey)
    },
    [toggleLoanInSelection, walletPubkey],
  )

  const columns = getTableColumns({
    onSelectAll,
    findLoanInSelection,
    toggleLoanInSelection: onRowClick,
    hasSelectedLoans,
    isCardView: viewState === ViewState.CARD,
  })

  const rowParams = useMemo(() => {
    return {
      onRowClick,
      activeRowParams: [
        {
          condition: (loan: core.Loan) => isLoanTerminating(loan),
          className: styles.terminated,
          cardClassName: styles.terminated,
        },
        {
          condition: (loan: core.Loan) => isLoanLiquidated(loan),
          className: styles.liquidated,
          cardClassName: styles.liquidated,
        },
        {
          condition: (loan: core.Loan) =>
            isUnderWaterLoan(loan) && !isLoanRepaymentCallActive(loan),
          className: styles.underwater,
          cardClassName: styles.underwater,
        },
        {
          condition: (loan: core.Loan) => isLoanRepaymentCallActive(loan),
          className: styles.activeRepaymentCall,
          cardClassName: styles.activeRepaymentCall,
        },
      ],
    }
  }, [onRowClick])

  const customJSX = (
    <>
      <TokenDropdown
        option={tokenType}
        options={MARKET_OPTIONS_WITHOUT_ALL}
        onChange={setTokenType}
      />
      <FilterDropdown>
        <div className={styles.filterButtonsContainer}>
          <span className={styles.filterButtonsTitle}>Tags</span>
          <Tooltip
            title={underwaterLoansCount ? 'Underwater loans' : 'No underwater loans currently'}
          >
            <div
              className={styles.filterButtonWrapper}
              data-underwater-loans={underwaterLoansCount}
            >
              <Button
                className={classNames(
                  styles.filterButton,
                  { [styles.active]: isUnderwaterFilterActive },
                  { [styles.disabled]: !underwaterLoansCount },
                )}
                disabled={!underwaterLoansCount}
                onClick={onToggleUnderwaterFilter}
                type="circle"
                variant="tertiary"
              >
                <Underwater />
                Underwater
              </Button>
            </div>
          </Tooltip>
        </div>
      </FilterDropdown>
      <Search value={searchQuery} onChange={setSearchQuery} />
    </>
  )

  if (!connected) return <EmptyList message={MESSAGES.NO_CONNECTED_LOANS} />

  return (
    <div className={styles.tableRoot}>
      <Table
        data={loans}
        columns={columns}
        rowParams={rowParams}
        sortViewParams={sortViewParams}
        loading={loading}
        customJSX={customJSX}
        className={styles.table}
        emptyMessage={isNoLoans ? <NoLoans /> : <EmptyList message={filteredListEmptyMessage} />}
        showCard
      />
      {!isNoLoans && !filteredListEmptyMessage && (
        <Summary
          loansToClaim={loansToClaim}
          loansToTerminate={loansToTerminate}
          updateOrAddLoan={updateOrAddLoan}
          selectedLoans={walletSelectedLoans}
          setSelection={setSelection}
          hideLoans={hideLoans}
        />
      )}
    </div>
  )
}

const NoLoans = () => {
  const navigate = useNavigate()

  const goToLendPage = () => {
    navigate(buildUrlWithModeAndToken(PATHS.LEND, AssetMode.NFT, null))
  }

  return (
    <EmptyList
      message={MESSAGES.LENDER_NO_LOANS}
      buttonProps={{ text: 'Lend', onClick: goToLendPage }}
    />
  )
}
