import { FC, SVGProps } from 'react'

export const LogoFull: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="101"
    height="30"
    viewBox="0 0 101 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M35.4774 9.52434L29.0658 15.0038L30.7268 17.7654L33.7566 22.7984L33.7657 22.8112L32.0229 24.2972L25.3435 30H0L0.0420217 29.9128L3.49885 22.8548L11.1557 7.21449H17.4103L9.78442 22.7855H16.4349L24.7221 22.7907L24.7199 22.7855L20.0311 14.9987L22.4131 12.966L27.9334 8.24623L29.1432 7.21449H17.4103L17.4412 7.14776L18.3348 5.32297L20.9357 0.0128326H20.991L23.5897 0H30.3839L30.3861 0.00513332L33.8542 0.00769961L34.993 1.89666L38.1316 7.10154L38.1933 7.2042L35.4774 9.52434Z"
      fill="#1B1B1B"
    />
    <path
      d="M47.1221 18.3848H52.2835C52.6132 18.3848 52.884 18.0171 52.884 17.6493C52.884 17.2816 52.6132 16.9138 52.2835 16.9138H47.1221V18.3848ZM51.5661 12.6233H47.1221V14.1025H51.5661C51.8955 14.1025 52.1666 13.7266 52.1666 13.3588C52.1666 12.9911 51.8955 12.6233 51.5661 12.6233ZM54.6265 15.3202C55.7394 15.7206 56.486 16.6686 56.486 18.0497C56.508 19.9703 55.0583 21 53.3673 21H43.2856V10.0163H52.6424C54.3483 10.0082 55.7906 11.0461 55.7686 12.9584C55.7686 14.0126 55.3294 14.8217 54.6265 15.3202ZM63.9687 13.1954L62.6142 16.4398H65.3157L63.9687 13.1954ZM56.4056 21L61.6843 10.0163H66.2456L71.5243 21H67.2047L66.4286 19.1204H61.5013L60.7253 21H56.4056ZM81.4226 15.8187V10H85.2665V20.9837H80.9468L75.6607 14.2741V20.9837H71.8242V10H77.1029L81.4226 15.8187ZM93.4443 13.2199L96.2045 10.0163H101L95.8385 15.5082L101 21H96.2045L93.4443 17.7883L90.6842 21H85.8815L91.043 15.5082L85.8815 10.0163H90.6842L93.4443 13.2199Z"
      fill="#1B1B1B"
    />
  </svg>
)
