import { FC, useState } from 'react'

import classNames from 'classnames'

import { Button } from '@banx/components/Buttons'
import CategoryDropdown from '@banx/components/CategoryDropdown'
import { SearchSelect, SearchSelectProps } from '@banx/components/SearchSelect'
import { SortDropdown, SortDropdownProps } from '@banx/components/SortDropdown'
import Tooltip from '@banx/components/Tooltip'

import { MarketCategory } from '@banx/api/tokens'
import { Fire, StarSecondary } from '@banx/icons'

import { SortField } from '../../hooks'

import styles from './FilterSection.module.less'

interface FilterSectionProps<T> {
  searchSelectParams: SearchSelectProps<T>
  sortParams: SortDropdownProps<SortField>
  selectedCategory: MarketCategory
  onChangeCategory: (category: MarketCategory) => void
  isHotFilterActive: boolean
  onToggleHotFilter: () => void
  isDisabledHotFilter: boolean
  isExtraRewardFilterActive: boolean
  onToggleExtraRewardFilter: () => void
  isDisabledExtraRewardFilter: boolean
}

const FilterSection = <T extends object>({
  searchSelectParams,
  sortParams,
  selectedCategory,
  onChangeCategory,
  isHotFilterActive,
  onToggleHotFilter,
  isDisabledHotFilter,
  isExtraRewardFilterActive,
  onToggleExtraRewardFilter,
  isDisabledExtraRewardFilter,
}: FilterSectionProps<T>) => {
  const [searchSelectCollapsed, setSearchSelectCollapsed] = useState(true)

  return (
    <div className={styles.container}>
      <div className={styles.filterContent}>
        <SearchSelect
          {...searchSelectParams}
          className={styles.searchSelect}
          collapsed={searchSelectCollapsed}
          onChangeCollapsed={setSearchSelectCollapsed}
          disabled={!searchSelectParams.options.length}
        />

        <CategoryDropdown
          selectedOption={selectedCategory}
          onChange={onChangeCategory}
          className={!searchSelectCollapsed ? styles.dropdownHidden : ''}
        />

        <HotFilterButton
          isActive={isHotFilterActive}
          isDisabled={isDisabledHotFilter}
          onClick={onToggleHotFilter}
        />

        <ExtraRewardFilterButton
          isActive={isExtraRewardFilterActive}
          isDisabled={isDisabledExtraRewardFilter}
          onClick={onToggleExtraRewardFilter}
        />
      </div>

      <SortDropdown
        {...sortParams}
        className={!searchSelectCollapsed ? styles.dropdownHidden : ''}
      />
    </div>
  )
}
export default FilterSection

interface FilterButtonProps {
  isActive: boolean
  isDisabled: boolean
  onClick: () => void
}

const HotFilterButton: FC<FilterButtonProps> = ({ isActive, isDisabled, onClick }) => (
  <Tooltip
    className={styles.hidden}
    title={isDisabled ? 'No hot collections currently' : 'Hot collections'}
  >
    <>
      <Button
        className={classNames(styles.filterButton, {
          [styles.active]: isActive,
          [styles.disabled]: isDisabled,
        })}
        onClick={onClick}
        disabled={isDisabled}
        variant="secondary"
        type="circle"
      >
        <Fire />
      </Button>
    </>
  </Tooltip>
)

const ExtraRewardFilterButton: FC<FilterButtonProps> = ({ isActive, isDisabled, onClick }) => (
  <Tooltip
    className={styles.hidden}
    title={isDisabled ? 'No markets with extra rewards' : 'Markets with extra rewards'}
  >
    <>
      <Button
        className={classNames(styles.filterButton, {
          [styles.active]: isActive,
          [styles.disabled]: isDisabled,
        })}
        onClick={onClick}
        disabled={isDisabled}
        variant="secondary"
        type="circle"
      >
        <StarSecondary />
      </Button>
    </>
  </Tooltip>
)
