import { isEmpty } from 'lodash'

import { MESSAGES } from '@banx/constants/messages'

import { useWalletLoansAndOffers } from '../../../hooks'
import { useFilterLoans } from './useFilteredLoans'
import { useSortLoans } from './useSortLoans'

export const useLoansActiveTable = () => {
  const { loans, offers, isLoading } = useWalletLoansAndOffers()

  const {
    filteredLoans,
    searchQuery,
    setSearchQuery,
    isTerminationFilterEnabled,
    toggleTerminationFilter,
    terminatingLoansAmount,
    isRepaymentCallFilterEnabled,
    toggleRepaymentCallFilter,
    repaymentCallsAmount,
  } = useFilterLoans(loans)

  const { sortedLoans, sortParams } = useSortLoans(filteredLoans)

  const isNoLoans = !isLoading && isEmpty(loans)
  const isFilteredListEmpty = !isLoading && isEmpty(filteredLoans)

  const filteredListEmptyMessage = (() => {
    if (isFilteredListEmpty && searchQuery) return MESSAGES.EMPTY_SEARCH_RESULTS
    if (isFilteredListEmpty) return MESSAGES.EMPTY_FILTERED_LIST
    return ''
  })()

  return {
    loans: sortedLoans,
    offers,
    loading: isLoading,

    isNoLoans,
    filteredListEmptyMessage,

    searchQuery,
    setSearchQuery,

    terminatingLoansAmount,
    isTerminationFilterEnabled,
    toggleTerminationFilter,

    repaymentCallsAmount,
    isRepaymentCallFilterEnabled,
    toggleRepaymentCallFilter,

    sortViewParams: { sortParams },
  }
}
