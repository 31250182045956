import { FC, SVGProps } from 'react'

export const Wallet: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6425 3.82184H2.52625C2.24659 3.79774 1.9871 3.66636 1.80214 3.45522C1.66488 3.34862 1.5722 3.19476 1.54215 3.02359C1.52437 2.39748 2.2429 1.96484 2.89288 1.96484H11.2501C11.6191 1.96618 11.9725 2.11338 12.2334 2.37432C12.4942 2.63526 12.6413 2.98876 12.6425 3.35772V3.82184ZM13.1066 4.75059H2.42875C2.18247 4.75046 1.94632 4.65257 1.77217 4.47842C1.59803 4.30427 1.50013 4.06812 1.5 3.82184V12.6432C1.50027 13.0125 1.64712 13.3666 1.90829 13.6277C2.16946 13.8887 2.52359 14.0354 2.89288 14.0356H13.1066C13.4759 14.0354 13.83 13.8887 14.0912 13.6277C14.3524 13.3666 14.4992 13.0125 14.4995 12.6432V11.2503H12.1784C11.6858 11.2503 11.2134 11.0547 10.8651 10.7064C10.5168 10.3581 10.3211 9.88566 10.3211 9.39309C10.3211 8.90051 10.5168 8.42812 10.8651 8.07981C11.2134 7.73151 11.6858 7.53584 12.1784 7.53584H14.4995V6.14347C14.4994 5.7741 14.3526 5.41989 14.0914 5.15871C13.8302 4.89752 13.476 4.75073 13.1066 4.75059ZM11.2506 9.39334C11.2509 9.63953 11.3489 9.87556 11.523 10.0496C11.6971 10.2236 11.9332 10.3215 12.1794 10.3216H14.5V8.4651H12.1794C11.9331 8.46523 11.697 8.56313 11.5228 8.73727C11.3487 8.91142 11.2508 9.14757 11.2506 9.39385V9.39334Z"
      fill="#1B1B1B"
    />
  </svg>
)
