import { useWallet } from '@solana/wallet-adapter-react'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'

import { MARKET_OPTIONS_WITHOUT_ALL, TokenDropdown } from '@banx/components/Dropdowns'
import EmptyList from '@banx/components/EmptyList'
import Table from '@banx/components/Table'

import { MESSAGES } from '@banx/constants/messages'
import { PATHS } from '@banx/router'
import { buildUrlWithModeAndToken } from '@banx/store'
import { AssetMode } from '@banx/store/common'

import { Summary } from './Summary'
import { getTableColumns } from './columns'
import { useHistoryOffersTable } from './hooks'

import styles from './HistoryOffersTable.module.less'

export const HistoryOffersTable = () => {
  const { connected } = useWallet()

  const { loans, sortViewParams, loading, isNoLoans, tokenType, setTokenType, loadMore } =
    useHistoryOffersTable()

  const columns = getTableColumns()

  if (!connected) return <EmptyList message={MESSAGES.NOT_CONNECTED_ACTIVITY} />

  const customJSX = (
    <TokenDropdown
      option={tokenType}
      options={MARKET_OPTIONS_WITHOUT_ALL}
      onChange={setTokenType}
    />
  )

  return (
    <div className={styles.tableRoot}>
      <Table
        data={loans}
        columns={columns}
        sortViewParams={sortViewParams}
        className={styles.table}
        loadMore={loadMore}
        loading={loading}
        emptyMessage={isNoLoans ? <NoLoans /> : undefined}
        customJSX={customJSX}
        showCard
      />
      {!isEmpty(loans) && !loading && <Summary />}
    </div>
  )
}

const NoLoans = () => {
  const navigate = useNavigate()

  const goToLendPage = () => {
    navigate(buildUrlWithModeAndToken(PATHS.LEND, AssetMode.NFT, null))
  }

  return (
    <EmptyList
      message={MESSAGES.NO_LENDING_ACTIVITY}
      buttonProps={{ text: 'Lend', onClick: goToLendPage }}
    />
  )
}
