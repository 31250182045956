import { FC, SVGProps } from 'react'

export const Underwater: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <g clipPath="url(#clip0_9562_80987)">
      <g clipPath="url(#clip1_9562_80987)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.708 4.125H6.3403C6.18642 4.125 6.03706 4.19574 5.92391 4.31701L3.08608 7.4852C2.90052 7.69237 3.03177 8.04608 3.29428 8.04608H10.8345C10.39 7.48341 10.1248 6.77269 10.1248 6C10.1248 5.30368 10.3402 4.65767 10.708 4.125ZM20.4244 12.8152L20.914 13.3618C21.0995 13.569 20.9683 13.9227 20.7058 13.9227H6.34015C6.18174 13.9227 6.03238 13.8519 5.92375 13.7306L3.08593 10.5625C2.90036 10.3553 3.03162 10.0016 3.29413 10.0016H14.6962V10.5714C14.6962 12.3959 16.1752 13.875 17.9998 13.875C18.958 13.875 19.821 13.467 20.4244 12.8152ZM19.1606 11.4043L18.0761 10.1936C17.9675 10.0723 17.8182 10.0016 17.6597 10.0016H16.5712V10.5714C16.5712 11.3604 17.2108 12 17.9998 12C18.478 12 18.9013 11.765 19.1606 11.4043ZM20.9817 4.57143H19.4283V4.125H16.5712V4.57143H13.4283C12.6393 4.57143 11.9998 5.21102 11.9998 6C11.9998 6.78898 12.6393 7.42857 13.4283 7.42857H16.5712V8.04608H17.6599C17.8183 8.04608 17.9677 7.97534 18.0763 7.85407L20.9141 4.68588C20.9453 4.65107 20.9675 4.61213 20.9817 4.57143ZM6.34015 15.954C6.18174 15.954 6.03238 16.0247 5.92375 16.146L3.08593 19.3142C2.90036 19.5213 3.03162 19.875 3.29413 19.875H17.6597C17.8182 19.875 17.9675 19.8043 18.0761 19.683L20.914 16.5148C21.0995 16.3077 20.9683 15.954 20.7058 15.954H6.34015Z"
          fill="#1B1B1B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 0C18.789 0 19.4286 0.639593 19.4286 1.42857V4.57143H22.5714C23.3604 4.57143 24 5.21102 24 6C24 6.78898 23.3604 7.42857 22.5714 7.42857H19.4286V10.5714C19.4286 11.3604 18.789 12 18 12C17.211 12 16.5714 11.3604 16.5714 10.5714V7.42857H13.4286C12.6396 7.42857 12 6.78898 12 6C12 5.21102 12.6396 4.57143 13.4286 4.57143H16.5714V1.42857C16.5714 0.639593 17.211 0 18 0Z"
          fill="#D4B106"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_9562_80987">
        <rect width="24" height="24" fill="white" />
      </clipPath>
      <clipPath id="clip1_9562_80987">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
