import { FC, useMemo, useState } from 'react'

import { useWallet } from '@solana/wallet-adapter-react'
import { isEmpty, orderBy } from 'lodash'

import EmptyList from '@banx/components/EmptyList'
import { Loader } from '@banx/components/Loader'
import { filterBySearchQuery } from '@banx/components/Search'
import { SortOption } from '@banx/components/SortDropdown'

import { BorrowNft, MarketPreview } from '@banx/api/nft'
import { MESSAGES } from '@banx/constants/messages'
import { useTokenType } from '@banx/store/common'

import { useBorrowNftsAndMarketsQuery, useMaxLoanValueByMarket } from '../hooks'
import { FilterSection } from './components/FilterSection'
import { HeaderList } from './components/HeaderList'
import { MarketBorrowCard } from './components/MarketBorrowCard'

import styles from './InstantLoansContent.module.less'

type InstantLoansContentProps = {
  goToRequestLoanTab: () => void
}

export const InstantLoansContent: FC<InstantLoansContentProps> = ({ goToRequestLoanTab }) => {
  const { connected } = useWallet()

  const { marketsPreview, nftsByMarket, userVaults, offersByMarket, isLoading } =
    useBorrowNftsAndMarketsQuery()

  const maxLoanValueByMarket = useMaxLoanValueByMarket({ offersByMarket, userVaults })

  const { tokenType, setTokenType } = useTokenType()

  const [expandedMarketPublicKey, setExpandedMarketPublicKey] = useState('')
  const [searchQuery, setSearchQuery] = useState<string>('')

  const handleCardToggle = (marketPubkey: string) => {
    setExpandedMarketPublicKey((prev) => (prev === marketPubkey ? '' : marketPubkey))
  }

  const filteredBySearchQuery = useMemo(() => {
    return filterBySearchQuery(marketsPreview, searchQuery, [(preview) => preview.collectionName])
  }, [marketsPreview, searchQuery])

  const isNoMarkets = isEmpty(marketsPreview) && !isLoading
  const isFilteredListEmpty = isEmpty(filteredBySearchQuery) && !isLoading

  const filteredListEmptyMessage = (() => {
    if (isFilteredListEmpty && searchQuery) return MESSAGES.EMPTY_SEARCH_RESULTS
    if (isFilteredListEmpty) return MESSAGES.EMPTY_FILTERED_LIST
    return ''
  })()

  const { sortedMarkets, sortParams } = useSortedMarkets(
    filteredBySearchQuery,
    nftsByMarket,
    maxLoanValueByMarket,
  )

  if (!connected) return <EmptyList message="Connect wallet to view your nfts" />

  return (
    <div className={styles.content}>
      <FilterSection
        sortParams={sortParams}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        selectedLendingToken={tokenType}
        handleSelectedTokenChange={setTokenType}
      />

      {!isNoMarkets && !isFilteredListEmpty && !isLoading && <HeaderList />}

      {isLoading && <Loader />}
      {isNoMarkets && <EmptyList message="You don't have any whitelisted NFTs" />}
      {isFilteredListEmpty && !isNoMarkets && <EmptyList message={filteredListEmptyMessage} />}

      {!isNoMarkets && (
        <div className={styles.cardsList}>
          {sortedMarkets.map((preview) => (
            <MarketBorrowCard
              key={preview.marketPubkey}
              maxLoanValue={maxLoanValueByMarket[preview.marketPubkey] || 0}
              marketPreview={preview}
              onClick={() => handleCardToggle(preview.marketPubkey)}
              isExpanded={expandedMarketPublicKey === preview.marketPubkey}
              goToRequestLoanTab={goToRequestLoanTab}
              nftsAmount={nftsByMarket[preview.marketPubkey].length}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export enum SortField {
  LOAN_VALUE = 'loanValue',
  NFTS_AMOUNT = 'nftsAmount',
}

const SORT_OPTIONS: SortOption<SortField>[] = [
  { label: 'Top offer', value: [SortField.LOAN_VALUE, 'desc'] },
  { label: 'NFTs amount', value: [SortField.NFTS_AMOUNT, 'desc'] },
]

export const useSortedMarkets = (
  markets: MarketPreview[],
  nftsByMarket: Record<string, BorrowNft[]>,
  maxLoanValueByMarket: Record<string, number>,
) => {
  const [sortOption, setSortOption] = useState(SORT_OPTIONS[0])

  const sortedMarkets = useMemo(() => {
    if (!sortOption) return markets

    const [field, order] = sortOption.value

    return orderBy(
      markets,
      (market) => {
        if (field === SortField.LOAN_VALUE) return maxLoanValueByMarket[market.marketPubkey] || 0
        if (field === SortField.NFTS_AMOUNT) return nftsByMarket[market.marketPubkey].length || 0
        return 0
      },
      order,
    )
  }, [sortOption, markets, nftsByMarket, maxLoanValueByMarket])

  const onChangeSortOption = (option: SortOption<SortField>) => {
    setSortOption(option)
  }

  return {
    sortedMarkets,
    sortParams: {
      option: sortOption,
      onChange: onChangeSortOption,
      options: SORT_OPTIONS,
    },
  }
}
