import { FC } from 'react'

import { BN, web3 } from 'fbonds-core'
import { calcBorrowerTokenAPR } from 'fbonds-core/lib/fbond-protocol/helpers'

import { DisplayValue, createPercentValueJSX } from '@banx/components/TableComponents'

import { ZERO_BN, adjustTokenAmountWithUpfrontFee } from '@banx/utils'

import { EnhancedBorrowOffer } from '../hooks'

import styles from './OrderBook.module.less'

interface BorrowCellProps {
  offer: EnhancedBorrowOffer
  selectedOffer: EnhancedBorrowOffer | null
  remainingBorrowAmount: BN
}

export const BorrowCell: FC<BorrowCellProps> = ({
  offer,
  selectedOffer,
  remainingBorrowAmount,
}) => {
  const selectedAmount = selectedOffer ? new BN(selectedOffer.maxTokenToGet) : ZERO_BN
  const availableAmount = BN.min(remainingBorrowAmount, new BN(offer.maxTokenToGet))

  const borrowValue = selectedAmount.isZero() ? availableAmount : selectedAmount
  const adjustedBorrowValueToDisplay = adjustTokenAmountWithUpfrontFee(borrowValue)

  return (
    <div className={styles.borrowValueContainer}>
      <DisplayValue value={adjustedBorrowValueToDisplay.toNumber()} />
    </div>
  )
}

interface AprCellProps {
  offer: EnhancedBorrowOffer
  marketPubkey: string
}

export const AprCell: FC<AprCellProps> = ({ offer, marketPubkey }) => {
  const aprPercent =
    calcBorrowerTokenAPR(parseFloat(offer.apr), new web3.PublicKey(marketPubkey)) / 100

  return <span className={styles.aprValue}>{createPercentValueJSX(aprPercent)}</span>
}
