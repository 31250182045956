import { FC, useCallback, useEffect, useMemo } from 'react'

import { useWallet } from '@solana/wallet-adapter-react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

import { Button } from '@banx/components/Buttons'
import {
  FilterDropdown,
  MARKET_OPTIONS_WITHOUT_ALL,
  TokenDropdown,
} from '@banx/components/Dropdowns'
import EmptyList from '@banx/components/EmptyList'
import { Search } from '@banx/components/Search'
import Table from '@banx/components/Table'
import Tooltip from '@banx/components/Tooltip'

import { core } from '@banx/api/nft'
import { MESSAGES } from '@banx/constants/messages'
import { Coin, Warning } from '@banx/icons'
import { PATHS } from '@banx/router'
import { buildUrlWithModeAndToken } from '@banx/store'
import { AssetMode, ViewState, useTableView, useTokenType } from '@banx/store/common'
import { isLoanRepaymentCallActive, isLoanTerminating } from '@banx/utils'

import { Summary } from './Summary'
import { getTableColumns } from './columns'
import { useLoansActiveTable } from './hooks'
import { useSelectedLoans } from './loansState'

import styles from './LoansActiveTable.module.less'

export const LoansActiveTable = () => {
  const { publicKey, connected } = useWallet()
  const walletPubkey = publicKey?.toBase58() || ''

  const { tokenType, setTokenType } = useTokenType()

  const {
    loans,
    offers,
    loading,
    searchQuery,
    setSearchQuery,
    sortViewParams,
    isNoLoans,
    filteredListEmptyMessage,
    isTerminationFilterEnabled,
    terminatingLoansAmount,
    toggleTerminationFilter,
    repaymentCallsAmount,
    isRepaymentCallFilterEnabled,
    toggleRepaymentCallFilter,
  } = useLoansActiveTable()

  const {
    selection,
    toggle: toggleLoanInSelection,
    find,
    clear: clearSelection,
    set: setSelection,
  } = useSelectedLoans()

  //? Clear selection when tokenType changes
  //? To prevent selection transfering from one tokenType to another
  useEffect(() => {
    clearSelection()
  }, [clearSelection, tokenType])

  const walletSelectedLoans = useMemo(() => {
    if (!walletPubkey) return []
    return selection.filter(({ wallet }) => wallet === walletPubkey)
  }, [selection, walletPubkey])

  const hasSelectedLoans = useMemo(() => !!walletSelectedLoans?.length, [walletSelectedLoans])

  const { viewState } = useTableView()

  const onSelectAll = useCallback(() => {
    if (hasSelectedLoans) {
      clearSelection()
    } else {
      setSelection(loans, walletPubkey)
    }
  }, [clearSelection, hasSelectedLoans, loans, setSelection, walletPubkey])

  const findLoanInSelection = useCallback(
    (loanPubkey: string) => {
      return find(loanPubkey, walletPubkey)
    },
    [find, walletPubkey],
  )

  const onRowClick = useCallback(
    (loan: core.Loan) => {
      toggleLoanInSelection(loan, walletPubkey)
    },
    [toggleLoanInSelection, walletPubkey],
  )

  const columns = getTableColumns({
    onSelectAll,
    findLoanInSelection,
    toggleLoanInSelection: onRowClick,
    hasSelectedLoans,
    isCardView: viewState === ViewState.CARD,
    offers,
  })

  const rowParams = useMemo(() => {
    return {
      onRowClick,
      activeRowParams: [
        {
          condition: isLoanTerminating,
          className: styles.terminated,
          cardClassName: styles.terminated,
        },
        {
          condition: isLoanRepaymentCallActive,
          className: styles.repaymentCallActive,
          cardClassName: styles.repaymentCallActive,
        },
      ],
    }
  }, [onRowClick])

  const customJSX = (
    <>
      <TokenDropdown
        option={tokenType}
        options={MARKET_OPTIONS_WITHOUT_ALL}
        onChange={setTokenType}
      />
      <FilterDropdown>
        <div className={styles.filterButtonsContainer}>
          <span className={styles.filterButtonsTitle}>Tags</span>
          <div className={styles.filterButtons}>
            <TerminatingFilterButton
              loansAmount={terminatingLoansAmount}
              isActive={isTerminationFilterEnabled}
              onClick={toggleTerminationFilter}
            />
            <RepaymentCallFilterButton
              loansAmount={repaymentCallsAmount}
              isActive={isRepaymentCallFilterEnabled}
              onClick={toggleRepaymentCallFilter}
            />
          </div>
        </div>
      </FilterDropdown>
      <Search value={searchQuery} onChange={setSearchQuery} />
    </>
  )

  if (!connected) return <EmptyList message={MESSAGES.NO_CONNECTED_LOANS} />

  return (
    <div className={styles.tableRoot}>
      <Table
        data={loans}
        columns={columns}
        rowParams={rowParams}
        sortViewParams={sortViewParams}
        className={styles.table}
        customJSX={customJSX}
        loading={loading}
        emptyMessage={isNoLoans ? <NoLoans /> : <EmptyList message={filteredListEmptyMessage} />}
        showCard
      />
      {!isNoLoans && !filteredListEmptyMessage && !loading && (
        <Summary loans={loans} selectedLoans={walletSelectedLoans} setSelection={setSelection} />
      )}
    </div>
  )
}

const NoLoans = () => {
  const navigate = useNavigate()

  const goToBorrowPage = () => {
    navigate(buildUrlWithModeAndToken(PATHS.BORROW, AssetMode.NFT, null))
  }

  return (
    <EmptyList
      message={MESSAGES.BORROWER_NO_LOANS}
      buttonProps={{ text: 'Borrow', onClick: goToBorrowPage }}
    />
  )
}

interface FilterButtonProps {
  onClick: () => void
  isActive: boolean
  loansAmount: number | null
}

const RepaymentCallFilterButton: FC<FilterButtonProps> = ({ isActive, onClick, loansAmount }) => (
  <Tooltip title={loansAmount ? 'Repayment calls' : 'No repayment calls currently'}>
    <div
      className={classNames(styles.filterButtonWrapper, styles.repaymentCall)}
      data-loans-amount={loansAmount}
    >
      <Button
        className={classNames(
          styles.repaymentCallFilterButton,
          { [styles.active]: isActive },
          { [styles.disabled]: !loansAmount },
        )}
        disabled={!loansAmount}
        onClick={onClick}
        variant="tertiary"
        type="circle"
      >
        <Coin />
        Repayment call
      </Button>
    </div>
  </Tooltip>
)

const TerminatingFilterButton: FC<FilterButtonProps> = ({ isActive, onClick, loansAmount }) => (
  <Tooltip title={loansAmount ? 'Terminating loans' : 'No terminating loans currently'}>
    <div
      className={classNames(styles.filterButtonWrapper, styles.terminating)}
      data-loans-amount={loansAmount}
    >
      <Button
        className={classNames(
          styles.terminatingFilterButton,
          { [styles.active]: isActive },
          { [styles.disabled]: !loansAmount },
        )}
        disabled={!loansAmount}
        onClick={onClick}
        variant="tertiary"
        type="circle"
      >
        <Warning />
        Terminating
      </Button>
    </div>
  </Tooltip>
)
