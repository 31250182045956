import { FC } from 'react'

import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'

import { Button } from '@banx/components/Buttons'

import { UserPortfolio } from '@banx/api/common'
import { PATHS } from '@banx/router'
import { buildUrlWithModeAndToken } from '@banx/store'
import { useAssetMode, useTokenType } from '@banx/store/common'

import { Header, PositionsList, StatsSection } from './components'

import styles from './MyPositions.module.less'

interface MyPositionsProps {
  positions: UserPortfolio['positions'] | undefined
  isLoading: boolean
}

const MyPositions: FC<MyPositionsProps> = ({ positions, isLoading }) => {
  const { stats, list } = positions ?? {}
  const { netPnl = 0, apy = 0, funds = 0, totalNetPnl = 0 } = stats ?? {}

  const navigate = useNavigate()
  const { tokenType } = useTokenType()
  const { currentAssetMode } = useAssetMode()

  const goToLeveragePage = () => {
    navigate(buildUrlWithModeAndToken(PATHS.LEVERAGE_BASE, currentAssetMode, tokenType))
  }

  const goToPositionsPage = () => {
    navigate(buildUrlWithModeAndToken(PATHS.POSITIONS, currentAssetMode, tokenType))
  }

  const actionButtonProps = {
    onClick: isEmpty(list) ? goToLeveragePage : goToPositionsPage,
    text: isEmpty(list) ? 'Start leveraging assets' : 'Manage my positions',
  }

  return (
    <div className={styles.container}>
      <Header totalNetPnl={totalNetPnl} />
      <StatsSection netPnl={netPnl} apy={apy} funds={funds} />
      <PositionsList positions={list} totalFunds={funds} isLoading={isLoading} />
      <Button onClick={actionButtonProps.onClick} className={styles.manageButton}>
        {actionButtonProps.text}
      </Button>
    </div>
  )
}

export default MyPositions
