import { useEffect, useMemo, useState } from 'react'

import { MarketCategory, MarketTokenRewards, TokenMarketPreview } from '@banx/api/tokens'
import { createGlobalState } from '@banx/store'
import { useTokenType } from '@banx/store/common'

const useCollectionsStore = createGlobalState<string[]>([])

export const useFilteredMarkets = (
  markets: TokenMarketPreview[],
  allRewardsData: Record<string, MarketTokenRewards>,
) => {
  const { tokenType } = useTokenType()

  const [selectedCollections, setSelectedCollections] = useCollectionsStore()
  const [selectedCategory, setSelectedCategory] = useState<MarketCategory>(MarketCategory.All)

  const [isExtraRewardFilterActive, setIsExtraRewardFilterActive] = useState(false)
  const [isHotFilterActive, setIsHotFilterActive] = useState(false)

  const onChangeCategory = (category: MarketCategory) => {
    setSelectedCategory(category)
  }

  const onToggleExtraRewardFilter = () => {
    setIsExtraRewardFilterActive((prev) => !prev)
  }

  const onToggleHotFilter = () => {
    setIsHotFilterActive((prev) => !prev)
  }

  //? Reset extra rewards filter when tokenType changes
  useEffect(() => {
    setIsExtraRewardFilterActive(false)
  }, [tokenType])

  const filteredHotMarkets = useMemo(() => {
    if (!isHotFilterActive) return markets

    return markets.filter((market) => market.isHot)
  }, [markets, isHotFilterActive])

  const filteredExtraRewardsMarkets = useMemo(() => {
    if (!isExtraRewardFilterActive) return filteredHotMarkets

    return filteredHotMarkets.filter((market) => !!allRewardsData[market.marketPubkey])
  }, [filteredHotMarkets, isExtraRewardFilterActive, allRewardsData])

  const filteredByCategory = useMemo(() => {
    if (selectedCategory === MarketCategory.All) return filteredExtraRewardsMarkets

    return filteredExtraRewardsMarkets.filter((market) =>
      market.marketCategory.includes(selectedCategory),
    )
  }, [filteredExtraRewardsMarkets, selectedCategory])

  const filteredMarkets = useMemo(() => {
    if (!selectedCollections.length) return filteredByCategory

    return filteredByCategory.filter((market) =>
      selectedCollections.includes(market.collateral.ticker),
    )
  }, [filteredByCategory, selectedCollections])

  const isDisabledExtraRewardFilter = useMemo(
    () => !filteredMarkets.some((market) => allRewardsData[market.marketPubkey]),
    [filteredMarkets, allRewardsData],
  )

  const isDisabledHotFilter = useMemo(
    () => !filteredMarkets.some((market) => market.isHot),
    [filteredMarkets],
  )

  return {
    filteredMarkets,
    filteredByCategory,

    selectedCategory,
    onChangeCategory,

    selectedCollections,
    setSelectedCollections,

    isHotFilterActive,
    onToggleHotFilter,
    isDisabledHotFilter,

    isExtraRewardFilterActive,
    onToggleExtraRewardFilter,
    isDisabledExtraRewardFilter,
  }
}
