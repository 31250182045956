import { FC, SVGProps } from 'react'

export const USDC: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4951_37305)">
      <path
        d="M8 14.5C11.6021 14.5 14.5 11.6021 14.5 8C14.5 4.3979 11.6021 1.5 8 1.5C4.3979 1.5 1.5 4.3979 1.5 8C1.5 11.6021 4.3979 14.5 8 14.5Z"
        fill="#2775CA"
      />
      <path
        d="M9.78735 9.02915C9.78735 8.08126 9.2186 7.75626 8.0811 7.62086C7.2686 7.51251 7.1061 7.29586 7.1061 6.91665C7.1061 6.53744 7.37695 6.29376 7.9186 6.29376C8.4061 6.29376 8.67695 6.45626 8.81235 6.86251C8.83945 6.94376 8.9207 6.9979 9.00195 6.9979H9.43524C9.5436 6.9979 9.62485 6.91665 9.62485 6.80836V6.78126C9.51649 6.1854 9.02899 5.72501 8.4061 5.67086V5.02086C8.4061 4.91251 8.32485 4.83126 8.18945 4.80415H7.7832C7.67485 4.80415 7.5936 4.8854 7.56649 5.02086V5.64376C6.75399 5.75211 6.23945 6.29376 6.23945 6.97086C6.23945 7.86461 6.7811 8.21665 7.9186 8.35211C8.67695 8.48751 8.9207 8.65001 8.9207 9.08336C8.9207 9.51672 8.54149 9.81461 8.02695 9.81461C7.32274 9.81461 7.07899 9.51665 6.99774 9.1104C6.9707 9.00211 6.88945 8.9479 6.8082 8.9479H6.34774C6.23945 8.9479 6.1582 9.02915 6.1582 9.13751V9.16461C6.26649 9.84165 6.69985 10.3292 7.5936 10.4646V11.1146C7.5936 11.2229 7.67485 11.3042 7.81024 11.3313H8.21649C8.32485 11.3313 8.4061 11.25 8.4332 11.1146V10.4646C9.2457 10.3292 9.78735 9.7604 9.78735 9.02915Z"
        fill="white"
      />
      <path
        d="M6.61879 11.8728C4.50629 11.1145 3.42293 8.75827 4.20839 6.67281C4.61464 5.53531 5.50839 4.66866 6.61879 4.26241C6.72714 4.20827 6.78129 4.12702 6.78129 3.99156V3.61241C6.78129 3.50406 6.72714 3.42281 6.61879 3.39577C6.59168 3.39577 6.53754 3.39577 6.51043 3.42281C3.93754 4.23531 2.52918 6.97077 3.34168 9.54366C3.82918 11.0603 4.99379 12.2249 6.51043 12.7124C6.61879 12.7666 6.72714 12.7124 6.75418 12.6041C6.78129 12.577 6.78129 12.5499 6.78129 12.4958V12.1166C6.78129 12.0353 6.70004 11.927 6.61879 11.8728ZM9.48964 3.42281C9.38129 3.36866 9.27293 3.42281 9.24589 3.53116C9.21879 3.55827 9.21879 3.58531 9.21879 3.63952V4.01866C9.21879 4.12702 9.30004 4.23531 9.38129 4.28952C11.4938 5.04781 12.5771 7.40406 11.7917 9.48952C11.3854 10.627 10.4917 11.4937 9.38129 11.8999C9.27293 11.9541 9.21879 12.0353 9.21879 12.1708V12.5499C9.21879 12.6583 9.27293 12.7395 9.38129 12.7666C9.40839 12.7666 9.46254 12.7666 9.48964 12.7395C12.0625 11.927 13.4709 9.19156 12.6584 6.61866C12.1709 5.07491 10.9792 3.91031 9.48964 3.42281Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4951_37305">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
