import { FC } from 'react'

import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'

import { Button } from '@banx/components/Buttons'

import { UserPortfolio } from '@banx/api/common'
import { PATHS } from '@banx/router'
import { buildUrlWithModeAndToken } from '@banx/store'
import { useAssetMode, useTokenType } from '@banx/store/common'

import { Header, StatsSection, VaultsList } from './components'

import styles from './MyVaults.module.less'

interface MyVaultsProps {
  vaults: UserPortfolio['vaults'] | undefined
  isLoading: boolean
}

const MyVaults: FC<MyVaultsProps> = ({ vaults, isLoading }) => {
  const { stats, list } = vaults ?? {}
  const { netPnl = 0, apy = 0, funds = 0, totalPnl = 0 } = stats ?? {}

  const navigate = useNavigate()
  const { tokenType } = useTokenType()
  const { currentAssetMode } = useAssetMode()

  const goToVaultsPage = () => {
    navigate(buildUrlWithModeAndToken(PATHS.LEND_VAULTS, currentAssetMode, tokenType))
  }

  const actionButtonProps = {
    onClick: goToVaultsPage,
    text: isEmpty(list) ? 'Invest in vaults' : 'Manage my vaults',
  }

  return (
    <div className={styles.container}>
      <Header totalPnl={totalPnl} />
      <StatsSection netPnl={netPnl} apy={apy} funds={funds} />
      <VaultsList vaults={list} totalFunds={funds} isLoading={isLoading} />
      <Button onClick={actionButtonProps.onClick} className={styles.manageButton}>
        {actionButtonProps.text}
      </Button>
    </div>
  )
}

export default MyVaults
