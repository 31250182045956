import { isEmpty } from 'lodash'

import { useTokenType } from '@banx/store/common'

import { useBorrowerActivity } from './useBorrowerActivity'

export const useHistoryLoansTable = () => {
  const { tokenType, setTokenType } = useTokenType()

  const { loans, isLoading, sortParams, fetchNextPage, hasNextPage } = useBorrowerActivity()

  const loadMore = () => {
    if (hasNextPage) {
      fetchNextPage()
    }
  }

  const isNoLoans = isEmpty(loans) && !isLoading

  return {
    loans,
    loading: isLoading,
    isNoLoans,
    tokenType,
    setTokenType,
    loadMore,
    sortViewParams: {
      sortParams,
    },
  }
}
