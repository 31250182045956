import { FC, SVGProps } from 'react'

export const Escrow: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 10.875C8.69239 10.875 10.875 8.69239 10.875 6C10.875 3.30761 8.69239 1.125 6 1.125C4.80001 1.125 3.70128 1.55857 2.85193 2.2776L5.63538 5.06105L5.81778 4.87865C6.22332 4.47311 6.4261 4.27033 6.61402 4.24891C6.77748 4.23028 6.94054 4.28694 7.05722 4.40292C7.19137 4.53626 7.22471 4.82107 7.29139 5.39071L7.48956 7.0835C7.48956 7.30787 7.30767 7.48975 7.08331 7.48975L5.39051 7.29159C4.82088 7.22491 4.53606 7.19157 4.40272 7.05742C4.28675 6.94074 4.23008 6.77767 4.24871 6.61422C4.27014 6.42629 4.47291 6.22352 4.87845 5.81798L5.06085 5.63558L2.27742 2.85214C1.5585 3.70147 1.125 4.80011 1.125 6C1.125 8.69239 3.30761 10.875 6 10.875Z"
      fill="black"
    />
  </svg>
)
