import { FC, SVGProps } from 'react'

export const CoinCrashed: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M8.6665 3.33301C11.9802 3.33301 14.6665 4.67615 14.6665 6.33301V9.99967C14.6665 10.9242 13.6078 11.7389 11.9998 12.2173C11.0464 12.5009 9.89994 12.6663 8.6665 12.6663V9.33301L10.6665 7.66634L8.33317 6.99967L10.6665 5.33301L7.6665 4.99967L8.6665 3.33301Z"
        fill="#FF2B44"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.23776 3.07576C8.32812 2.92516 8.49087 2.83301 8.6665 2.83301C10.3842 2.83301 11.9639 3.18005 13.1328 3.76447C14.2717 4.33394 15.1665 5.21317 15.1665 6.33301V9.99967C15.1665 10.6682 14.7825 11.2316 14.2518 11.6636C13.7187 12.0975 12.9856 12.4457 12.1424 12.6965C11.1376 12.9955 9.94248 13.1663 8.6665 13.1663C8.39036 13.1663 8.1665 12.9425 8.1665 12.6663V9.33301C8.1665 9.18462 8.23242 9.04389 8.34641 8.9489L9.6202 7.8874L8.19581 7.48044C8.01122 7.4277 7.87313 7.27392 7.84046 7.08475C7.8078 6.89557 7.88633 6.70439 8.04255 6.59281L9.31258 5.68565L7.61129 5.49662C7.44205 5.47781 7.29402 5.37417 7.21845 5.22159C7.14287 5.069 7.15015 4.88844 7.23776 4.74243L8.23776 3.07576ZM14.1665 8.2321V9.99967C14.1665 10.2557 14.0211 10.562 13.6205 10.888C13.3417 11.115 12.9639 11.3293 12.4998 11.5155V9.17944C13.1504 8.92934 13.7211 8.61091 14.1665 8.2321ZM11.4998 9.49484C10.7845 9.67625 9.99623 9.79029 9.1665 9.82315V12.1569C10.0149 12.1246 10.806 12.0109 11.4998 11.8361V9.49484ZM10.1661 8.73423C10.7738 8.65228 11.3382 8.52201 11.8411 8.35368C12.601 8.09937 13.1993 7.76544 13.5985 7.3999C13.9966 7.03533 14.1665 6.67062 14.1665 6.33301C14.1665 5.79599 13.7182 5.17522 12.6855 4.6589C11.7421 4.18717 10.429 3.87198 8.94758 3.83638L8.49614 4.58878L10.7217 4.83607C10.9277 4.85895 11.0981 5.00647 11.1504 5.20698C11.2026 5.40749 11.1257 5.61944 10.9571 5.73987L9.46763 6.8038L10.8039 7.18558C10.982 7.23648 11.1174 7.38171 11.1557 7.56298C11.194 7.74425 11.1289 7.93184 10.9866 8.05045L10.1661 8.73423Z"
        fill="#1B1B1B"
      />
      <path
        d="M1.3335 6.33301C1.3335 4.67615 4.01979 3.33301 7.3335 3.33301L4.3335 4.99967L6.66683 5.99967L4.3335 6.99967L7.3335 7.66634V9.33301V12.6663C6.10007 12.6663 4.95356 12.5009 4.00016 12.2173C2.39218 11.7389 1.3335 10.9242 1.3335 9.99967V6.33301Z"
        fill="#FF2B44"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.31446 4.6589C2.28183 5.17522 1.8335 5.79599 1.8335 6.33301C1.8335 6.67062 2.00338 7.03533 2.40151 7.3999C2.8007 7.76544 3.39904 8.09937 4.15885 8.35368C4.72177 8.54208 5.36263 8.68313 6.05653 8.76186C6.30926 8.79053 6.56867 8.81089 6.8335 8.8223V8.06743L4.22503 7.48777C4.01315 7.44068 3.85584 7.26227 3.83566 7.04616C3.81548 6.83005 3.93703 6.6256 4.13654 6.5401L5.39753 5.99967L4.13654 5.45925C3.9612 5.3841 3.84389 5.21573 3.83415 5.02521C3.8244 4.8347 3.92392 4.65524 4.09067 4.5626L4.93126 4.09561C4.32026 4.23684 3.77359 4.42934 3.31446 4.6589ZM6.8335 9.82315C6.53067 9.81116 6.2336 9.78836 5.9438 9.75548C5.43902 9.69821 4.95506 9.61021 4.50016 9.49484V11.8361C5.19395 12.0109 5.98509 12.1246 6.8335 12.1569V9.82315ZM3.50016 11.5155V9.17944C2.84962 8.92934 2.2789 8.61091 1.8335 8.2321V9.99967C1.8335 10.2557 1.97888 10.562 2.37947 10.888C2.65829 11.115 3.03614 11.3293 3.50016 11.5155ZM0.833496 6.33301V9.99967C0.833496 10.6682 1.21745 11.2316 1.7482 11.6636C2.28131 12.0975 3.0144 12.4457 3.85759 12.6965C4.86245 12.9955 6.05752 13.1663 7.3335 13.1663C7.60964 13.1663 7.8335 12.9425 7.8335 12.6663V7.66634C7.8335 7.43199 7.67073 7.22908 7.44196 7.17825L5.95641 6.84812L6.86379 6.45925C7.04763 6.38046 7.16683 6.19969 7.16683 5.99967C7.16683 5.79966 7.04763 5.61889 6.86379 5.5401L5.46746 4.94167L7.57632 3.77009C7.77548 3.65944 7.87466 3.42814 7.81751 3.20759C7.76036 2.98703 7.56133 2.83301 7.3335 2.83301C5.61585 2.83301 4.03609 3.18005 2.86725 3.76447C1.72831 4.33394 0.833496 5.21317 0.833496 6.33301Z"
        fill="#1B1B1B"
      />
    </g>
    <defs>
      <clipPath id="clip0_21721_57056">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
