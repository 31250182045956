import { BN } from 'fbonds-core'
import { chain } from 'lodash'

import { sumBNs } from '@banx/utils'

import { EnhancedBorrowOffer } from '../hooks'

export const distributeBorrowAmountToOffers = ({
  borrowAmount,
  offers,
  tokenDecimals,
}: {
  borrowAmount: BN
  offers: EnhancedBorrowOffer[]
  tokenDecimals: number
}) => {
  let remainingBorrowAmount = borrowAmount
  const newOffers: EnhancedBorrowOffer[] = []

  for (let i = 0; i < offers.length; ++i) {
    const offer = offers[i]

    const maxTokenToGet = new BN(offer.maxTokenToGet)
    const collateralsPerToken = new BN(offer.collateralsPerToken)
    const denominator = new BN(10 ** tokenDecimals)

    if (maxTokenToGet.gte(remainingBorrowAmount)) {
      const collateralRequired = remainingBorrowAmount
        .mul(collateralsPerToken)
        .divRound(denominator)

      newOffers.push({
        ...offer,
        maxTokenToGet: remainingBorrowAmount.toString(),
        maxCollateralToReceive: collateralRequired.toString(),
      })
      break
    }

    if (maxTokenToGet.lt(remainingBorrowAmount)) {
      const collateralRequired = maxTokenToGet.mul(collateralsPerToken).divRound(denominator)

      newOffers.push({
        ...offer,
        maxTokenToGet: maxTokenToGet.toString(),
        maxCollateralToReceive: collateralRequired.toString(),
      })

      remainingBorrowAmount = remainingBorrowAmount.sub(maxTokenToGet)
    }
  }

  return newOffers
}

export const distributeCollateralToBorrowOffers = ({
  collateralAmount,
  offers,
  tokenDecimals,
}: {
  collateralAmount: BN
  offers: EnhancedBorrowOffer[]
  tokenDecimals: number
}): EnhancedBorrowOffer[] => {
  let remainingCollateral = collateralAmount

  const validBorrowOffers = chain(offers)
    .sortBy((offer) => new BN(offer.maxCollateralToReceive).toString())
    .value()

  const allocatedOffers = validBorrowOffers.map((offer) => {
    const maxCollateralForOffer = new BN(offer.maxCollateralToReceive)
    const allocatedCollateral = BN.min(maxCollateralForOffer, remainingCollateral)

    const maxTokenToGet = allocatedCollateral
      .mul(new BN(10).pow(new BN(tokenDecimals)))
      .divRound(new BN(offer.collateralsPerToken))

    remainingCollateral = remainingCollateral.sub(allocatedCollateral)

    return {
      ...offer,
      maxCollateralToReceive: allocatedCollateral.toString(),
      maxTokenToGet: maxTokenToGet.toString(),
    }
  })

  return allocatedOffers
}

export const getMaxBorrowableAmount = ({
  offers,
  collateralAmount,
  tokenDecimals,
}: {
  offers: EnhancedBorrowOffer[]
  collateralAmount: BN
  tokenDecimals: number
}): BN => {
  const updatedOffers = distributeCollateralToBorrowOffers({
    offers,
    collateralAmount,
    tokenDecimals,
  })

  return sumBNs(updatedOffers.map((offer) => new BN(offer.maxTokenToGet)))
}
