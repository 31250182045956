import { FC, SVGProps } from 'react'

export const Docs: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <g clipPath="url(#clip0_2810_15)">
      <path
        d="M8.0237 13.6313C8.33941 13.6313 8.52883 13.8962 8.52883 14.161C8.52883 14.492 8.27627 14.6907 8.0237 14.6907C7.70799 14.6907 7.51857 14.4258 7.51857 14.161C7.51857 13.8962 7.70799 13.6313 8.0237 13.6313ZM16.2952 10.2547C15.9795 10.2547 15.7901 9.98991 15.7901 9.72508C15.7901 9.46025 16.0426 9.19542 16.2952 9.19542C16.6109 9.19542 16.8003 9.46025 16.8003 9.72508C16.8003 9.98991 16.5478 10.2547 16.2952 10.2547ZM16.2952 8.00367C15.4112 8.00367 14.6535 8.79817 14.6535 9.72508C14.6535 9.9237 14.6535 10.1223 14.7167 10.2547L9.34966 13.2341C9.03396 12.7706 8.52883 12.5058 8.0237 12.5058C7.39229 12.5058 6.82402 12.9031 6.57145 13.4327L1.77273 10.7844C1.2676 10.5196 0.888752 9.59266 0.951893 8.79817C0.951893 8.40092 1.14132 8.06988 1.33074 7.93747C1.45702 7.87126 1.64645 7.87126 1.77273 7.93747H1.83587C3.09869 8.66575 7.32915 10.9168 7.45543 11.0492C7.70799 11.1817 7.89742 11.2479 8.33941 10.983L16.9897 6.28227C17.116 6.21606 17.2423 6.08364 17.2423 5.88502C17.2423 5.62019 16.9897 5.48777 16.9897 5.48777C16.4846 5.22294 15.7269 4.8919 15.0324 4.49465C13.6478 3.80046 12.2586 3.1163 10.8651 2.44221C10.1705 2.04496 9.66537 2.376 9.53909 2.44221L9.34966 2.50842C5.81376 4.36224 1.07818 6.81193 0.825611 6.94435C0.320482 7.27539 0.0679169 7.87126 0.00477581 8.59955C-0.0583653 9.79129 0.509905 11.0492 1.39388 11.5127L6.50831 14.2934C6.6346 15.1541 7.32915 15.75 8.08684 15.75C8.97082 15.75 9.72851 15.0217 9.72851 14.0948L15.3481 10.9168C15.6638 11.1817 15.9795 11.2479 16.3583 11.2479C17.2423 11.2479 18 10.4534 18 9.52646C17.8737 8.73196 17.1792 8.00367 16.2952 8.00367Z"
        fill="#8C8C8C"
      />
    </g>
    <defs>
      <clipPath id="clip0_2810_15">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
