import { BN } from 'fbonds-core'
import { PairState } from 'fbonds-core/lib/fbond-protocol/types'
import { chain, reduce, uniqueId } from 'lodash'

import { Offer, core } from '@banx/api/nft'
import { UserVaultPrimitive } from '@banx/api/shared'
import { ZERO_BN } from '@banx/utils/bn'

import { SimpleOffer } from './types'

const spreadToSimpleOffers = (
  offer: core.Offer,
  //? use null to ignore userVaultBalance adjustments
  userVaultBalance: number | null,
): SimpleOffer[] => {
  const offerSize = calculateOfferSize(offer).toNumber()
  const offerLoanValue = calculateMaxLoanValueFromOffer(offer)
  const fullLoansAmount = сalculateFullLoansAmount(offer)

  const simpleOffers: SimpleOffer[] = []

  if (fullLoansAmount >= 1) {
    //? Need to use map to call uniqueId for each offer to prevent same id generation
    const fullSimpleOffers: SimpleOffer[] = new Array(fullLoansAmount).fill(null).map(() => ({
      id: uniqueId(),
      loanValue: offerLoanValue,
      hadoMarket: offer.hadoMarket,
      publicKey: offer.publicKey,
      assetReceiver: offer.assetReceiver,
    }))

    simpleOffers.push(...fullSimpleOffers)
  }

  const additionalOfferLoanValue = offerSize - offerLoanValue * fullLoansAmount
  if (additionalOfferLoanValue > 0) {
    simpleOffers.push({
      id: uniqueId(),
      loanValue: additionalOfferLoanValue,
      hadoMarket: offer.hadoMarket,
      publicKey: offer.publicKey,
      assetReceiver: offer.assetReceiver,
    })
  }

  if (userVaultBalance === null) {
    return simpleOffers
  }

  //? Filter/Patch simpleOffers according to userVaultBalance
  const { offers } = reduce(
    simpleOffers,
    (acc: { vaultBalance: number; offers: SimpleOffer[] }, offer) => {
      const { vaultBalance, offers } = acc

      if (vaultBalance !== 0 && offer.loanValue <= vaultBalance) {
        return {
          offers: [...offers, offer],
          vaultBalance: vaultBalance - offer.loanValue,
        }
      }

      if (vaultBalance !== 0 && offer.loanValue > vaultBalance) {
        return {
          offers: [
            ...offers,
            {
              ...offer,
              loanValue: vaultBalance,
            },
          ],
          vaultBalance: 0,
        }
      }

      return acc
    },
    { vaultBalance: userVaultBalance, offers: [] },
  )

  return offers
}

type ConvertOffersToSimple = (params: {
  offers: core.Offer[]
  userVaults: UserVaultPrimitive[] | null
  sort?: 'desc' | 'asc'
}) => SimpleOffer[]
export const convertOffersToSimple: ConvertOffersToSimple = ({
  offers,
  userVaults,
  sort = 'desc',
}) => {
  const convertedOffers = chain(offers)
    .map((offer) => {
      if (userVaults === null) {
        return spreadToSimpleOffers(offer, null)
      }

      const userVault = userVaults.find(({ user }) => user === offer.assetReceiver)
      if (!userVault) return []

      return spreadToSimpleOffers(offer, userVault.offerLiquidityAmount)
    })
    .flatten()
    .sort((a, b) => {
      if (sort === 'desc') {
        return b.loanValue - a.loanValue
      }
      return a.loanValue - b.loanValue
    })
    .value()

  return convertedOffers
}

export const сalculateFullLoansAmount = (offer: core.Offer) => {
  const offerLoanValue = calculateMaxLoanValueFromOffer(offer)
  const offerSize = calculateOfferSize(offer).toNumber()

  if (offerLoanValue === 0 || offerSize === 0) {
    return 0
  }

  const loansAmount = offerSize / offerLoanValue
  return Math.floor(loansAmount)
}

type CalculateNewOfferSizeParams = {
  loanValue: number
  loansAmount: number
}
export const calculateNewOfferSize = ({
  loanValue,
  loansAmount,
}: CalculateNewOfferSizeParams): BN => {
  return new BN(loanValue * loansAmount)
}

export const calculateOfferSize = (offer: core.Offer): BN => {
  const { fundsSolOrTokenBalance, bidSettlement } = offer

  return new BN(fundsSolOrTokenBalance).add(new BN(bidSettlement))
}

export const calculateMaxLoanValueFromOffer = (offer: core.Offer): number => {
  const { currentSpotPrice, validation } = offer
  const offerSize = calculateOfferSize(offer).toNumber()

  const potentialMaxLoanValue = Math.min(currentSpotPrice, validation.loanToValueFilter)

  return Math.min(potentialMaxLoanValue, offerSize)
}

export const isOfferStateClosed = (pairState: PairState) => {
  return (
    pairState === PairState.PerpetualClosed ||
    pairState === PairState.PerpetualBondingCurveClosed ||
    pairState === PairState.PerpetualMigrated
  )
}

export const isOfferClosed = (offer: Offer) => {
  const isStateClosed = isOfferStateClosed(offer.pairState)

  return (
    isStateClosed &&
    offer.bidCap === 0 &&
    offer.concentrationIndex === 0 &&
    offer.bidSettlement === 0 &&
    offer.fundsSolOrTokenBalance === 0
  )
}

//? Prevent orders wrong distibution on bulk borrow from same offer
export const offerNeedsReservesOptimizationOnBorrow = (offer: core.Offer, loanValueSum: number) =>
  loanValueSum <= (offer.bidSettlement + offer.buyOrdersQuantity > 0 ? offer.currentSpotPrice : 0)

type FilterOutWalletLoans = (props: { offers: core.Offer[]; walletPubkey?: string }) => core.Offer[]
export const filterOutWalletLoans: FilterOutWalletLoans = ({ offers, walletPubkey }) => {
  if (!walletPubkey) return offers
  return offers.filter((offer) => offer.assetReceiver !== walletPubkey)
}

type FindSuitableOffer = (props: {
  loanValue: number
  offers: core.Offer[]
}) => core.Offer | undefined
export const findSuitableOffer: FindSuitableOffer = ({ loanValue, offers }) => {
  const notEmptyOffers = offers.filter(isOfferNotEmpty)

  //? Create simple offers array sorted by loanValue (offerValue) asc
  const simpleOffers = convertOffersToSimple({
    offers: notEmptyOffers,
    userVaults: null,
    sort: 'asc',
  })

  //? Find offer. OfferValue must be greater than or equal to loanValue
  const simpleOffer = simpleOffers.find(({ loanValue: offerValue }) => loanValue <= offerValue)

  return notEmptyOffers.find(({ publicKey }) => publicKey === simpleOffer?.publicKey)
}

export const isOfferNotEmpty = (offer: core.Offer) => {
  return calculateOfferSize(offer).gt(ZERO_BN)
}

export type NftWithLoanValue = {
  nft: core.BorrowNft
  loanValue: number
}
type NftWithOffer = {
  nft: NftWithLoanValue
  offer: core.Offer
}
type MatchNftsAndOffers = (props: {
  nfts: NftWithLoanValue[]
  rawOffers: core.Offer[]
  rawUserVaults: UserVaultPrimitive[]
}) => NftWithOffer[]
/**
 * Recalculates the cart. Matches selected nfts with selected offers
 * to make pairs (nft+offer) as effective as possible.
 */
export const matchNftsAndOffers: MatchNftsAndOffers = ({ nfts, rawOffers, rawUserVaults }) => {
  //? Create simple offers array sorted by loanValue (offerValue) asc
  const simpleOffers = convertOffersToSimple({
    offers: rawOffers,
    userVaults: rawUserVaults,
    sort: 'asc',
  })

  const { nftsWithOffers } = chain(nfts)
    .cloneDeep()
    //? Sort by selected loanValue asc
    .sort((a, b) => {
      return a.loanValue - b.loanValue
    })
    .reduce(
      (acc, nft) => {
        //? Find index of offer. OfferValue must be greater than or equal to selected loanValue. And mustn't be used by prev iteration
        const offerIndex = simpleOffers.findIndex(
          ({ loanValue: offerValue }, idx) => nft.loanValue <= offerValue && acc.offerIndex <= idx,
        )

        const nftAndOffer: NftWithOffer = {
          nft,
          offer: rawOffers.find(
            ({ publicKey }) => publicKey === simpleOffers[offerIndex].publicKey,
          ) as core.Offer,
        }

        return {
          //? Increment offerIndex to use in next iteration (to reduce amount of iterations)
          offerIndex: offerIndex + 1,
          nftsWithOffers: [...acc.nftsWithOffers, nftAndOffer],
        }
      },
      { offerIndex: 0, nftsWithOffers: [] } as {
        offerIndex: number
        nftsWithOffers: NftWithOffer[]
      },
    )
    .value()

  return nftsWithOffers
}
