import { FC, SVGProps } from 'react'

export const House: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M10.7652 2.94333C11.564 2.66501 12.4334 2.66494 13.2323 2.94315C13.7381 3.1193 14.1644 3.43369 14.5986 3.84078C15.0218 4.23751 15.5029 4.772 16.1015 5.437L18.25 7.82386V5C18.25 4.58579 18.5858 4.25 19 4.25C19.4142 4.25 19.75 4.58579 19.75 5V9.49028L21.5574 11.4982C21.8346 11.8061 21.8096 12.2803 21.5018 12.5574C21.1939 12.8346 20.7197 12.8096 20.4426 12.5018L19.75 11.7324V16.2321C19.75 17.045 19.75 17.7006 19.7066 18.2315C19.662 18.7781 19.5676 19.2582 19.3413 19.7025C18.9818 20.4081 18.4081 20.9818 17.7025 21.3413C17.2583 21.5676 16.7781 21.662 16.2315 21.7066C15.8198 21.7403 15.3331 21.7478 14.7564 21.7495L14.7501 20.9995L14.75 20.2495C14.7521 19.91 14.7543 19.5953 14.7564 19.297C14.7668 17.7935 14.7744 16.7101 14.75 15C14.75 14.5858 14.4142 14.25 14 14.25H10C9.58579 14.25 9.25 14.586 9.25 15.0002C9.22562 16.7103 9.23317 17.7937 9.24365 19.2972L9.24366 19.2994L9.24368 19.3016C9.24575 19.5985 9.24793 19.9119 9.25 20.2496V20.9995V21.7495C8.67043 21.7479 8.18172 21.7404 7.76854 21.7066C7.2219 21.662 6.74176 21.5676 6.29754 21.3413C5.59193 20.9818 5.01826 20.4081 4.65873 19.7025C4.43239 19.2582 4.33803 18.7781 4.29337 18.2315C4.24999 17.7006 4.25 17.0449 4.25001 16.2321L4.25001 11.7321L3.55751 12.5017C3.28044 12.8096 2.80623 12.8346 2.49832 12.5575C2.19042 12.2804 2.16543 11.8062 2.4425 11.4983L5.52165 8.07654L5.62152 7.96553L5.67445 7.90673L7.89634 5.43759C8.49482 4.77251 8.97583 4.23796 9.39897 3.84116C9.83316 3.43401 10.2594 3.11956 10.7652 2.94333Z"
      fill="#8C8C8C"
    />
  </svg>
)
