import { useEffect, useMemo } from 'react'

import { useWallet } from '@solana/wallet-adapter-react'
import { BN } from 'fbonds-core'

import { useModal, useTokenType } from '@banx/store/common'
import { ZERO_BN, getTokenDecimals, limitDecimalPlaces, stringToBN, sumBNs } from '@banx/utils'

import { BorrowToken } from '../../constants'
import { useBorrowTokensList, useCollateralsList } from '../../hooks'
import { getMaxBorrowableAmount } from '../OrderBook/helpers'
import { WarningModal } from '../components'
import {
  calcTotalCollateralAmount,
  calculateRequiredBorrowAmount,
  getErrorMessage,
  getInitialCollateral,
} from '../helpers'
import { useBorrowOffers } from './useBorrowOffers'
import { useBorrowOffersTransaction } from './useBorrowOffersTransaction'
import { useBorrowStore } from './useBorrowStore'
import { useSelectedOffers } from './useSelectedOffers'

export const useInstantBorrowContent = () => {
  const { publicKey } = useWallet()
  const walletPubkey = publicKey?.toBase58() ?? ''

  const { tokenType, setTokenType } = useTokenType()
  const { open: openModal } = useModal()

  const { collateralsList } = useCollateralsList()
  const { borrowTokensList } = useBorrowTokensList()

  const {
    collateralToken,
    setCollateralToken,
    borrowToken,
    setBorrowToken,
    ltvSliderValue,
    setLtvSlider,
    collateralInputValue,
    setCollateralInputValue,
    borrowInputValue,
    setBorrowInputValue,
  } = useBorrowStore()

  const { data: offers, allOffers, isLoading } = useBorrowOffers()

  const { borrow, isBorrowing } = useBorrowOffersTransaction(collateralToken)
  const { selectedOffers: offersInCart } = useSelectedOffers()

  const marketTokenDecimals = Math.log10(getTokenDecimals(tokenType))

  const initialCollateral = useMemo(() => {
    if (!collateralsList.length) return
    return getInitialCollateral(collateralsList)
  }, [collateralsList])

  useEffect(() => {
    if (!walletPubkey) return
    setCollateralToken(undefined)
  }, [setCollateralToken, walletPubkey])

  const initialBorrowToken = useMemo(
    () => borrowTokensList.find((token) => token.lendingTokenType === tokenType),
    [borrowTokensList, tokenType],
  )

  useEffect(() => {
    if (!collateralToken && initialCollateral) {
      setCollateralToken(initialCollateral)
    }
  }, [collateralToken, initialCollateral, setCollateralToken])

  useEffect(() => {
    if (!initialBorrowToken) return

    const isSameMint = collateralToken?.collateral.mint === initialBorrowToken.collateral.mint
    if (isSameMint) {
      setCollateralToken(initialCollateral!)
    }

    setBorrowToken(initialBorrowToken)
  }, [collateralToken, initialCollateral, setCollateralToken, setBorrowToken, initialBorrowToken])

  useEffect(() => {
    if (!collateralToken) return

    const totalAmount = calcTotalCollateralAmount(offersInCart, collateralToken).toString()

    if (totalAmount !== collateralInputValue) {
      setCollateralInputValue(limitDecimalPlaces(totalAmount, 6))
    }
  }, [offersInCart, collateralToken, collateralInputValue, setCollateralInputValue])

  const requiredBorrowAmount = useMemo(() => {
    return calculateRequiredBorrowAmount({
      offers,
      borrowInputValue,
      collateralToken,
      tokenDecimals: marketTokenDecimals,
    })
  }, [borrowInputValue, collateralToken, marketTokenDecimals, offers])

  const maxBorrowableAmount = useMemo(() => {
    if (!collateralToken || !allOffers?.length) return ZERO_BN

    return getMaxBorrowableAmount({
      offers: allOffers,
      collateralAmount: collateralToken.amountInWallet,
      tokenDecimals: marketTokenDecimals,
    })
  }, [collateralToken, allOffers, marketTokenDecimals])

  const canFundRequiredBorrowAmount = useMemo(() => {
    const totalBorrowAmountInCart = sumBNs(offersInCart.map((offer) => new BN(offer.maxTokenToGet)))
    const requiredBorrowAmount = stringToBN(borrowInputValue, marketTokenDecimals)

    return totalBorrowAmountInCart.gte(requiredBorrowAmount)
  }, [borrowInputValue, marketTokenDecimals, offersInCart])

  const handleBorrowTokenChange = (token: BorrowToken) => {
    setBorrowToken(token)
    setTokenType(token.lendingTokenType)
  }

  const onSubmit = () => {
    if (!canFundRequiredBorrowAmount) {
      return openModal(WarningModal, {
        offers: offersInCart,
        collateral: collateralToken,
        onSubmit: borrow,
      })
    }

    return borrow()
  }

  const errorMessage = getErrorMessage({
    borrowToken,
    borrowInputValue,
    collateral: collateralToken,
  })

  return {
    offers,
    isLoading,
    offersInCart,

    borrowTokensList,
    collateralsList,

    borrowToken,
    borrowInputValue,
    setBorrowInputValue,
    handleBorrowTokenChange,

    collateralInputValue,
    collateralToken,
    setCollateralToken,

    ltvSliderValue,
    setLtvSlider,

    requiredBorrowAmount,
    maxBorrowableAmount,

    errorMessage,
    isBorrowing,
    onSubmit,
  }
}
