import { BN, web3 } from 'fbonds-core'
import { PUBKEY_PLACEHOLDER } from 'fbonds-core/lib/fbond-protocol/constants'
import { getMockBondOffer } from 'fbonds-core/lib/fbond-protocol/functions/getters'
import {
  getBondingCurveTypeFromLendingToken,
  optimisticInitializeBondOfferBonding,
  optimisticUpdateBondOfferBonding,
} from 'fbonds-core/lib/fbond-protocol/functions/perpetual'
import { LendingTokenType } from 'fbonds-core/lib/fbond-protocol/types'
import { chain } from 'lodash'

import { core } from '@banx/api/nft'
import { SyntheticOffer } from '@banx/store/nft'
import { ZERO_BN } from '@banx/utils'

type GetUpdatedBondOffer = (props: {
  loanValue: number //? lamports
  loansAmount: number //? integer number
  syntheticOffer: SyntheticOffer
  tokenType: LendingTokenType
}) => core.Offer

export const getUpdatedBondOffer: GetUpdatedBondOffer = ({
  loanValue,
  loansAmount,
  syntheticOffer,
  tokenType,
}) => {
  const initializedOffer = optimisticInitializeBondOfferBonding({
    bondingType: getBondingCurveTypeFromLendingToken(tokenType),
    hadoMarket: new web3.PublicKey(syntheticOffer.marketPubkey),
    assetReceiver: new web3.PublicKey(syntheticOffer.assetReceiver),
    bondOffer: getMockBondOffer().publicKey,
    isSplFeature: false,
  })

  const updatedBondOffer = optimisticUpdateBondOfferBonding({
    bondOffer: initializedOffer,
    newLoanValue: new BN(loanValue),
    newQuantityOfLoans: new BN(loansAmount),
    collateralsPerToken: ZERO_BN,
    tokenLendingApr: ZERO_BN,
  })

  return core.convertBondOfferV3ToCore(updatedBondOffer)
}

type GetErrorMessage = (props: { loansAmount: number; hasFormChanges: boolean }) => string

const ERROR_MESSAGES = {
  EMPTY_LOANS_AMOUNT: 'Please enter a valid number of loans. The number of loans cannot be empty',
}

export const getErrorMessage: GetErrorMessage = ({ loansAmount, hasFormChanges }) => {
  const isEmptyLoansAmount = hasFormChanges && !loansAmount

  const errorConditions: Array<[boolean, string]> = [
    [isEmptyLoansAmount, ERROR_MESSAGES.EMPTY_LOANS_AMOUNT],
  ]

  const errorMessage = chain(errorConditions)
    .find(([condition]) => condition)
    .thru((error) => (error ? error[1] : ''))
    .value() as string

  return errorMessage
}

export const checkIsEditMode = (offerPubkey: string) =>
  !!offerPubkey && offerPubkey !== PUBKEY_PLACEHOLDER

type CalcOfferSize = (props: {
  syntheticOffer: SyntheticOffer
  loanValue: number //? lamports
  loansAmount: number
  tokenType: LendingTokenType
}) => number

export const calcOfferSize: CalcOfferSize = ({
  syntheticOffer,
  loanValue,
  loansAmount,
  tokenType,
}) => {
  const offerToUpdate = { loanValue, loansAmount, syntheticOffer, tokenType }
  const updatedBondOffer = getUpdatedBondOffer(offerToUpdate)

  const offerSize = updatedBondOffer.fundsSolOrTokenBalance + updatedBondOffer.bidSettlement
  return offerSize
}
