import { useWallet } from '@solana/wallet-adapter-react'

import EmptyList from '@banx/components/EmptyList'
import { Loader } from '@banx/components/Loader'

import { useFakeInfinityScroll } from '@banx/hooks'

import BorrowCard from './components/BorrowCard'
import FilterSection from './components/FilterSection'
import { HeaderList } from './components/HeaderList'
import { useRequestLoansContent } from './hooks'

import styles from './RequestLoansContent.module.less'

export const RequestLoansContent = () => {
  const { connected } = useWallet()

  const {
    visibleMarketPubkey,
    isLoading,
    isNoMarkets,
    isFilteredListEmpty,
    filteredListEmptyMessage,
    searchQuery,
    setSearchQuery,
    sortParams,
    onCardClick,
    markets,
    tokenType,
    setTokenType,
  } = useRequestLoansContent()

  const { data, fetchMoreTrigger } = useFakeInfinityScroll({ rawData: markets })

  if (!connected) return <EmptyList message="Connect wallet to view your nfts" />

  return (
    <div className={styles.content}>
      <FilterSection
        sortParams={sortParams}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        selectedLendingToken={tokenType}
        handleSelectedTokenChange={setTokenType}
      />

      {!isNoMarkets && !isFilteredListEmpty && !isLoading && <HeaderList />}

      {isLoading && <Loader />}
      {isNoMarkets && <EmptyList message="You don't have any whitelisted NFTs" />}
      {isFilteredListEmpty && !isNoMarkets && <EmptyList message={filteredListEmptyMessage} />}

      {!isNoMarkets && !isLoading && (
        <div className={styles.marketsList}>
          {data.map((market) => (
            <BorrowCard
              key={market.marketPubkey}
              market={market}
              onClick={() => onCardClick(market.marketPubkey)}
              isOpen={visibleMarketPubkey === market.marketPubkey}
            />
          ))}
          <div ref={fetchMoreTrigger} />
        </div>
      )}
    </div>
  )
}
