import React, { FC } from 'react'

import { useNavigate } from 'react-router-dom'

import { OnboardButton } from '@banx/components/Buttons'

import { buildUrlWithModeAndToken } from '@banx/store'
import { useAssetMode, useTokenType } from '@banx/store/common'

import { FaqType } from '../Faq'
import { OnboardingModalContentType } from '../modals'

import styles from './BreadcrumbHeader.module.less'

type Breadcrumb = {
  title: string
  path?: string
}

interface BreadcrumbHeaderProps {
  breadcrumbs: Breadcrumb[]
  onboardContentType?: `${OnboardingModalContentType}`
  faqType?: `${FaqType}`
}

export const BreadcrumbHeader: FC<BreadcrumbHeaderProps> = ({
  breadcrumbs,
  onboardContentType,
  faqType,
}) => {
  const navigate = useNavigate()
  const { tokenType } = useTokenType()
  const { currentAssetMode } = useAssetMode()

  const handleNavigate = (path: string) => {
    navigate(buildUrlWithModeAndToken(path, currentAssetMode, tokenType))
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.navigation}>
          {breadcrumbs.map((breadcrumb, index) => (
            <React.Fragment key={index}>
              {breadcrumb.path ? (
                <span
                  onClick={() => handleNavigate(breadcrumb.path!)}
                  className={styles.clickableBreadcrumb}
                >
                  {breadcrumb.title}
                </span>
              ) : (
                <span className={styles.currentBreadcrumb}>{breadcrumb.title}</span>
              )}
              {index < breadcrumbs.length - 1 && <span className={styles.arrow}>→</span>}
            </React.Fragment>
          ))}
        </div>

        {onboardContentType && <OnboardButton contentType={onboardContentType} faqType={faqType} />}
      </div>
    </div>
  )
}
