import { FC, useMemo, useState } from 'react'

import { useWallet } from '@solana/wallet-adapter-react'
import { isEmpty } from 'lodash'

import EmptyList from '@banx/components/EmptyList'
import { Loader } from '@banx/components/Loader'
import { filterBySearchQuery } from '@banx/components/Search'

import { MESSAGES } from '@banx/constants/messages'
import { useTokenType } from '@banx/store/common'

import { useBorrowNftsAndMarketsQuery, useMaxLoanValueByMarket } from '../hooks'
import { FilterSection } from './components/FilterSection'
import { HeaderList } from './components/HeaderList'
import { MarketBorrowCard } from './components/MarketBorrowCard'

import styles from './InstantLoansContent.module.less'

type InstantLoansContentProps = {
  goToRequestLoanTab: () => void
}

export const InstantLoansContent: FC<InstantLoansContentProps> = ({ goToRequestLoanTab }) => {
  const { connected } = useWallet()

  const { marketsPreview, nftsByMarket, userVaults, offersByMarket, isLoading } =
    useBorrowNftsAndMarketsQuery()

  const maxLoanValueByMarket = useMaxLoanValueByMarket({ offersByMarket, userVaults })

  const { tokenType, setTokenType } = useTokenType()

  const [expandedMarketPublicKey, setExpandedMarketPublicKey] = useState('')
  const [searchQuery, setSearchQuery] = useState<string>('')

  const handleCardToggle = (marketPubkey: string) => {
    setExpandedMarketPublicKey((prev) => (prev === marketPubkey ? '' : marketPubkey))
  }

  const filteredBySearchQuery = useMemo(() => {
    return filterBySearchQuery(marketsPreview, searchQuery, [(preview) => preview.collectionName])
  }, [marketsPreview, searchQuery])

  const isNoMarkets = isEmpty(marketsPreview) && !isLoading
  const isFilteredListEmpty = isEmpty(filteredBySearchQuery) && !isLoading

  const filteredListEmptyMessage = (() => {
    if (isFilteredListEmpty && searchQuery) return MESSAGES.EMPTY_SEARCH_RESULTS
    if (isFilteredListEmpty) return MESSAGES.EMPTY_FILTERED_LIST
    return ''
  })()

  if (!connected) return <EmptyList message="Connect wallet to view your nfts" />

  return (
    <div className={styles.content}>
      <FilterSection
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        selectedLendingToken={tokenType}
        handleSelectedTokenChange={setTokenType}
      />

      {!isNoMarkets && !isFilteredListEmpty && !isLoading && <HeaderList />}

      {isLoading && <Loader />}
      {isNoMarkets && <EmptyList message="You don't have any whitelisted NFTs" />}
      {isFilteredListEmpty && !isNoMarkets && <EmptyList message={filteredListEmptyMessage} />}

      {!isNoMarkets && (
        <div className={styles.cardsList}>
          {filteredBySearchQuery.map((preview) => (
            <MarketBorrowCard
              key={preview.marketPubkey}
              maxLoanValue={maxLoanValueByMarket[preview.marketPubkey] || 0}
              marketPreview={preview}
              onClick={() => handleCardToggle(preview.marketPubkey)}
              isExpanded={expandedMarketPublicKey === preview.marketPubkey}
              goToRequestLoanTab={goToRequestLoanTab}
              nftsAmount={nftsByMarket[preview.marketPubkey].length}
            />
          ))}
        </div>
      )}
    </div>
  )
}
