import { useWallet } from '@solana/wallet-adapter-react'
import { PUBKEY_PLACEHOLDER } from 'fbonds-core/lib/fbond-protocol/constants'

import { Button } from '@banx/components/Buttons'
import { useWalletModal } from '@banx/components/WalletModal'

import { adjustTokenAmountWithUpfrontFee, bnToHuman } from '@banx/utils'

import InputTokenSelect, { ControlsButtons } from '../components/InputTokenSelect'
import OrderBook from './OrderBook'
import { Summary } from './Summary'
import { CollateralField, LtvSlider } from './components'
import { useInstantBorrowContent } from './hooks/useInstantBorrowContent'

import styles from './InstantBorrowContent.module.less'

const InstantBorrowContent = () => {
  const { connected } = useWallet()
  const { setVisible: setVisibleWalletModal } = useWalletModal()

  const {
    offers,
    offersInCart,
    isLoading,

    borrowTokensList,
    collateralsList,

    borrowToken,
    borrowInputValue,
    setBorrowInputValue,
    handleBorrowTokenChange,

    collateralToken,
    collateralInputValue,
    setCollateralToken,

    ltvSliderValue,
    setLtvSlider,

    requiredBorrowAmount,
    maxBorrowableAmount,

    errorMessage,
    isBorrowing,
    onSubmit,
  } = useInstantBorrowContent()

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <InputTokenSelect
          label="To borrow"
          value={borrowInputValue}
          onChange={setBorrowInputValue}
          selectedToken={borrowToken}
          onChangeToken={handleBorrowTokenChange}
          tokensList={borrowTokensList}
          className={styles.borrowInput}
          disabled={!connected}
          rightContentJSX={
            connected ? (
              <ControlsButtons
                onChange={setBorrowInputValue}
                maxValue={bnToHuman(
                  adjustTokenAmountWithUpfrontFee(maxBorrowableAmount),
                  borrowToken?.collateral.decimals,
                )}
              />
            ) : null
          }
        />

        <CollateralField
          label="Your collateral"
          value={collateralInputValue}
          collateral={collateralToken}
          onChange={setCollateralToken}
          tokensList={collateralsList}
        />

        <LtvSlider
          label="LTV"
          value={ltvSliderValue}
          onChange={setLtvSlider}
          disabled={!connected}
        />

        <div className={styles.footerContent}>
          <Summary
            offers={offersInCart}
            marketPubkey={collateralToken?.marketPubkey ?? PUBKEY_PLACEHOLDER}
          />

          {connected && (
            <Button
              onClick={onSubmit}
              className={styles.borrowButton}
              disabled={!!errorMessage || !offersInCart.length}
              loading={isLoading || (isBorrowing && !errorMessage)}
            >
              {errorMessage || 'Borrow'}
            </Button>
          )}

          {!connected && (
            <Button onClick={() => setVisibleWalletModal(true)} className={styles.borrowButton}>
              Connect wallet
            </Button>
          )}
        </div>
      </div>

      <OrderBook
        offers={offers}
        requiredBorrowAmount={requiredBorrowAmount}
        collateral={collateralToken}
        loading={isLoading}
      />
    </div>
  )
}

export default InstantBorrowContent
