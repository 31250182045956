import { useEffect, useMemo } from 'react'

import { chain } from 'lodash'

import { useUserVault } from '@banx/components/WalletAccountSidebar'

import { core } from '@banx/api/nft'
import { useWalletBalance } from '@banx/hooks'
import { useTokenType } from '@banx/store/common'
import { SyntheticOffer } from '@banx/store/nft'
import { convertOffersToSimple, getTokenDecimals } from '@banx/utils'

import { Mark } from '../PlaceOfferContent/components'
import {
  convertLoanToMark,
  convertSimpleOfferToMark,
} from '../PlaceOfferContent/components/Diagram'
import { calcOfferSize, getErrorMessage, getUpdatedBondOffer } from '../helpers'
import { useLenderLoans } from './useLenderLoans'
import { useMarketAndOffer } from './useMarketAndOffer'
import { useOfferFormController } from './useOfferFormController'
import { useOfferTransactions } from './useOfferTransactions'
import { useSyntheticOffer } from './useSyntheticOffer'

export interface PlaceOfferParams {
  market: core.MarketPreview | undefined
  optimisticOffer: core.Offer | undefined
  updatedOffer: core.Offer | undefined
  syntheticOffer: SyntheticOffer

  setOfferPubkey?: (offerPubkey: string) => void
  exitEditMode: () => void

  offerErrorMessage: string
  hasFormChanges: boolean

  onCreateOffer: () => void
  onRemoveOffer: () => void
  onUpdateOffer: () => void

  loansAmount: string
  loanValue: string
  offerSize: number

  onLoanValueChange: (value: string) => void
  onLoanAmountChange: (value: string) => void

  diagramData: Mark[]
  isLoadingDiagram: boolean
}

type UsePlaceOffer = (props: {
  offerPubkey: string
  marketPubkey: string
  setOfferPubkey?: (offerPubkey: string) => void
}) => PlaceOfferParams

export const usePlaceOffer: UsePlaceOffer = ({ marketPubkey, offerPubkey, setOfferPubkey }) => {
  const { tokenType } = useTokenType()

  const walletBalance = useWalletBalance(tokenType)
  const { userVault } = useUserVault()

  const { offer, market, updateOrAddOffer } = useMarketAndOffer(offerPubkey, marketPubkey)
  const { syntheticOffer, removeSyntheticOffer, setSyntheticOffer } = useSyntheticOffer(
    offerPubkey,
    marketPubkey,
  )

  const isEditMode = syntheticOffer.isEdit

  const { lenderLoans, isLoading: isLoadingLenderLoans } = useLenderLoans({ offerPubkey })

  const decimals = getTokenDecimals(tokenType)

  const {
    loanValue: loanValueString,
    loansAmount: loansAmountString,
    onLoanValueChange,
    onLoanAmountChange,
    hasFormChanges,
    resetFormValues,
  } = useOfferFormController(syntheticOffer)

  const loanValue = parseFloat(loanValueString) * decimals
  const loansAmount = parseFloat(loansAmountString)

  const exitEditMode = () => {
    if (!setOfferPubkey) return

    setOfferPubkey('')
    removeSyntheticOffer()
  }

  useEffect(() => {
    if (!syntheticOffer) return
    const newSyntheticOffer = { ...syntheticOffer, loanValue, loansAmount }

    setSyntheticOffer(newSyntheticOffer)
  }, [syntheticOffer, setSyntheticOffer, loanValue, loansAmount])

  const { onCreateOffer, onRemoveOffer, onUpdateOffer } = useOfferTransactions({
    marketPubkey,
    loanValue,
    loansAmount,
    optimisticOffer: offer,
    updateOrAddOffer,
    resetFormValues,
    exitEditMode,
  })

  const offerSize = useMemo(() => {
    return calcOfferSize({ syntheticOffer, loanValue, loansAmount, tokenType })
  }, [syntheticOffer, loanValue, loansAmount, tokenType])

  const updatedOffer = useMemo(() => {
    return getUpdatedBondOffer({ syntheticOffer, loanValue, loansAmount, tokenType })
  }, [syntheticOffer, loanValue, loansAmount, tokenType])

  const offerErrorMessage = getErrorMessage({
    syntheticOffer,
    walletBalance,
    escrowBalance: userVault?.offerLiquidityAmount.toNumber() || 0,
    offerSize,
    loansAmount,
    hasFormChanges,
    tokenType,
  })

  const diagramData = useMemo(() => {
    if (!isEditMode) {
      return chain(new Array(loansAmount))
        .fill(loanValue)
        .sortBy((value) => value)
        .reverse()
        .value()
    }

    if (!offer) return []

    const offerToUpdate = { syntheticOffer, loanValue, loansAmount, tokenType }
    const offerToUse = hasFormChanges ? getUpdatedBondOffer(offerToUpdate) : offer

    const loansToMarks = lenderLoans.map(convertLoanToMark)
    const simpleOffersToMarks = convertOffersToSimple({
      offers: [offerToUse],
      userVaults: null,
    }).map(convertSimpleOfferToMark)

    return chain([...loansToMarks, ...simpleOffersToMarks])
      .filter(({ value }) => value > 0)
      .sortBy(({ value }) => value)
      .reverse()
      .value()
  }, [
    isEditMode,
    loansAmount,
    loanValue,
    offer,
    hasFormChanges,
    syntheticOffer,
    lenderLoans,
    tokenType,
  ])

  return {
    market,
    optimisticOffer: offer,
    syntheticOffer,
    updatedOffer,

    loanValue: loanValueString,
    loansAmount: loansAmountString,
    offerSize,

    onLoanValueChange,
    onLoanAmountChange,

    offerErrorMessage,
    hasFormChanges,

    exitEditMode,
    onCreateOffer,
    onRemoveOffer,
    onUpdateOffer,

    diagramData,
    isLoadingDiagram: isEditMode ? isLoadingLenderLoans : false,
    setOfferPubkey,
  }
}
