import { BN } from 'fbonds-core'
import { create } from 'zustand'

import { ZERO_BN } from '@banx/utils'

import { calculateRemainingAmount } from '../helpers'
import { EnhancedBorrowOffer } from './useBorrowOffers'

interface SelectedOffersState {
  allOffers: EnhancedBorrowOffer[]
  selectedOffers: EnhancedBorrowOffer[]
  requiredBorrowAmount: BN

  setSelection: (selectedOffers: EnhancedBorrowOffer[]) => void
  setOffersData: (offers: EnhancedBorrowOffer[], requiredBorrowAmount: BN) => void

  findSelectedOffer: (publicKey: string) => EnhancedBorrowOffer | null
  addToSelected: (offer: EnhancedBorrowOffer) => void
  removeFromSelected: (publicKey: string) => void
  toggleSelectedOffer: (offer: EnhancedBorrowOffer) => void

  clearSelection: () => void
}

export const useSelectedOffers = create<SelectedOffersState>((set, get) => ({
  selectedOffers: [],
  allOffers: [],
  requiredBorrowAmount: ZERO_BN,

  setSelection: (offers) => set({ selectedOffers: [...offers] }),

  setOffersData: (offers, requiredBorrowAmount) => {
    return set({ allOffers: [...offers], requiredBorrowAmount })
  },

  findSelectedOffer: (publicKey) => {
    return get().selectedOffers.find((offer) => offer.publicKey === publicKey) ?? null
  },

  addToSelected: (offer) => {
    const { selectedOffers } = get()
    set({ selectedOffers: [...selectedOffers, offer] })
  },

  removeFromSelected: (publicKey) => {
    const { allOffers, selectedOffers, requiredBorrowAmount } = get()

    const activeSelectedOffers = selectedOffers.filter((offer) => offer.publicKey !== publicKey)

    const remainingAmount = calculateRemainingAmount(activeSelectedOffers, requiredBorrowAmount)

    const updatedOffers = activeSelectedOffers.map((selectedOffer) =>
      calculateNewBorrowAmount(allOffers, selectedOffer, remainingAmount),
    )

    set({ selectedOffers: updatedOffers })
  },

  toggleSelectedOffer: (offer) => {
    const { findSelectedOffer, addToSelected, removeFromSelected } = get()
    findSelectedOffer(offer.publicKey) ? removeFromSelected(offer.publicKey) : addToSelected(offer)
  },

  clearSelection: () => set({ selectedOffers: [] }),
}))

const calculateNewBorrowAmount = (
  offers: EnhancedBorrowOffer[],
  selectedOffer: EnhancedBorrowOffer,
  remainingAmount: BN,
): EnhancedBorrowOffer => {
  const matchingOffer = offers.find((offer) => offer.publicKey === selectedOffer.publicKey)

  const maxBorrowableAmount = matchingOffer ? new BN(matchingOffer.maxTokenToGet) : ZERO_BN
  const currentSelectedAmount = new BN(selectedOffer.maxTokenToGet)

  const finalBorrowAmount = BN.min(currentSelectedAmount.add(remainingAmount), maxBorrowableAmount)

  return {
    ...selectedOffer,
    maxTokenToGet: finalBorrowAmount.toString(),
  }
}
