import { FC, SVGProps } from 'react'

export const Settings: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.33333 5.66667C5.33333 6.58714 4.58714 7.33333 3.66667 7.33333C2.74619 7.33333 2 6.58714 2 5.66667C2 4.74619 2.74619 4 3.66667 4C4.58714 4 5.33333 4.74619 5.33333 5.66667Z"
      fill="#1B1B1B"
    />
    <path
      d="M14 10.3333C14 11.2538 13.2538 12 12.3333 12C11.4129 12 10.6667 11.2538 10.6667 10.3333C10.6667 9.41286 11.4129 8.66667 12.3333 8.66667C13.2538 8.66667 14 9.41286 14 10.3333Z"
      fill="#1B1B1B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.66667 4.5C3.02233 4.5 2.5 5.02233 2.5 5.66667C2.5 6.311 3.02233 6.83333 3.66667 6.83333C4.311 6.83333 4.83333 6.311 4.83333 5.66667C4.83333 5.02233 4.311 4.5 3.66667 4.5ZM1.5 5.66667C1.5 4.47005 2.47005 3.5 3.66667 3.5C4.69119 3.5 5.54963 4.2111 5.77536 5.16667H14C14.2761 5.16667 14.5 5.39052 14.5 5.66667C14.5 5.94281 14.2761 6.16667 14 6.16667H5.77536C5.54963 7.12223 4.69119 7.83333 3.66667 7.83333C2.47005 7.83333 1.5 6.86328 1.5 5.66667ZM12.3333 9.16667C11.689 9.16667 11.1667 9.689 11.1667 10.3333C11.1667 10.9777 11.689 11.5 12.3333 11.5C12.9777 11.5 13.5 10.9777 13.5 10.3333C13.5 9.689 12.9777 9.16667 12.3333 9.16667ZM10.2246 9.83333C10.4504 8.87777 11.3088 8.16667 12.3333 8.16667C13.53 8.16667 14.5 9.13672 14.5 10.3333C14.5 11.53 13.53 12.5 12.3333 12.5C11.3088 12.5 10.4504 11.7889 10.2246 10.8333H2C1.72386 10.8333 1.5 10.6095 1.5 10.3333C1.5 10.0572 1.72386 9.83333 2 9.83333H10.2246Z"
      fill="#1B1B1B"
    />
  </svg>
)
