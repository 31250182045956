import { FC } from 'react'

import { LendingTokenType } from 'fbonds-core/lib/fbond-protocol/types'

import { MARKET_OPTIONS_WITHOUT_ALL, TokenDropdown } from '@banx/components/Dropdowns'
import { Search } from '@banx/components/Search'
import { SortDropdown, SortDropdownProps } from '@banx/components/SortDropdown'

import { SortField } from '../../hooks'

import styles from './FilterSection.module.less'

interface FilterSectionProps {
  sortParams: SortDropdownProps<SortField>
  searchQuery: string
  setSearchQuery: (query: string) => void
  selectedLendingToken: LendingTokenType
  handleSelectedTokenChange: (value: LendingTokenType) => void
}

const FilterSection: FC<FilterSectionProps> = ({
  sortParams,
  searchQuery,
  setSearchQuery,
  selectedLendingToken,
  handleSelectedTokenChange,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.filterWrapper}>
        <TokenDropdown
          option={selectedLendingToken}
          options={MARKET_OPTIONS_WITHOUT_ALL}
          onChange={handleSelectedTokenChange}
        />
        <Search value={searchQuery} onChange={setSearchQuery} />
      </div>

      <SortDropdown {...sortParams} />
    </div>
  )
}

export default FilterSection
