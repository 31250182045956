import { useWallet } from '@solana/wallet-adapter-react'
import { useQuery } from '@tanstack/react-query'

import { user } from '@banx/api/common'
import { AssetType } from '@banx/api/nft'
import { AssetMode, useAssetMode, useTokenType } from '@banx/store/common'

export const useUserPortfolio = () => {
  const { publicKey } = useWallet()
  const walletPubkey = publicKey?.toBase58() || ''

  const { tokenType } = useTokenType()
  const { currentAssetMode } = useAssetMode()

  const assetType = currentAssetMode === AssetMode.NFT ? AssetType.NFT : AssetType.SPL

  const { data, isLoading } = useQuery(
    ['userPortfolio', walletPubkey, tokenType, currentAssetMode],
    () => user.fetchUserPortfolio({ walletPubkey, tokenType, assetType }),
    {
      enabled: !!publicKey,
      staleTime: 30 * 1000,
      refetchInterval: 30 * 1000,
      refetchOnWindowFocus: false,
    },
  )

  return { data, isLoading }
}
