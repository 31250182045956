import { FC, SVGProps } from 'react'

export const Link: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" {...props}>
    <g clipPath="url(#clip0_3694_58003)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.97222 4.52083C3.73834 4.52083 2.6875 5.60187 2.6875 6.99999C2.6875 8.39812 3.73834 9.47916 4.97222 9.47916H6.33333C6.57496 9.47916 6.77083 9.67504 6.77083 9.91666C6.77083 10.1583 6.57496 10.3542 6.33333 10.3542H4.97222C3.19922 10.3542 1.8125 8.82353 1.8125 6.99999C1.8125 5.17646 3.19922 3.64583 4.97222 3.64583H6.33333C6.57496 3.64583 6.77083 3.8417 6.77083 4.08333C6.77083 4.32495 6.57496 4.52083 6.33333 4.52083H4.97222ZM8.22917 4.08333C8.22917 3.8417 8.42504 3.64583 8.66667 3.64583H10.0278C11.8008 3.64583 13.1875 5.17646 13.1875 6.99999C13.1875 8.82353 11.8008 10.3542 10.0278 10.3542H8.66667C8.42504 10.3542 8.22917 10.1583 8.22917 9.91666C8.22917 9.67504 8.42504 9.47916 8.66667 9.47916H10.0278C11.2617 9.47916 12.3125 8.39812 12.3125 6.99999C12.3125 5.60187 11.2617 4.52083 10.0278 4.52083H8.66667C8.42504 4.52083 8.22917 4.32495 8.22917 4.08333ZM4.72917 6.99999C4.72917 6.75837 4.92504 6.56249 5.16667 6.56249L9.83333 6.56249C10.075 6.56249 10.2708 6.75837 10.2708 6.99999C10.2708 7.24162 10.075 7.43749 9.83333 7.43749L5.16667 7.43749C4.92504 7.43749 4.72917 7.24162 4.72917 6.99999Z"
        fill="#389E0D"
      />
    </g>
    <defs>
      <clipPath id="clip0_3694_58003">
        <rect width="14" height="14" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
)
