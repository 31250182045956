import { createJupiterApiClient } from '@jup-ag/api'
import { LendingTokenType } from 'fbonds-core/lib/fbond-protocol/types'

import { USDC_ADDRESS, WSOL_ADDRESS } from '@banx/constants'
import { getTokenDecimals } from '@banx/utils'

type FetchConversionRate = {
  lendingTokenType: LendingTokenType
  tokenMint: string
  tokenDecimals: number
  slippageBps: number
}
export const fetchConversionRate = async ({
  lendingTokenType,
  tokenMint,
  tokenDecimals,
  slippageBps = 300,
}: FetchConversionRate): Promise<number> => {
  try {
    const lendingTokenDecimals = getTokenDecimals(lendingTokenType)
    const lendingTokenMint =
      lendingTokenType === LendingTokenType.Usdc ? USDC_ADDRESS : WSOL_ADDRESS

    const jupiterQuoteApi = createJupiterApiClient()
    const quote = await jupiterQuoteApi.quoteGet({
      inputMint: lendingTokenMint,
      outputMint: tokenMint,
      amount: lendingTokenDecimals,
      slippageBps,
      computeAutoSlippage: true,
      swapMode: 'ExactIn',
      onlyDirectRoutes: true,
      asLegacyTransaction: false,
      maxAccounts: 64,
      minimizeSlippage: false,
      // dexes: ['Whirlpool'],
    })

    return parseInt(quote.outAmount) / 10 ** tokenDecimals
  } catch (error) {
    console.error(error)
    throw new Error('Failed to fetch token price')
  }
}
