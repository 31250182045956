import { useEffect, useMemo } from 'react'

import { useWallet } from '@solana/wallet-adapter-react'
import { BN } from 'fbonds-core'
import { useLocation } from 'react-router-dom'

import { useCollateralsList } from '@banx/hooks'
import { useModal, useTokenType } from '@banx/store/common'
import {
  adjustTokenAmountWithUpfrontFee,
  bnToHuman,
  getTokenDecimals,
  limitDecimalPlaces,
  stringToBN,
} from '@banx/utils'

import { BorrowToken } from '../../constants'
import { useBorrowTokensList } from '../../hooks'
import { WarningModal } from '../components'
import { getErrorMessage, getInitialCollateral } from '../helpers'
import { useBorrowOffers } from './useBorrowOffers'
import { useBorrowOffersTransaction } from './useBorrowOffersTransaction'
import { useBorrowStore } from './useBorrowStore'
import { useSelectedOffer } from './useSelectedOffers'

export const useInstantBorrowContent = () => {
  const location = useLocation()
  const { ticker: tickerParam } = location.state || {}

  const { publicKey } = useWallet()
  const walletPubkey = publicKey?.toBase58() ?? ''

  const { tokenType, setTokenType } = useTokenType()
  const { open: openModal } = useModal()

  const { collateralsList } = useCollateralsList()
  const { borrowTokensList } = useBorrowTokensList()

  const {
    collateral,
    setCollateral,
    borrowToken,
    setBorrowToken,
    ltvSliderValue,
    setLtvSlider,
    collateralInputValue,
    setCollateralInputValue,
    borrowInputValue,
    setBorrowInputValue,
  } = useBorrowStore()

  const { data: offers, isLoading } = useBorrowOffers()

  const { borrow, isBorrowing } = useBorrowOffersTransaction()
  const { selectedOffer } = useSelectedOffer()

  const marketTokenDecimals = Math.log10(getTokenDecimals(tokenType))

  const initialCollateral = useMemo(() => {
    if (!collateralsList.length) return

    return tickerParam
      ? collateralsList.find((token) => token.collateral.ticker === tickerParam)
      : getInitialCollateral(collateralsList)
  }, [collateralsList, tickerParam])

  useEffect(() => {
    if (!walletPubkey) {
      setBorrowInputValue('0')
      setCollateralInputValue('0')
      return
    }
    setCollateral(undefined)
  }, [setBorrowInputValue, setCollateral, setCollateralInputValue, walletPubkey])

  const initialBorrowToken = useMemo(
    () => borrowTokensList.find((token) => token.lendingTokenType === tokenType),
    [borrowTokensList, tokenType],
  )

  useEffect(() => {
    if (!collateral && initialCollateral) {
      setCollateral(initialCollateral)
    }
  }, [collateral, initialCollateral, setCollateral])

  useEffect(() => {
    if (!initialBorrowToken) return

    const isSameMint = collateral?.collateral.mint === initialBorrowToken.collateral.mint
    if (isSameMint) {
      setCollateral(initialCollateral!)
    }

    setBorrowToken(initialBorrowToken)
  }, [collateral, initialCollateral, setCollateral, setBorrowToken, initialBorrowToken])

  useEffect(() => {
    if (!collateral || !selectedOffer) return

    const maxCollateralAmount = new BN(selectedOffer.maxCollateralToReceive)
    const availableAmount = BN.min(collateral.amountInWallet, maxCollateralAmount)
    const totalAmountStr = bnToHuman(availableAmount, collateral.collateral.decimals).toString()

    if (totalAmountStr !== collateralInputValue) {
      setCollateralInputValue(limitDecimalPlaces(totalAmountStr, 6))
    }
  }, [selectedOffer, collateral, collateralInputValue, setCollateralInputValue])

  const maxBorrowableAmount = useMemo(() => {
    if (!selectedOffer) return

    const marketUpfrontFee = new BN(collateral?.collateral.upfrontFee || 0)

    return bnToHuman(
      adjustTokenAmountWithUpfrontFee(selectedOffer.maxBorrow, marketUpfrontFee),
      marketTokenDecimals,
    )
  }, [collateral, marketTokenDecimals, selectedOffer])

  const canFundRequiredBorrowAmount = useMemo(() => {
    if (!selectedOffer) return

    const borrowAmount = stringToBN(borrowInputValue, marketTokenDecimals)
    return new BN(selectedOffer.maxTokenToGet).gte(borrowAmount)
  }, [borrowInputValue, marketTokenDecimals, selectedOffer])

  const handleBorrowTokenChange = (token: BorrowToken) => {
    setBorrowToken(token)
    setTokenType(token.lendingTokenType)
  }

  const onSubmit = () => {
    if (!selectedOffer || !collateral) return

    if (!canFundRequiredBorrowAmount) {
      return openModal(WarningModal, {
        offer: selectedOffer,
        collateral: collateral,
        onSubmit: () => borrow(selectedOffer, collateral),
      })
    }

    return borrow(selectedOffer, collateral)
  }

  const errorMessage = getErrorMessage({
    borrowToken,
    borrowInputValue,
    collateral,
  })

  const maxAvailableLtv = Math.min((selectedOffer?.maxLtv || 0) / 100, 100)

  return {
    offers,
    isLoading,
    selectedOffer,
    maxBorrowableAmount,

    borrowTokensList,
    collateralsList,

    borrowToken,
    borrowInputValue,
    setBorrowInputValue,
    handleBorrowTokenChange,

    collateral,
    collateralInputValue,
    setCollateral,

    maxAvailableLtv,
    ltvSliderValue,
    setLtvSlider,

    errorMessage,
    isBorrowing,
    onSubmit,
  }
}
