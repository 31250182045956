import { FC, SVGProps } from 'react'

export const Warning: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_11313_45736)">
      <path
        d="M6.13515 4.68972C6.74399 3.59379 7.04842 3.04583 7.44967 2.864C7.79945 2.70549 8.20058 2.70549 8.55036 2.864C8.95162 3.04583 9.25604 3.59379 9.86489 4.68971L12.9059 10.1636C13.4852 11.2063 13.7748 11.7276 13.725 12.1543C13.6815 12.5266 13.4833 12.8634 13.179 13.0822C12.8302 13.3329 12.2338 13.3329 11.0411 13.3329H4.95898C3.76619 13.3329 3.16979 13.3329 2.821 13.0822C2.51669 12.8634 2.31852 12.5266 2.27504 12.1543C2.2252 11.7276 2.51484 11.2063 3.09411 10.1636L6.13515 4.68972Z"
        fill="#F86A3A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.24328 2.40858C7.72422 2.19063 8.27578 2.19063 8.75672 2.40858C9.08601 2.55779 9.32661 2.83532 9.54283 3.15234C9.75817 3.46807 9.99566 3.89556 10.2895 4.42454L13.3553 9.94284C13.6345 10.4454 13.8609 10.853 14.0088 11.1881C14.158 11.5261 14.2623 11.8638 14.2216 12.2123C14.1618 12.7242 13.8893 13.1873 13.4709 13.4881C13.1861 13.6929 12.8402 13.7658 12.4722 13.7995C12.1075 13.833 11.6412 13.833 11.0663 13.8329H4.93373C4.35877 13.833 3.89254 13.833 3.52781 13.7995C3.1598 13.7658 2.81394 13.6929 2.5291 13.4881C2.11067 13.1873 1.83818 12.7242 1.7784 12.2123C1.7377 11.8638 1.842 11.5261 1.99121 11.1881C2.1391 10.853 2.36553 10.4454 2.64475 9.94283L5.71047 4.42454C6.00434 3.89555 6.24183 3.46807 6.45717 3.15234C6.67339 2.83532 6.91399 2.55779 7.24328 2.40858ZM8.34397 3.31942C8.12535 3.22035 7.87465 3.22035 7.65604 3.31942C7.58406 3.35203 7.47183 3.4394 7.28331 3.7158C7.09694 3.98906 6.88144 4.37592 6.57221 4.93254L3.53117 10.4064C3.23676 10.9363 3.0332 11.3038 2.90607 11.5918C2.77811 11.8818 2.76251 12.0181 2.77165 12.0963C2.79882 12.329 2.92268 12.5395 3.11287 12.6762C3.17682 12.7222 3.30355 12.7747 3.61914 12.8037C3.93268 12.8324 4.35274 12.8329 4.95896 12.8329H11.041C11.6473 12.8329 12.0673 12.8324 12.3809 12.8037C12.6965 12.7747 12.8232 12.7222 12.8871 12.6762C13.0773 12.5395 13.2012 12.329 13.2284 12.0963C13.2375 12.0181 13.2219 11.8818 13.0939 11.5918C12.9668 11.3038 12.7632 10.9363 12.4688 10.4064L9.42779 4.93253C9.11856 4.37592 8.90306 3.98906 8.71669 3.7158C8.52817 3.4394 8.41594 3.35203 8.34397 3.31942ZM8 6.16628C8.27614 6.16628 8.5 6.39014 8.5 6.66628V9.33295C8.5 9.60909 8.27614 9.83295 8 9.83295C7.72386 9.83295 7.5 9.60909 7.5 9.33295V6.66628C7.5 6.39014 7.72386 6.16628 8 6.16628ZM7.5 11.3329C7.5 11.0568 7.72386 10.8329 8 10.8329H8.00667C8.28281 10.8329 8.50667 11.0568 8.50667 11.3329C8.50667 11.6091 8.28281 11.8329 8.00667 11.8329H8C7.72386 11.8329 7.5 11.6091 7.5 11.3329Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_11313_45736">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
