import { FC, SVGProps } from 'react'

export const CircleCheck: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.5 12C21.5 16.9706 17.4706 21 12.5 21C7.52944 21 3.5 16.9706 3.5 12C3.5 7.02944 7.52944 3 12.5 3C17.4706 3 21.5 7.02944 21.5 12Z"
      fill="#9CFF1F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 3.75C7.94365 3.75 4.25 7.44365 4.25 12C4.25 16.5563 7.94365 20.25 12.5 20.25C17.0563 20.25 20.75 16.5563 20.75 12C20.75 7.44365 17.0563 3.75 12.5 3.75ZM2.75 12C2.75 6.61522 7.11522 2.25 12.5 2.25C17.8848 2.25 22.25 6.61522 22.25 12C22.25 17.3848 17.8848 21.75 12.5 21.75C7.11522 21.75 2.75 17.3848 2.75 12ZM17.0087 8.4489C17.3131 8.72985 17.3321 9.20434 17.0511 9.50871L11.5126 15.5087C11.3707 15.6625 11.1709 15.75 10.9615 15.75C10.7522 15.75 10.5524 15.6625 10.4104 15.5087L7.9489 12.842C7.66794 12.5377 7.68692 12.0632 7.99129 11.7822C8.29566 11.5013 8.77015 11.5203 9.0511 11.8246L10.9615 13.8943L15.9489 8.49129C16.2298 8.18692 16.7043 8.16794 17.0087 8.4489Z"
      fill="black"
    />
  </svg>
)
