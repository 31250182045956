import { useMemo, useState } from 'react'

import { isEmpty } from 'lodash'

import { filterBySearchQuery } from '@banx/components/Search'

import { MESSAGES } from '@banx/constants/messages'
import { useTokenType } from '@banx/store/common'

import { useSortedOffers } from './useSortedOffers'
import { useUserOffers } from './useUserOffers'

export const useOffersContent = () => {
  const { tokenType, setTokenType } = useTokenType()

  const { offers, isLoading, marketsPreview } = useUserOffers({
    refetchInterval: 30 * 1000,
  })

  const [searchQuery, setSearchQuery] = useState<string>('')
  const [visibleOfferPubkey, setOfferPubkey] = useState('')

  const onCardClick = (offerPubkey: string) => {
    const isSameOfferPubkey = visibleOfferPubkey === offerPubkey
    const nextValue = !isSameOfferPubkey ? offerPubkey : ''
    return setOfferPubkey(nextValue)
  }

  const filteredBySearchQuery = useMemo(() => {
    return filterBySearchQuery(offers, searchQuery, [
      (offer) => offer.collectionMeta.collectionName,
    ])
  }, [offers, searchQuery])

  const { sortParams, sortedOffers } = useSortedOffers(filteredBySearchQuery)

  const isNoOffers = isEmpty(offers) && !isLoading
  const isFilteredListEmpty = isEmpty(filteredBySearchQuery) && !isLoading

  const filteredListEmptyMessage = (() => {
    if (isFilteredListEmpty && searchQuery) return MESSAGES.EMPTY_SEARCH_RESULTS
    if (isFilteredListEmpty) return MESSAGES.EMPTY_FILTERED_LIST
    return ''
  })()

  return {
    marketsPreview,
    offersToDisplay: sortedOffers,
    isLoading,

    isNoOffers,
    isFilteredListEmpty,
    filteredListEmptyMessage,

    visibleOfferPubkey,
    onCardClick,

    searchQuery,
    setSearchQuery,

    sortParams,

    tokenType,
    setTokenType,
  }
}
