import { FC, SVGProps } from 'react'

export const Logo: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9073 27.2905C23.7195 27.2905 26.81 24.2 26.81 20.3877C26.81 16.5755 23.7195 13.485 19.9073 13.485C16.095 13.485 13.0045 16.5755 13.0045 20.3877C13.0045 24.2 16.095 27.2905 19.9073 27.2905ZM19.9073 30.4825C25.4824 30.4825 30.002 25.9629 30.002 20.3877C30.002 14.8125 25.4824 10.293 19.9073 10.293C14.3321 10.293 9.8125 14.8125 9.8125 20.3877C9.8125 25.9629 14.3321 30.4825 19.9073 30.4825Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.1501 4.52235C28.9159 6.23765 30.2871 8.35688 31.1148 10.7309C28.6687 8.33789 25.3209 6.86278 21.6286 6.86278C14.7312 6.86278 9.036 12.0103 8.17507 18.6733C8.1008 18.0986 8.0625 17.5126 8.0625 16.9176C8.0625 9.4253 14.1363 3.35156 21.6286 3.35156C23.5948 3.35156 25.4634 3.76987 27.1501 4.52235Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.4994 19.9866C31.8263 18.7119 32.0002 17.3759 32.0002 15.9993C32.0002 14.9036 31.89 13.8337 31.6803 12.7999C29.4334 10.258 26.2363 8.55059 22.5872 8.26174C21.9941 8.21479 21.4069 8.20674 20.8281 8.23542C26.5612 9.44232 30.8552 14.2231 31.4994 19.9866Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.6334 2.52677C23.5637 2.28322 22.4502 2.15461 21.3067 2.15461C13.0652 2.15461 6.38404 8.83572 6.38404 17.0773C6.38404 24.4297 11.7013 30.5402 18.7001 31.7731C17.8224 31.9223 16.9202 32 16 32C7.16346 32 0 24.8366 0 16C0 7.16344 7.16346 0 16 0C19.1797 0 22.1428 0.927525 24.6334 2.52677Z"
      fill="black"
    />
  </svg>
)
