import { FC, useState } from 'react'

import classNames from 'classnames'

import { Button } from '@banx/components/Buttons'
import { SearchSelect, SearchSelectProps } from '@banx/components/SearchSelect'
import { SortDropdown, SortDropdownProps } from '@banx/components/SortDropdown'
import Tooltip from '@banx/components/Tooltip'

import { CoinCrashed, CoinPlus, Warning } from '@banx/icons'

import { SortPreviewField } from '../constants'

import styles from '../LenderTokenLoansContent.module.less'

interface FilterSectionProps<T> {
  searchSelectParams: SearchSelectProps<T>
  sortParams: SortDropdownProps<SortPreviewField>

  terminatingLoansAmount: number
  isTerminationFilterEnabled: boolean
  toggleTerminationFilter: () => void

  liquidatedLoansAmount: number
  isLiquidatedFilterEnabled: boolean
  toggleLiquidatedFilter: () => void

  underwaterLoansAmount: number
  isUnderwaterFilterEnabled: boolean
  toggleUnderwaterFilter: () => void
}

export const FilterSection = <T extends object>({
  searchSelectParams,
  sortParams,

  terminatingLoansAmount,
  isTerminationFilterEnabled,
  toggleTerminationFilter,

  liquidatedLoansAmount,
  isLiquidatedFilterEnabled,
  toggleLiquidatedFilter,

  underwaterLoansAmount,
  isUnderwaterFilterEnabled,
  toggleUnderwaterFilter,
}: FilterSectionProps<T>) => {
  const [searchSelectCollapsed, setSearchSelectCollapsed] = useState(true)

  return (
    <div className={styles.container}>
      <div className={styles.filterContent}>
        <SearchSelect
          {...searchSelectParams}
          className={styles.searchSelect}
          collapsed={searchSelectCollapsed}
          onChangeCollapsed={setSearchSelectCollapsed}
          disabled={!searchSelectParams.options.length}
        />

        <div className={styles.filterButtons}>
          <LiquidatedFilterButton
            loansAmount={liquidatedLoansAmount}
            isActive={isLiquidatedFilterEnabled}
            onClick={toggleLiquidatedFilter}
          />

          <TerminatingFilterButton
            loansAmount={terminatingLoansAmount}
            isActive={isTerminationFilterEnabled}
            onClick={toggleTerminationFilter}
          />

          <UnderwaterFilterButton
            loansAmount={underwaterLoansAmount}
            isActive={isUnderwaterFilterEnabled}
            onClick={toggleUnderwaterFilter}
          />
        </div>
      </div>

      <SortDropdown
        {...sortParams}
        className={!searchSelectCollapsed ? styles.dropdownHidden : ''}
      />
    </div>
  )
}

interface FilterButtonProps {
  onClick: () => void
  isActive: boolean
  loansAmount: number | null
}

const LiquidatedFilterButton: FC<FilterButtonProps> = ({ isActive, onClick, loansAmount }) => (
  <Tooltip title={loansAmount ? 'Liquidated loans' : 'No liquidated loans currently'}>
    <div
      className={classNames(styles.filterButtonWrapper, styles.liquidated)}
      data-loans-amount={loansAmount && loansAmount > 0 ? loansAmount : null}
    >
      <Button
        className={classNames(
          styles.liquidatedFilterButton,
          { [styles.active]: isActive },
          { [styles.disabled]: !loansAmount },
        )}
        disabled={!loansAmount}
        onClick={onClick}
        variant="secondary"
        type="circle"
      >
        <CoinCrashed />
      </Button>
    </div>
  </Tooltip>
)

const TerminatingFilterButton: FC<FilterButtonProps> = ({ isActive, onClick, loansAmount }) => (
  <Tooltip title={loansAmount ? 'Terminating loans' : 'No terminating loans currently'}>
    <div
      className={classNames(styles.filterButtonWrapper, styles.terminating)}
      data-loans-amount={loansAmount && loansAmount > 0 ? loansAmount : null}
    >
      <Button
        className={classNames(
          styles.terminatingFilterButton,
          { [styles.active]: isActive },
          { [styles.disabled]: !loansAmount },
        )}
        disabled={!loansAmount}
        onClick={onClick}
        variant="secondary"
        type="circle"
      >
        <Warning />
      </Button>
    </div>
  </Tooltip>
)

export const UnderwaterFilterButton: FC<FilterButtonProps> = ({
  isActive,
  onClick,
  loansAmount,
}) => (
  <Tooltip title={loansAmount ? 'Underwater loans' : 'No underwater loans currently'}>
    <div
      className={classNames(styles.filterButtonWrapper, styles.underwater)}
      data-loans-amount={loansAmount && loansAmount > 0 ? loansAmount : null}
    >
      <Button
        className={classNames(
          styles.underwaterFilterButton,
          { [styles.active]: isActive },
          { [styles.disabled]: !loansAmount },
        )}
        disabled={!loansAmount}
        onClick={onClick}
        variant="secondary"
        type="circle"
      >
        <CoinPlus />
      </Button>
    </div>
  </Tooltip>
)
