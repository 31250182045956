import { useCallback } from 'react'

import { createJupiterApiClient } from '@jup-ag/api'
import { useQuery } from '@tanstack/react-query'

import { TokenLoan } from '@banx/api/tokens'
import { USDC_ADDRESS, WSOL_ADDRESS } from '@banx/constants'
import { MultiplyPair } from '@banx/pages/tokenLending/LeveragePage/constants'
import { lrtsSOL } from '@banx/transactions/leverage'
import { SSOL_MINT } from '@banx/transactions/leverage/lrtsSOL'
import { caclulateBorrowTokenLoanValue, isBanxSolTokenType } from '@banx/utils'

export const useRepayValueInCollateral = (params: {
  loan: TokenLoan
  pair: MultiplyPair
  slippageBps: number
}) => {
  const { loan, pair, slippageBps } = params

  const fetchSwapCollateralAmount = useCallback(async () => {
    const inputTokenMint = isBanxSolTokenType(loan.bondTradeTransaction.lendingToken)
      ? WSOL_ADDRESS
      : USDC_ADDRESS

    const totalRepayValue = caclulateBorrowTokenLoanValue(loan).toNumber()

    const jupiterQuoteApi = createJupiterApiClient()

    //? exception for lrtsSOL because we swap SSOL (not lrtsSOL)
    const outputTokenMint =
      pair.collateralMint === lrtsSOL.LRTS_MINT ? SSOL_MINT : pair.collateralMint

    const priceQuote = await jupiterQuoteApi.quoteGet({
      inputMint: inputTokenMint,
      outputMint: outputTokenMint.toBase58(),
      amount: totalRepayValue,
      slippageBps,
      computeAutoSlippage: true,
      swapMode: 'ExactIn',
      onlyDirectRoutes: true,
      asLegacyTransaction: false,
      minimizeSlippage: false,
      // dexes: ['Whirlpool'],
    })

    return parseInt(priceQuote.outAmount)
  }, [loan, pair, slippageBps])

  const { data: repayValue, isLoading } = useQuery(
    ['fetchSwapCollateralAmount', loan, slippageBps],
    () => fetchSwapCollateralAmount(),
    {
      enabled: !!pair && !!loan,
      staleTime: 1 * 1000,
      refetchOnWindowFocus: false,
    },
  )

  return { repayValue: repayValue || 0, isLoading }
}
