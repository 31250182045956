import { useWallet } from '@solana/wallet-adapter-react'
import { useNavigate } from 'react-router-dom'

import EmptyList from '@banx/components/EmptyList'
import { Loader } from '@banx/components/Loader'

import { MESSAGES } from '@banx/constants/messages'
import { useFakeInfinityScroll } from '@banx/hooks'
import { PATHS } from '@banx/router'
import { buildUrlWithModeAndToken } from '@banx/store'
import { AssetMode } from '@banx/store/common'

import CollateralLoansCard from './components/CollateralLoansCard'
import { FilterSection } from './components/FilterSection'
import { HeaderList } from './components/HeaderList'
import { useLenderTokenLoansContent } from './hooks'

import styles from './LenderTokenLoansContent.module.less'

const LenderTokenLoansContent = () => {
  const { connected } = useWallet()

  const {
    loansPreviews,
    isLoading,
    isNoLoans,
    isFilteredListEmpty,
    filteredListEmptyMessage,
    searchQuery,
    setSearchQuery,
    selectedLendingToken,
    handleSelectedTokenChange,
    terminatingLoansAmount,
    isTerminationFilterEnabled,
    toggleTerminationFilter,
    liquidatedLoansAmount,
    isLiquidatedFilterEnabled,
    toggleLiquidatedFilter,
    underwaterLoansAmount,
    isUnderwaterFilterEnabled,
    toggleUnderwaterFilter,
    expandedPreviewId,
    handleCardToggle,
    sortParams,
  } = useLenderTokenLoansContent()

  const { data, fetchMoreTrigger } = useFakeInfinityScroll({ rawData: loansPreviews })

  if (!connected) return <EmptyList message={MESSAGES.NO_CONNECTED_LOANS} />

  return (
    <div className={styles.content}>
      <FilterSection
        sortParams={sortParams}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        selectedLendingToken={selectedLendingToken}
        handleSelectedTokenChange={handleSelectedTokenChange}
        terminatingLoansAmount={terminatingLoansAmount}
        liquidatedLoansAmount={liquidatedLoansAmount}
        isTerminationFilterEnabled={isTerminationFilterEnabled}
        toggleTerminationFilter={toggleTerminationFilter}
        isLiquidatedFilterEnabled={isLiquidatedFilterEnabled}
        toggleLiquidatedFilter={toggleLiquidatedFilter}
        underwaterLoansAmount={underwaterLoansAmount}
        toggleUnderwaterFilter={toggleUnderwaterFilter}
        isUnderwaterFilterEnabled={isUnderwaterFilterEnabled}
      />

      {!isNoLoans && !isFilteredListEmpty && !isLoading && <HeaderList />}

      {isLoading && <Loader />}
      {isNoLoans && <NoLoans />}
      {isFilteredListEmpty && !isNoLoans && <EmptyList message={filteredListEmptyMessage} />}

      {!isNoLoans && !isLoading && (
        <div className={styles.cardsList}>
          {data.map((preview) => (
            <CollateralLoansCard
              key={preview.id}
              loansPreview={preview}
              onClick={() => handleCardToggle(preview.id)}
              isExpanded={expandedPreviewId === preview.id}
            />
          ))}
          <div ref={fetchMoreTrigger} />
        </div>
      )}
    </div>
  )
}

export default LenderTokenLoansContent

const NoLoans = () => {
  const navigate = useNavigate()

  const goToLendPage = () => {
    navigate(buildUrlWithModeAndToken(PATHS.LEND, AssetMode.Token, null))
  }

  return (
    <EmptyList
      message={MESSAGES.LENDER_NO_LOANS}
      buttonProps={{ text: 'Lend', onClick: goToLendPage }}
    />
  )
}
