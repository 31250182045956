import { FC, SVGProps } from 'react'

export const Logo: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="45"
    height="30"
    viewBox="0 0 45 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M41.8002 9.52434L34.2458 15.0038L36.2028 17.7654L39.7726 22.7984L39.7834 22.8112L37.7299 24.2972L29.8602 30H0L0.0495107 29.9128L4.12241 22.8548L13.1438 7.21449H20.5131L11.5282 22.7855H19.3639L29.128 22.7907L29.1254 22.7855L23.601 14.9987L26.4075 12.966L32.9116 8.24623L34.337 7.21449H20.5131L20.5496 7.14776L21.6023 5.32297L24.6668 0.0128326H24.7319L27.7938 0H35.7989L35.8015 0.00513332L39.8876 0.00769961L41.2294 1.89666L44.9273 7.10154L45 7.2042L41.8002 9.52434Z"
      fill="#1B1B1B"
    />
  </svg>
)
