import { useMemo, useState } from 'react'

import { isEmpty } from 'lodash'

import { filterBySearchQuery } from '@banx/components/Search'

import { MESSAGES } from '@banx/constants/messages'

import { useBorrowerLoansRequests } from '../../../hooks'
import { useSortedLoans } from './useSortedLoans'

export const useRequestsLoansTable = () => {
  const { loans, isLoading } = useBorrowerLoansRequests()

  const [searchQuery, setSearchQuery] = useState<string>('')

  const filteredBySearchQuery = useMemo(() => {
    return filterBySearchQuery(loans, searchQuery, [
      (loan) => loan.nft.meta.collectionName,
      (loan) => loan.nft.meta.name,
      (loan) => loan.nft.mint,
    ])
  }, [loans, searchQuery])

  const { sortedLoans, sortParams } = useSortedLoans(filteredBySearchQuery)

  const isNoLoans = !isLoading && isEmpty(loans)
  const isFilteredListEmpty = !isLoading && isEmpty(filteredBySearchQuery)

  const filteredListEmptyMessage = (() => {
    if (isFilteredListEmpty && searchQuery) return MESSAGES.EMPTY_SEARCH_RESULTS
    if (isFilteredListEmpty) return MESSAGES.EMPTY_FILTERED_LIST
    return ''
  })()

  return {
    loans: sortedLoans,
    loading: isLoading,

    isNoLoans,
    filteredListEmptyMessage,

    searchQuery,
    setSearchQuery,

    sortViewParams: {
      sortParams,
    },
  }
}
