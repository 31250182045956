import { FC, SVGProps } from 'react'

export const Alert: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_10660_49396)">
      <path
        d="M9.20272 7.03506C10.116 5.39118 10.5726 4.56923 11.1745 4.29648C11.6992 4.05872 12.3009 4.05872 12.8255 4.29648C13.4274 4.56923 13.8841 5.39117 14.7973 7.03506L19.3589 15.2459C20.2278 16.8099 20.6622 17.5919 20.5875 18.2319C20.5223 18.7904 20.225 19.2955 19.7685 19.6237C19.2454 19.9999 18.3508 19.9999 16.5616 19.9999H7.43847C5.64928 19.9999 4.75468 19.9999 4.23151 19.6237C3.77503 19.2955 3.47778 18.7904 3.41256 18.2319C3.3378 17.5919 3.77226 16.8099 4.64117 15.2459L9.20272 7.03506Z"
        fill="#FAAD14"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8649 3.61335C11.5863 3.28644 12.4137 3.28644 13.1351 3.61335C13.629 3.83718 13.9899 4.25347 14.3142 4.729C14.6373 5.2026 14.9935 5.84382 15.4343 6.6373L20.0329 14.9147C20.4517 15.6686 20.7914 16.28 21.0132 16.7826C21.237 17.2897 21.3934 17.7963 21.3324 18.3189C21.2427 19.0868 20.834 19.7814 20.2063 20.2327C19.7791 20.5399 19.2603 20.6491 18.7083 20.6998C18.1612 20.7499 17.4618 20.7499 16.5994 20.7499H7.4006C6.53816 20.7499 5.8388 20.7499 5.29171 20.6998C4.7397 20.6491 4.2209 20.5399 3.79365 20.2327C3.166 19.7814 2.75728 19.0868 2.6676 18.3189C2.60655 17.7963 2.763 17.2897 2.98682 16.7826C3.20865 16.28 3.54829 15.6686 3.96713 14.9147L8.5657 6.6373C9.00652 5.84382 9.36274 5.2026 9.68575 4.729C10.0101 4.25347 10.371 3.83718 10.8649 3.61335ZM12.5159 4.97961C12.188 4.83101 11.812 4.83101 11.4841 4.97961C11.3761 5.02854 11.2077 5.15959 10.925 5.57419C10.6454 5.98407 10.3222 6.56437 9.85831 7.39929L5.29676 15.6101C4.85514 16.405 4.54981 16.9562 4.35911 17.3882C4.16717 17.8231 4.14376 18.0276 4.15747 18.1449C4.19823 18.4939 4.38401 18.8097 4.66931 19.0148C4.76524 19.0838 4.95532 19.1626 5.42871 19.206C5.89902 19.2492 6.5291 19.2499 7.43845 19.2499H16.5616C17.4709 19.2499 18.101 19.2492 18.5713 19.206C19.0447 19.1626 19.2348 19.0838 19.3307 19.0148C19.616 18.8097 19.8018 18.4939 19.8425 18.1449C19.8562 18.0276 19.8328 17.8231 19.6409 17.3882C19.4502 16.9562 19.1449 16.405 18.7032 15.6101L14.1417 7.39929C13.6778 6.56437 13.3546 5.98407 13.075 5.57419C12.7923 5.15959 12.6239 5.02854 12.5159 4.97961ZM12 9.24991C12.4142 9.24991 12.75 9.5857 12.75 9.99991V13.9999C12.75 14.4141 12.4142 14.7499 12 14.7499C11.5858 14.7499 11.25 14.4141 11.25 13.9999V9.99991C11.25 9.5857 11.5858 9.24991 12 9.24991ZM11.25 16.9999C11.25 16.5857 11.5858 16.2499 12 16.2499H12.01C12.4242 16.2499 12.76 16.5857 12.76 16.9999C12.76 17.4141 12.4242 17.7499 12.01 17.7499H12C11.5858 17.7499 11.25 17.4141 11.25 16.9999Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_10660_49396">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
