import { FC, useEffect, useState } from 'react'

import { useWallet } from '@solana/wallet-adapter-react'
import { PUBKEY_PLACEHOLDER } from 'fbonds-core/lib/fbond-protocol/constants'

import { Button } from '@banx/components/Buttons'
import { Slider, SliderProps } from '@banx/components/Slider'
import { createDisplayValueJSX } from '@banx/components/TableComponents'
import { Modal } from '@banx/components/modals/BaseModal'

import { CollateralToken } from '@banx/api/tokens'
import { useDebounce } from '@banx/hooks'
import { Wallet } from '@banx/icons'
import { useModal, useTokenType } from '@banx/store/common'
import {
  HealthColorIncreasing,
  ZERO_BN,
  bnToHuman,
  formatValueByTokenType,
  getColorByPercent,
  getTokenUnit,
} from '@banx/utils'

import { SelectTokenButton } from '../components/InputTokenSelect'
import ModalTokenSelect from '../components/ModalTokenSelect'
import { DEBOUNCE_DELAY_MS } from './constants'
import { getSummaryInfo } from './helpers'
import { EnhancedBorrowOffer } from './hooks'

import styles from './InstantBorrowContent.module.less'

interface WarningModalProps {
  offers: EnhancedBorrowOffer[]
  collateral: CollateralToken | undefined
  onSubmit: () => void
}

export const WarningModal: FC<WarningModalProps> = ({ offers, onSubmit, collateral }) => {
  const { close: closeModal } = useModal()
  const { tokenType } = useTokenType()

  const marketPubkey = collateral?.marketPubkey ?? PUBKEY_PLACEHOLDER
  const { weightedApr, weeklyFee, totalAmountToGet, totalCollateralsAmount } = getSummaryInfo(
    offers,
    marketPubkey,
  )

  const tokenUnit = getTokenUnit(tokenType)
  const collateralDecimals = collateral?.collateral.decimals ?? 0

  const formattedValues = {
    totalAmountToGet: formatValueByTokenType(totalAmountToGet.toNumber(), tokenType),
    weeklyFee: formatValueByTokenType(weeklyFee, tokenType),
    collateralsAmount: formatNumber(bnToHuman(totalCollateralsAmount, collateralDecimals)),
    apr: (weightedApr / 100).toFixed(0),
  }

  return (
    <Modal className={styles.warningModal} open onCancel={closeModal} width={496}>
      <div className={styles.warningModalBody}>
        <h3 className={styles.warningModalTitle}>Please pay attention!</h3>

        <div className={styles.warningModalText}>
          The selected offers can only provide a total of{' '}
          <span className={styles.warningModalTokenValue}>
            {createDisplayValueJSX(formattedValues.totalAmountToGet, tokenUnit)}
          </span>{' '}
          secured by{' '}
          <span className={styles.warningModalTokenRow}>
            {formattedValues.collateralsAmount} {collateral?.collateral.ticker}
          </span>{' '}
          with an WAPR of {formattedValues.apr}%. The estimated weekly fee is{' '}
          <span className={styles.warningModalTokenValue}>
            {createDisplayValueJSX(formattedValues.weeklyFee, tokenUnit)}
          </span>
        </div>
      </div>

      <div className={styles.warningModalFooter}>
        <Button onClick={closeModal} className={styles.cancelButton}>
          Cancel
        </Button>
        <Button onClick={onSubmit} className={styles.confirmButton}>
          Confirm
        </Button>
      </div>
    </Modal>
  )
}

const formatNumber = (value = 0) => {
  if (!value) return '--'

  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 2,
  }).format(value)
}

interface LtvSliderProps extends SliderProps {
  label: string
  value: number
  onChange: (value: number) => void
}

export const LtvSlider: FC<LtvSliderProps> = ({ label, value, onChange, ...props }) => {
  const [localSliderValue, setLocalSliderValue] = useState(value)

  const debouncedSetLtvSlider = useDebounce<number>((val) => onChange(val!), DEBOUNCE_DELAY_MS)

  useEffect(() => {
    setLocalSliderValue(value)
  }, [value])

  const handleSliderChange = (newValue: number) => {
    setLocalSliderValue(newValue)
    debouncedSetLtvSlider(newValue)
  }

  const labelColor = getColorByPercent(localSliderValue, HealthColorIncreasing)
  const sliderColorClassName = getColorByPercent(localSliderValue, {
    25: styles.maxLtvSliderGreen,
    50: styles.maxLtvSliderYellow,
    75: styles.maxLtvSliderOrange,
    100: styles.maxLtvSliderRed,
  })

  return (
    <div className={styles.sliderContainer}>
      <div className={styles.sliderLabels}>
        <p className={styles.loanValueLabel}>
          {label}:{' '}
          <span className={styles.loanValue} style={{ color: labelColor }}>
            {localSliderValue}%
          </span>
        </p>
      </div>

      <Slider
        value={localSliderValue}
        onChange={handleSliderChange}
        min={10}
        max={100}
        marks={{}}
        rootClassName={sliderColorClassName}
        className={styles.ltvSlider}
        {...props}
      />
    </div>
  )
}

interface CollateralFieldProps {
  label: string
  value: string
  collateral: CollateralToken | undefined
  onChange: (token: CollateralToken) => void
  tokensList: CollateralToken[]
}

export const CollateralField: FC<CollateralFieldProps> = ({
  label,
  value,
  collateral,
  tokensList,
  onChange,
}) => {
  const { amountInWallet = ZERO_BN, collateral: { decimals = 0 } = {} } = collateral || {}

  const { connected } = useWallet()
  const { open: openModal } = useModal()

  const handleOpenModal = () => {
    openModal(ModalTokenSelect, { onChangeToken: onChange, tokensList })
  }

  return (
    <div className={styles.collateralField}>
      <div className={styles.collateralFieldLabelWrapper}>
        <span className={styles.collateralFieldLabel}>{label}</span>
        {connected && (
          <span className={styles.collateralFieldBalance}>
            <Wallet /> {formatNumber(bnToHuman(amountInWallet, decimals))}
          </span>
        )}
      </div>
      <div className={styles.collateralFieldContent}>
        <SelectTokenButton onClick={handleOpenModal} token={collateral} />
        <span className={styles.collateralFieldValue}>{value}</span>
      </div>
    </div>
  )
}
