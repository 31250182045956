import { FC, SVGProps } from 'react'

export const ChangeWallet: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
    <path
      d="M21.3055 10.7997H20.2508C19.8063 6.98019 16.4916 3.91406 12.4989 3.91406C10.2915 3.91406 8.28764 4.87835 6.90148 6.38504C6.53234 6.74665 6.54741 7.22879 6.84874 7.50753C7.15762 7.79381 7.60209 7.79381 7.97877 7.44727C9.10126 6.23437 10.7134 5.47349 12.4989 5.47349C15.678 5.47349 18.2469 7.80134 18.6763 10.7997H17.5237C16.9436 10.7997 16.7854 11.2291 17.1244 11.6961L18.9249 14.2425C19.2037 14.6191 19.6255 14.6267 19.8967 14.2425L21.7048 11.7037C22.0438 11.2291 21.8931 10.7997 21.3055 10.7997ZM3.69222 12.6152H4.75444C5.19891 16.4347 8.51364 19.5008 12.4989 19.5008C14.7212 19.5008 16.7251 18.529 18.1113 17.0223C18.4729 16.6607 18.4578 16.1786 18.1565 15.8998C17.8476 15.6136 17.4107 15.6136 17.0265 15.9676C15.9191 17.1805 14.3069 17.9414 12.4989 17.9414C9.32726 17.9414 6.75834 15.6136 6.32893 12.6152H7.47402C8.04657 12.6152 8.21231 12.1858 7.8733 11.7187L6.06526 9.17243C5.79406 8.79576 5.37218 8.78823 5.10098 9.17243L3.29294 11.7112C2.9464 12.1858 3.1046 12.6152 3.69222 12.6152Z"
      fill="#F3F3F3"
    />
  </svg>
)
