import { FC, ReactNode } from 'react'

import classNames from 'classnames'
import { LendingTokenType } from 'fbonds-core/lib/fbond-protocol/types'

import { useImagePreload } from '@banx/hooks'
import { PlaceholderPFP, SOLFilled, USDC } from '@banx/icons'
import { ViewState, useTableView } from '@banx/store/common'
import { formatCompact, isUsdcTokenType } from '@banx/utils'

import Checkbox from '../Checkbox'
import Tooltip from '../Tooltip/Tooltip'
import { DisplayValue } from './helpers'

import styles from './TableCells.module.less'

interface NftInfoCellProps {
  nftName: string
  nftImage: string
  selected?: boolean
  onCheckboxClick?: () => void

  checkboxClassName?: string

  banxPoints?: {
    partnerPoints: number
    playerPoints: number
  }

  rightContentJSX?: ReactNode
  hideCollectionName?: boolean
}

const createDisplayNftNameJSX = (
  nftName: string,
  isCardView: boolean,
  hideCollectionName: boolean,
) => {
  const [collectionName, nftId] = nftName.split('#')

  const defaultNftIdName = hideCollectionName ? collectionName : ''
  const displayNftId = nftId ? `#${nftId}` : defaultNftIdName

  const ellipsisClass = { [styles.ellipsis]: !isCardView }

  return (
    <div className={styles.nftNames}>
      {!hideCollectionName && (
        <p className={classNames(styles.nftCollectionName, ellipsisClass)}>{collectionName}</p>
      )}

      <p className={classNames(styles.nftNumber, ellipsisClass)}>{displayNftId}</p>
    </div>
  )
}

export const NftInfoCell: FC<NftInfoCellProps> = ({
  nftName,
  nftImage,
  onCheckboxClick,
  selected = false,
  banxPoints,
  checkboxClassName,
  hideCollectionName = false,
  rightContentJSX,
}) => {
  const { viewState } = useTableView()
  const isCardView = viewState === ViewState.CARD

  return (
    <div className={styles.nftInfo}>
      {onCheckboxClick && !isCardView && (
        <Checkbox
          className={classNames(styles.checkbox, checkboxClassName)}
          onChange={onCheckboxClick}
          checked={selected}
        />
      )}

      <div className={styles.nftImageWrapper}>
        {!!banxPoints?.partnerPoints && <PointsBanxBadge {...banxPoints} />}
        <NftImage nftImage={nftImage} />
        {selected && isCardView && <div className={styles.selectedCollectionOverlay} />}
      </div>

      {createDisplayNftNameJSX(nftName, isCardView, hideCollectionName)}
      {rightContentJSX}
    </div>
  )
}

interface NftImageProps {
  nftImage: string
}

export const NftImage: FC<NftImageProps> = ({ nftImage }) => {
  const imageLoaded = useImagePreload(nftImage)

  return imageLoaded ? (
    <img src={nftImage} className={styles.nftImage} />
  ) : (
    <PlaceholderPFP className={styles.nftPlaceholderIcon} />
  )
}

interface PointsBanxBadgeProps {
  playerPoints: number
  partnerPoints: number
  className?: string
}

export const PointsBanxBadge: FC<PointsBanxBadgeProps> = ({
  playerPoints,
  partnerPoints,
  className,
}) => {
  return (
    <Tooltip title="Partner Points / Player Points">
      <div className={classNames(styles.badge, className)}>
        {partnerPoints}/{playerPoints}
      </div>
    </Tooltip>
  )
}

interface CollateralTokenCellProps {
  amount: number

  logoUrl?: string
  ticker?: string
  collateralPrice?: number

  selected?: boolean
  onCheckboxClick?: () => void
  checkboxClassName?: string

  lendingToken?: LendingTokenType
  showLendingTokenIcon?: boolean

  rightContentJSX?: ReactNode
  className?: string
}

export const CollateralTokenCell: FC<CollateralTokenCellProps> = ({
  amount,
  logoUrl,
  ticker,
  selected = false,
  onCheckboxClick,
  checkboxClassName,
  className,
  lendingToken,
  showLendingTokenIcon,
  rightContentJSX,
  collateralPrice = 0,
}) => {
  const shouldShowLogos = logoUrl || (lendingToken && showLendingTokenIcon)
  const formattedAmount = amount ? formatCompact(amount.toString(), 2) : null
  const totalValue = collateralPrice > 0 && amount > 0 ? collateralPrice * amount : 0

  return (
    <div className={styles.collateralTokenCell}>
      {onCheckboxClick && (
        <Checkbox
          className={classNames(styles.checkbox, checkboxClassName)}
          onChange={onCheckboxClick}
          checked={selected}
        />
      )}

      <div className={classNames(styles.collateralTokenCellСontent, className)}>
        {shouldShowLogos && (
          <div className={styles.collateralImageWrapper}>
            <TokenLogo logoUrl={logoUrl} />
            <LendingTokenLogo lendingToken={lendingToken} showIcon={showLendingTokenIcon} />
          </div>
        )}

        <div className={styles.collateralTokenMainInfo}>
          <div className={styles.collateralTokenMainInfoRow}>
            {formattedAmount && <span>{formattedAmount}</span>}
            {ticker && <span>{ticker}</span>}
          </div>
          {totalValue > 0 && (
            <span className={styles.collateralTokenPrice}>
              <DisplayValue value={totalValue} strictTokenType={lendingToken} />
            </span>
          )}
        </div>

        {rightContentJSX}
      </div>
    </div>
  )
}

interface TokenLogoProps {
  logoUrl?: string
  altText?: string
  className?: string
}

const TokenLogo: FC<TokenLogoProps> = ({ logoUrl, altText, className }) => {
  if (!logoUrl) return null
  return <img src={logoUrl} alt={altText} className={classNames(styles.tokenLogo, className)} />
}

interface LendingTokenLogoProps {
  lendingToken: LendingTokenType | undefined
  showIcon?: boolean
}

const LendingTokenLogo: FC<LendingTokenLogoProps> = ({ lendingToken, showIcon }) => {
  if (!lendingToken || !showIcon) return null

  return isUsdcTokenType(lendingToken) ? (
    <USDC className={styles.lendingTokenLogo} />
  ) : (
    <SOLFilled className={styles.lendingTokenLogo} />
  )
}
