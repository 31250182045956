import { useEffect, useMemo } from 'react'

import { useWallet } from '@solana/wallet-adapter-react'
import { useQuery } from '@tanstack/react-query'
import { BN } from 'fbonds-core'
import { PUBKEY_PLACEHOLDER } from 'fbonds-core/lib/fbond-protocol/constants'
import { getBondingCurveTypeFromLendingToken } from 'fbonds-core/lib/fbond-protocol/functions/perpetual'
import { LendingTokenType } from 'fbonds-core/lib/fbond-protocol/types'
import { chain, clamp, find } from 'lodash'

import { BorrowOffer, CollateralToken, core } from '@banx/api/tokens'
import { adjustTokenAmountWithUpfrontFee, bnToHuman } from '@banx/utils'

import { BorrowToken } from '../../constants'
import { getMaxBorrowableAmount } from '../OrderBook/helpers'
import { MAX_SLIDER_PERCENT, MIN_OFFER_SIZE, MIN_SLIDER_PERCENT } from '../constants'
import { useBorrowStore } from './useBorrowStore'

export interface EnhancedBorrowOffer extends BorrowOffer {
  maxLtv: string
  disabled: boolean
}

export const useBorrowOffers = () => {
  const { publicKey } = useWallet()
  const walletPubkey = publicKey?.toBase58() ?? ''

  const { borrowToken, collateralToken, setLtvSlider, ltvSliderValue, setBorrowInputValue } =
    useBorrowStore()

  const { suggestedOffers, allOffers, isLoading } = useFetchOffers({
    collateralToken,
    borrowToken,
    customLtv: ltvSliderValue,
    walletPubkey,
  })

  const mergedOffers = useMemo(() => {
    if (!suggestedOffers && !allOffers) return []

    const prioritizeOffers = (offer: BorrowOffer): EnhancedBorrowOffer => {
      const isSuggested = find(suggestedOffers, { publicKey: offer.publicKey })
      const isDisabled = !isSuggested || ltvSliderValue > parseLtvToPercent(offer.ltv)

      return { ...(isSuggested ?? offer), disabled: isDisabled, maxLtv: offer.ltv }
    }

    return (
      chain(allOffers)
        .map(prioritizeOffers)
        //? Filter out offers with a size smaller than the minimum threshold
        .filter((offer) => new BN(offer.maxTokenToGet).gte(new BN(MIN_OFFER_SIZE)))
        .sortBy((offer) => parseFloat(offer.apr))
        .value()
    )
  }, [allOffers, suggestedOffers, ltvSliderValue])

  //? Normalize all offers to EnhancedBorrowOffer type
  const normalizedAllOffers = useMemo(() => {
    if (!allOffers) return []

    return allOffers.map((offer) => ({
      ...offer,
      maxLtv: offer.ltv,
      disabled: false,
    }))
  }, [allOffers])

  useEffect(() => {
    if (!normalizedAllOffers.length || !collateralToken || !borrowToken) return

    if (!walletPubkey) {
      setLtvSlider(0)
      setBorrowInputValue('')
      return
    }

    const maxBorrowable = adjustTokenAmountWithUpfrontFee(
      getMaxBorrowableAmount({
        offers: normalizedAllOffers,
        collateralAmount: collateralToken.amountInWallet,
        tokenDecimals: borrowToken.collateral.decimals,
      }),
    )

    const borrowValue = bnToHuman(maxBorrowable, borrowToken.collateral.decimals)
    setBorrowInputValue(borrowValue.toString())

    setLtvSlider(getLowestLtv(normalizedAllOffers))
  }, [
    normalizedAllOffers,
    collateralToken,
    borrowToken,
    walletPubkey,
    setLtvSlider,
    setBorrowInputValue,
  ])

  return {
    data: mergedOffers,
    allOffers: normalizedAllOffers,
    isLoading,
  }
}

const useFetchOffers = (props: {
  collateralToken: CollateralToken | undefined
  borrowToken: BorrowToken | undefined
  customLtv: number
  walletPubkey: string
}) => {
  const { borrowToken, collateralToken, customLtv, walletPubkey } = props

  const queryParams = {
    market: collateralToken?.marketPubkey ?? '',
    bondingCurveType: getBondingCurveTypeFromLendingToken(
      borrowToken?.lendingTokenType ?? LendingTokenType.Usdc,
    ),
    excludeWallet: walletPubkey || PUBKEY_PLACEHOLDER,
  }

  const fetchOffers = (customLtv?: number) => core.fetchBorrowOffers({ ...queryParams, customLtv })

  const allOffersQuery = useQuery(
    ['allBorrowOffers', collateralToken?.marketPubkey, borrowToken?.lendingTokenType],
    () => fetchOffers(),
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
      enabled: Boolean(collateralToken && borrowToken),
    },
  )

  const suggestedOffersQuery = useQuery(
    [
      'suggestedBorrowOffers',
      collateralToken?.marketPubkey,
      borrowToken?.lendingTokenType,
      customLtv,
    ],
    () => fetchOffers(customLtv * 100),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(customLtv && collateralToken && borrowToken),
      staleTime: 30 * 1000,
    },
  )

  const isInitialLoading = allOffersQuery.isLoading && suggestedOffersQuery.isLoading
  const isDataLoading = allOffersQuery.isFetching || suggestedOffersQuery.isFetching

  const isLoading = isInitialLoading || isDataLoading

  return {
    suggestedOffers: suggestedOffersQuery.data,
    allOffers: allOffersQuery.data,
    isLoading,
  }
}

const parseLtvToPercent = (ltv: string) => parseFloat(ltv) / 100

const getLowestLtv = (offers: EnhancedBorrowOffer[]): number => {
  if (!offers.length) return MIN_SLIDER_PERCENT

  const minLtv = Math.min(...offers.map((offer) => parseLtvToPercent(offer.ltv)))

  return clamp(Math.floor(minLtv), MIN_SLIDER_PERCENT, MAX_SLIDER_PERCENT)
}
