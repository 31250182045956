import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'
import { pickBy } from 'lodash'

import { fetchExtraTokenReward } from '@banx/api/tokens'
import { useTokenType } from '@banx/store/common'

export const useMarketTokenRewards = (marketPubkey: string) => {
  const { tokenType } = useTokenType()

  const { data = {}, isLoading } = useQuery(
    ['marketTokenRewards', marketPubkey, tokenType],
    fetchExtraTokenReward,
    {
      staleTime: 1000 * 60 * 5,
    },
  )

  const isMatchingTokenType = data[marketPubkey]?.lendingTokenType === tokenType
  const marketRewards = isMatchingTokenType ? data[marketPubkey] : undefined

  const allRewardsDataByMarket = useMemo(() => {
    return pickBy(data, (value) => value.lendingTokenType === tokenType)
  }, [data, tokenType])

  return { allRewardsData: allRewardsDataByMarket, marketRewards, isLoading }
}
