import { useMemo } from 'react'

import { useWallet } from '@solana/wallet-adapter-react'
import { useQuery } from '@tanstack/react-query'
import { LendingTokenType } from 'fbonds-core/lib/fbond-protocol/types'
import { chain } from 'lodash'

import { CollateralToken, core } from '@banx/api/tokens'
import { USDC_ADDRESS, WSOL_ADDRESS } from '@banx/constants'
import { useTokenType } from '@banx/store/common'
import { bnToHuman, isBanxSolTokenType } from '@banx/utils'

export const useCollateralsList = (strictTokenType?: LendingTokenType) => {
  const { publicKey } = useWallet()
  const { tokenType: appTokenType } = useTokenType()

  const tokenType = strictTokenType || appTokenType

  const { data, isLoading } = useQuery(
    ['collateralsList', publicKey, tokenType],
    () => core.fetchCollateralsList({ walletPubkey: publicKey?.toBase58(), marketType: tokenType }),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 60 * 1000,
      staleTime: 60 * 1000,
    },
  )

  const collateralsList: CollateralToken[] = useMemo(() => {
    if (!data) return []

    const collateralMintToRemove = isBanxSolTokenType(tokenType) ? WSOL_ADDRESS : USDC_ADDRESS

    return chain(data)
      .filter((token) => token.collateral.mint !== collateralMintToRemove)
      .sortBy((token) => -calculateCollateralValueInUsd(token))
      .value()
  }, [data, tokenType])

  return { collateralsList, isLoading }
}

const calculateCollateralValueInUsd = (token: core.CollateralToken) => {
  const { collateral, amountInWallet, collateralPrice } = token
  return (bnToHuman(amountInWallet, collateral.decimals) * collateralPrice) / 100
}
