import FLPImage from './assets/FLP.png'
import MeteoraImage from './assets/Meteora.png'
import ezSOLImage from './assets/ezSOL.png'

export type TokenItem = {
  logoUrl?: string
  ticker: string
  maxApr?: string
  maxMultiply?: number
  disabled?: boolean
  extraRewardsDescription?: string
  customFooterText?: string
}

export const MOCK_TOKEN_ITEMS: TokenItem[] = [
  {
    logoUrl: FLPImage,
    ticker: 'FLP.1',
    maxMultiply: 100,
    disabled: true,
  },
  {
    logoUrl: MeteoraImage,
    ticker: 'Meteora LP',
    maxMultiply: 100,
    disabled: true,
  },
  {
    logoUrl: ezSOLImage,
    ticker: 'ezSOL',
    maxMultiply: 100,
    disabled: true,
  },
]
