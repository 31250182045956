import { FC, SVGProps } from 'react'

export const USDC: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 16C12.4334 16 16 12.4334 16 8C16 3.56664 12.4334 0 8 0C3.56664 0 0 3.56664 0 8C0 12.4334 3.56664 16 8 16Z"
      fill="#2775CA"
    />
    <path
      d="M10.1998 9.26689C10.1998 8.10025 9.49979 7.70025 8.09979 7.53361C7.09979 7.40025 6.89979 7.13362 6.89979 6.66689C6.89979 6.20017 7.23315 5.90025 7.89979 5.90025C8.49979 5.90025 8.83315 6.10025 8.99979 6.60025C9.03315 6.70025 9.13315 6.76689 9.23315 6.76689H9.76643C9.89979 6.76689 9.99979 6.66689 9.99979 6.53361V6.50025C9.86643 5.76689 9.26643 5.20025 8.49979 5.13361V4.33361C8.49979 4.20025 8.39979 4.10025 8.23315 4.06689H7.73315C7.59979 4.06689 7.49979 4.16689 7.46643 4.33361V5.10025C6.46643 5.23361 5.83315 5.90025 5.83315 6.73361C5.83315 7.83361 6.49979 8.2669 7.89979 8.43362C8.83315 8.60026 9.13315 8.80026 9.13315 9.33362C9.13315 9.86698 8.66643 10.2336 8.03315 10.2336C7.16643 10.2336 6.86643 9.86689 6.76643 9.36689C6.73315 9.23361 6.63315 9.16689 6.53315 9.16689H5.96643C5.83315 9.16689 5.73315 9.2669 5.73315 9.40026V9.43362C5.86643 10.2669 6.39979 10.8669 7.49979 11.0336V11.8336C7.49979 11.9669 7.59979 12.0669 7.76643 12.1003H8.26643C8.39979 12.1003 8.49979 12.0003 8.53315 11.8336V11.0336C9.53315 10.8669 10.1998 10.1669 10.1998 9.26689Z"
      fill="white"
    />
    <path
      d="M6.29967 12.7663C3.69967 11.833 2.36631 8.93301 3.33303 6.36629C3.83303 4.96629 4.93303 3.89965 6.29967 3.39965C6.43303 3.33301 6.49967 3.23301 6.49967 3.06629V2.59965C6.49967 2.46629 6.43303 2.36629 6.29967 2.33301C6.26631 2.33301 6.19967 2.33301 6.16631 2.36629C2.99967 3.36629 1.26631 6.73301 2.26631 9.89965C2.86631 11.7663 4.29967 13.1996 6.16631 13.7996C6.29967 13.8663 6.43303 13.7996 6.46631 13.6663C6.49967 13.633 6.49967 13.5996 6.49967 13.533V13.0663C6.49967 12.9663 6.39967 12.833 6.29967 12.7663ZM9.83303 2.36629C9.69967 2.29965 9.56631 2.36629 9.53303 2.49965C9.49967 2.53301 9.49967 2.56629 9.49967 2.63301V3.09965C9.49967 3.23301 9.59967 3.36629 9.69967 3.43301C12.2997 4.36629 13.633 7.26629 12.6663 9.83301C12.1663 11.233 11.0663 12.2996 9.69967 12.7996C9.56631 12.8663 9.49967 12.9663 9.49967 13.133V13.5996C9.49967 13.733 9.56631 13.833 9.69967 13.8663C9.73303 13.8663 9.79967 13.8663 9.83303 13.833C12.9997 12.833 14.733 9.46629 13.733 6.29965C13.133 4.39965 11.6663 2.96629 9.83303 2.36629Z"
      fill="white"
    />
  </svg>
)
