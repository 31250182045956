import { FC, SVGProps } from 'react'

export const EmptyContent: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M0 9.59961H48V36.4796C48 40.512 48 42.5282 47.3722 44.0683C46.82 45.4231 45.9388 46.5246 44.855 47.2149C43.6229 47.9996 42.0099 47.9996 38.784 47.9996H9.216C5.9901 47.9996 4.37715 47.9996 3.14501 47.2149C2.0612 46.5246 1.18003 45.4231 0.627802 44.0683C0 42.5282 0 40.512 0 36.4796V9.59961Z"
        fill="#F9F9F9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 34.2H1.44V36.48C1.44 37.3721 1.44001 38.1567 1.44669 38.8588L0.00675564 38.8725C0 38.1622 0 37.3702 0 36.48V34.2ZM0.120452 41.7406L1.55412 41.6057C1.63491 42.4641 1.76746 43.0497 1.96128 43.5252C2.09637 43.8566 2.25392 44.1658 2.42997 44.45L1.20584 45.2083C0.986176 44.8537 0.792381 44.4725 0.627802 44.0687C0.362093 43.4169 0.208841 42.6797 0.120452 41.7406ZM2.15223 46.4127L3.17268 45.3967C3.40814 45.6332 3.65837 45.8349 3.91858 46.0007C4.21435 46.189 4.58495 46.3283 5.17908 46.4191L4.9615 47.8425C4.23203 47.731 3.6558 47.5406 3.14501 47.2152C2.791 46.9898 2.4586 46.7204 2.15223 46.4127ZM36.474 48V46.56H38.784C39.5089 46.56 40.1382 46.56 40.6991 46.5514L40.7211 47.9912C40.147 48 39.5059 48 38.784 48H36.474ZM43.0385 47.8425L42.8209 46.4191C43.415 46.3283 43.7856 46.189 44.0814 46.0007C44.3416 45.8349 44.5919 45.6332 44.8273 45.3967L45.8478 46.4127C45.5414 46.7204 45.209 46.9898 44.855 47.2152C44.3442 47.5406 43.768 47.731 43.0385 47.8425ZM46.7942 45.2083L45.57 44.45C45.7461 44.1658 45.9036 43.8566 46.0387 43.5252C46.2325 43.0497 46.3651 42.4641 46.4459 41.6057L47.8795 41.7406C47.7912 42.6797 47.6379 43.4169 47.3722 44.0687C47.2076 44.4725 47.0138 44.8537 46.7942 45.2083ZM48 5.016H46.56V9.576H48V5.016ZM48 12.312H46.56V16.872H48V12.312ZM48 19.608H46.56V24.168H48V19.608ZM48 26.904H46.56V31.464H48V26.904ZM48 34.2H46.56V36.48C46.56 37.3721 46.56 38.1567 46.5533 38.8588L47.9932 38.8725C48 38.1622 48 37.3702 48 36.48V34.2ZM33.702 48V46.56H29.082V48H33.702ZM26.31 48V46.56H21.69V48H26.31ZM18.918 48V46.56H14.298V48H18.918ZM11.526 48V46.56H9.216C8.49107 46.56 7.86185 46.56 7.30095 46.5514L7.2789 47.9912C7.85304 48 8.49413 48 9.216 48H11.526ZM0 31.464H1.44V26.904H0V31.464ZM0 24.168H1.44V19.608H0V24.168ZM0 16.872H1.44V12.312H0V16.872ZM0 9.576H1.44V5.016H0V9.576Z"
        fill="#CCCCCC"
      />
      <path d="M1.44 2.28V1.44V0H0V2.28H1.44Z" fill="#CCCCCC" />
      <path d="M46.56 1.44V2.28H48V0H46.56V1.44Z" fill="#CCCCCC" />
    </g>
    <defs>
      <clipPath id="clip0_7158_1677">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
