import { FC, SVGProps } from 'react'

export const LogoFull: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="109" height="32" viewBox="0 0 109 32" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9102 27.2929C23.7225 27.2929 26.8129 24.2025 26.8129 20.3902C26.8129 16.5779 23.7225 13.4874 19.9102 13.4874C16.0979 13.4874 13.0075 16.5779 13.0075 20.3902C13.0075 24.2025 16.0979 27.2929 19.9102 27.2929ZM19.9102 30.4849C25.4854 30.4849 30.005 25.9654 30.005 20.3902C30.005 14.815 25.4854 10.2954 19.9102 10.2954C14.335 10.2954 9.81543 14.815 9.81543 20.3902C9.81543 25.9654 14.335 30.4849 19.9102 30.4849Z"
      fill="#1B1B1B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.1472 4.52332C28.9129 6.23862 30.2842 8.35786 31.1119 10.7319C28.6658 8.33886 25.3179 6.86376 21.6257 6.86376C14.7283 6.86376 9.03307 12.0112 8.17214 18.6742C8.09787 18.0995 8.05957 17.5135 8.05957 16.9186C8.05957 9.42628 14.1333 3.35254 21.6257 3.35254C23.5919 3.35254 25.4604 3.77085 27.1472 4.52332Z"
      fill="#1B1B1B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.4994 19.9866C31.8263 18.7119 32.0002 17.3759 32.0002 15.9993C32.0002 14.9036 31.89 13.8337 31.6803 12.7999C29.4334 10.258 26.2363 8.55059 22.5872 8.26174C21.9941 8.21479 21.4069 8.20674 20.8281 8.23542C26.5612 9.44232 30.8552 14.2231 31.4994 19.9866Z"
      fill="#1B1B1B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.6334 2.52677C23.5637 2.28322 22.4502 2.15461 21.3067 2.15461C13.0651 2.15461 6.38404 8.83572 6.38404 17.0773C6.38404 24.4297 11.7013 30.5402 18.7001 31.7731C17.8224 31.9223 16.9202 32 16 32C7.16346 32 0 24.8366 0 16C0 7.16344 7.16346 0 16 0C19.1797 0 22.1428 0.927525 24.6334 2.52677Z"
      fill="#1B1B1B"
    />
    <path
      d="M37.4546 23.7634V7.2002H47.0034C49.2221 7.2002 50.9031 7.57566 52.0466 8.32659C53.2071 9.06046 53.7874 10.1613 53.7874 11.629C53.7874 12.5677 53.557 13.3101 53.0962 13.8562C52.6354 14.3853 51.9783 14.7778 51.125 15.0338C50.2887 15.2898 49.2989 15.4519 48.1554 15.5202L48.2578 15.341C49.0941 15.3581 49.8791 15.4178 50.613 15.5202C51.3639 15.6226 52.021 15.8103 52.5842 16.0834C53.1474 16.3565 53.5911 16.7405 53.9154 17.2354C54.2397 17.7133 54.4018 18.3447 54.4018 19.1298C54.4018 20.1538 54.1287 21.0071 53.5826 21.6898C53.0365 22.3554 52.277 22.8589 51.3042 23.2002C50.3314 23.5415 49.205 23.7293 47.925 23.7634H37.4546ZM40.8594 20.9474H47.5666C48.6418 20.9474 49.4695 20.7767 50.0498 20.4354C50.6301 20.077 50.9202 19.5138 50.9202 18.7458C50.9202 18.1826 50.7495 17.773 50.4082 17.517C50.0669 17.261 49.6146 17.1074 49.0514 17.0562C48.4882 16.9879 47.8823 16.9538 47.2338 16.9538H40.8594V20.9474ZM40.8594 14.2146H46.8754C47.9506 14.2146 48.7783 14.061 49.3586 13.7538C49.9389 13.4295 50.229 12.8834 50.229 12.1154C50.229 11.3474 49.9389 10.8098 49.3586 10.5026C48.7783 10.1783 48.0103 10.0162 47.0546 10.0162H40.8594V14.2146Z"
      fill="#1B1B1B"
    />
    <path
      d="M63.1896 24.045C61.9267 24.045 60.7747 23.7719 59.7336 23.2258C58.7096 22.6626 57.8904 21.8861 57.276 20.8962C56.6787 19.8893 56.38 18.7287 56.38 17.4146C56.38 16.0663 56.6872 14.8973 57.3016 13.9074C57.916 12.9175 58.7437 12.1495 59.7848 11.6034C60.8429 11.0402 62.0376 10.7586 63.3688 10.7586C64.8365 10.7586 66.0141 11.0573 66.9016 11.6546C67.8061 12.2349 68.4632 13.0285 68.8728 14.0354C69.2824 15.0423 69.4872 16.1687 69.4872 17.4146C69.4872 18.1655 69.3677 18.9335 69.1288 19.7186C68.8899 20.4866 68.5229 21.2034 68.028 21.869C67.5331 22.5175 66.8845 23.0466 66.0824 23.4562C65.2803 23.8487 64.316 24.045 63.1896 24.045ZM64.2648 21.485C65.2717 21.485 66.1421 21.3143 66.876 20.973C67.6099 20.6317 68.1731 20.1538 68.5656 19.5394C68.9581 18.925 69.1544 18.2167 69.1544 17.4146C69.1544 16.5442 68.9496 15.8103 68.54 15.213C68.1475 14.5986 67.5843 14.1378 66.8504 13.8306C66.1336 13.5063 65.2717 13.3442 64.2648 13.3442C62.8483 13.3442 61.7475 13.7197 60.9624 14.4706C60.1773 15.2045 59.7848 16.1858 59.7848 17.4146C59.7848 18.2338 59.9725 18.9506 60.348 19.565C60.7235 20.1623 61.244 20.6317 61.9096 20.973C62.5923 21.3143 63.3773 21.485 64.2648 21.485ZM69.1544 11.0402H72.4824V23.7634H69.3848C69.3848 23.7634 69.3592 23.6013 69.308 23.277C69.2739 22.9357 69.2397 22.509 69.2056 21.997C69.1715 21.485 69.1544 20.9815 69.1544 20.4866V11.0402Z"
      fill="#1B1B1B"
    />
    <path
      d="M75.4944 11.0402H78.8224V23.7634H75.4944V11.0402ZM84.736 10.7586C85.5211 10.7586 86.2464 10.861 86.912 11.0658C87.5776 11.2706 88.1579 11.5863 88.6528 12.013C89.1477 12.4397 89.5317 12.9858 89.8048 13.6514C90.0779 14.2999 90.2144 15.0765 90.2144 15.981V23.7634H86.8864V16.5698C86.8864 15.4946 86.6219 14.7095 86.0928 14.2146C85.5808 13.7026 84.736 13.4466 83.5584 13.4466C82.6709 13.4466 81.8688 13.6173 81.152 13.9586C80.4352 14.2999 79.8549 14.7351 79.4112 15.2642C78.9675 15.7762 78.7115 16.3138 78.6432 16.877L78.6176 15.5714C78.7029 14.9741 78.8992 14.3938 79.2064 13.8306C79.5136 13.2674 79.9232 12.7554 80.4352 12.2946C80.9643 11.8167 81.5872 11.4413 82.304 11.1682C83.0208 10.8951 83.8315 10.7586 84.736 10.7586Z"
      fill="#1B1B1B"
    />
    <path
      d="M107.657 11.0402L101.052 18.1314L96.2908 23.7634H92.2204L99.1068 16.3138L103.612 11.0402H107.657ZM92.2204 11.0402H96.2908L108.016 23.7634H103.945L99.0044 18.2082L92.2204 11.0402Z"
      fill="#1B1B1B"
    />
  </svg>
)
