import { useCallback, useEffect, useMemo } from 'react'

import { useWallet } from '@solana/wallet-adapter-react'
import { useNavigate } from 'react-router-dom'

import { MARKET_OPTIONS_WITHOUT_ALL, TokenDropdown } from '@banx/components/Dropdowns'
import EmptyList from '@banx/components/EmptyList'
import { Search } from '@banx/components/Search'
import Table from '@banx/components/Table'

import { core } from '@banx/api/nft'
import { MESSAGES } from '@banx/constants/messages'
import { PATHS } from '@banx/router'
import { buildUrlWithModeAndToken } from '@banx/store'
import { AssetMode, ViewState, useTableView, useTokenType } from '@banx/store/common'

import { Summary } from './Summary'
import { getTableColumns } from './columns'
import { useRequestsLoansTable } from './hooks'
import { useSelectedLoans } from './loansState'

import styles from './RequestLoansTable.module.less'

export const RequestsTable = () => {
  const { tokenType, setTokenType } = useTokenType()
  const { viewState } = useTableView()

  const { publicKey, connected } = useWallet()
  const walletPubkey = publicKey?.toBase58() || ''

  const {
    loans,
    loading,
    isNoLoans,
    filteredListEmptyMessage,
    searchQuery,
    setSearchQuery,
    sortViewParams,
  } = useRequestsLoansTable()

  const {
    selection,
    toggle: toggleLoanInSelection,
    find,
    clear: clearSelection,
    set: setSelection,
  } = useSelectedLoans()

  //? Clear selection when tokenType changes
  //? To prevent selection transfering from one tokenType to another
  useEffect(() => {
    clearSelection()
  }, [clearSelection, tokenType])

  const walletSelectedLoans = useMemo(() => {
    if (!walletPubkey) return []
    return selection.filter(({ wallet }) => wallet === walletPubkey)
  }, [selection, walletPubkey])

  const hasSelectedLoans = !!walletSelectedLoans?.length

  const onSelectAll = useCallback(() => {
    if (hasSelectedLoans) {
      clearSelection()
    } else {
      setSelection(loans, walletPubkey)
    }
  }, [clearSelection, hasSelectedLoans, loans, setSelection, walletPubkey])

  const findLoanInSelection = useCallback(
    (loanPubkey: string) => find(loanPubkey, walletPubkey),
    [find, walletPubkey],
  )

  const onRowClick = useCallback(
    (loan: core.Loan) => toggleLoanInSelection(loan, walletPubkey),
    [toggleLoanInSelection, walletPubkey],
  )

  const columns = getTableColumns({
    onSelectAll,
    findLoanInSelection,
    toggleLoanInSelection: onRowClick,
    hasSelectedLoans,
    isCardView: viewState === ViewState.CARD,
  })

  const rowParams = useMemo(() => {
    return { onRowClick }
  }, [onRowClick])

  const customJSX = (
    <>
      <TokenDropdown
        option={tokenType}
        options={MARKET_OPTIONS_WITHOUT_ALL}
        onChange={setTokenType}
      />
      <Search value={searchQuery} onChange={setSearchQuery} />
    </>
  )

  if (!connected) return <EmptyList message={MESSAGES.NO_CONNECTED_LOANS} />

  return (
    <div className={styles.tableRoot}>
      <Table
        data={loans}
        columns={columns}
        rowParams={rowParams}
        sortViewParams={sortViewParams}
        className={styles.table}
        loading={loading}
        customJSX={customJSX}
        emptyMessage={isNoLoans ? <NoListings /> : <EmptyList message={filteredListEmptyMessage} />}
        showCard
      />
      {!isNoLoans && !filteredListEmptyMessage && !loading && (
        <Summary loans={loans} selectedLoans={walletSelectedLoans} setSelection={setSelection} />
      )}
    </div>
  )
}

const NoListings = () => {
  const navigate = useNavigate()

  const goToBorrowPage = () => {
    navigate(buildUrlWithModeAndToken(PATHS.BORROW, AssetMode.NFT, null))
  }

  return (
    <EmptyList
      message={MESSAGES.BORROWER_NO_LISTINGS}
      buttonProps={{ text: 'List', onClick: goToBorrowPage }}
    />
  )
}
