import { BreadcrumbHeader } from '@banx/components/BreadcrumbHeader'
import { Tab, Tabs, useTabs } from '@banx/components/Tabs'

import { PATHS } from '@banx/router'

import { ActiveTabContent } from './components/ActiveTabContent'
import { HistoryOffersTable } from './components/HistoryOffersTable'
import OffersTabContent from './components/OffersTabContent'

import styles from './OffersPage.module.less'

export const OffersPage = () => {
  const { value: currentTabValue, ...tabsProps } = useTabs({
    tabs: OFFERS_TABS,
    defaultValue: OffersTabName.OFFERS,
  })

  return (
    <div className={styles.pageWrapper}>
      <BreadcrumbHeader
        breadcrumbs={[{ title: 'Portfolio', path: PATHS.PORTFOLIO }, { title: 'My offers' }]}
        onboardContentType="offers"
      />
      <Tabs value={currentTabValue} {...tabsProps} type="secondary" />
      {currentTabValue === OffersTabName.OFFERS && <OffersTabContent />}
      {currentTabValue === OffersTabName.LOANS && <ActiveTabContent />}
      {currentTabValue === OffersTabName.HISTORY && <HistoryOffersTable />}
    </div>
  )
}

enum OffersTabName {
  OFFERS = 'offers',
  LOANS = 'loans',
  HISTORY = 'history',
}

const OFFERS_TABS: Tab[] = [
  {
    label: 'Offers',
    value: OffersTabName.OFFERS,
  },
  {
    label: 'Loans',
    value: OffersTabName.LOANS,
  },
  {
    label: 'History',
    value: OffersTabName.HISTORY,
  },
]
