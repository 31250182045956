import { calcBorrowerTokenAPR } from 'fbonds-core/lib/fbond-protocol/helpers'

import { ColumnType } from '@banx/components/Table'
import { HeaderCell, createPercentValueJSX } from '@banx/components/TableComponents'

import { CollateralToken } from '@banx/api/tokens'
import { bnToNumberSafe, insertAtArray } from '@banx/utils'

import { LeverageSimpleOffer } from '../../types'

import styles from './OrderBook.module.less'

type GetTableColumns = (props: {
  collateral: CollateralToken | undefined
}) => ColumnType<LeverageSimpleOffer>[]

export const getTableColumns: GetTableColumns = ({ collateral }) => {
  const isOracleMarket = collateral?.collateral.oraclePriceFeedType !== 'none'

  const columns: ColumnType<LeverageSimpleOffer>[] = [
    {
      key: 'max leverage',
      title: <HeaderCell label="Max leverage" align="left" />,
      render: (offer) => {
        const collateralDecimals = collateral?.collateral.decimals || 0
        return (
          <span>
            {(bnToNumberSafe(offer.maxCollateralToReceive) / 10 ** collateralDecimals).toFixed(2)}
          </span>
        )
      },
    },
    {
      key: 'mutiplier',
      title: <HeaderCell label="Max multiply" className={styles.mutiplierHeaderCell} />,
      render: (offer) => {
        return <span className={styles.mutiplierCell}>x{offer.maxMultiplier}</span>
      },
    },
    {
      key: 'apr',
      title: <HeaderCell label="APR" />,
      render: (offer) => {
        const marketInterestFee = collateral?.collateral.interestFee || 0
        const aprPercent = calcBorrowerTokenAPR(offer.apr.toNumber(), marketInterestFee) / 100
        return <span>{aprPercent.toFixed(1)}%</span>
      },
    },

    //? Additional info fields for debug
    // {
    //   key: 'maxLtv',
    //   title: <HeaderCell label="Max ltv" />,
    //   render: (offer) => {
    //     const ltv = tokenOfferUtils.calcOfferLtv({
    //       collateralsPerToken: offer.collateralsPerToken,
    //       tokenPrice: new BN(tokenPriceInCollateral * 1e9),
    //     })
    //     return <span>{createPercentValueJSX(Math.trunc(ltv.toNumber() / 100))}</span>
    //   },
    // },
    // {
    //   key: 'offerSize',
    //   title: <HeaderCell label="Offer size" />,
    //   render: (offer) => <span>{(offer.maxTokenToGet.toNumber() / 1e6).toFixed(2)}</span>,
    // },
  ]

  if (isOracleMarket) {
    const column = {
      key: 'liqLtv',
      title: <HeaderCell label="Liq. LTV" />,
      render: (loan: LeverageSimpleOffer) =>
        createPercentValueJSX(bnToNumberSafe(loan.offerLtvBp) / 100),
    }

    return insertAtArray(columns, 3, column)
  }

  return columns
}
