import { FC, SVGProps } from 'react'

export const ChevronDown: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M11.9988 15.8474C12.2625 15.8398 12.5036 15.7419 12.6995 15.5309L18.4249 9.6699C18.5906 9.50416 18.681 9.29322 18.681 9.04462C18.681 8.54741 18.2893 8.14813 17.7921 8.14813C17.551 8.14813 17.3175 8.24607 17.1442 8.41934L12.0064 13.7003L6.85348 8.41934C6.68021 8.2536 6.45421 8.14813 6.2056 8.14813C5.70839 8.14813 5.31665 8.54741 5.31665 9.04462C5.31665 9.29322 5.40705 9.50416 5.57279 9.6699L11.3058 15.5309C11.5092 15.7419 11.7352 15.8474 11.9988 15.8474Z"
      fill="black"
    />
  </svg>
)
