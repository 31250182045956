import { useQuery } from '@tanstack/react-query'

import { core } from '@banx/api/nft'
import { USE_MARKETS_PREVIEW_QUERY_KEY } from '@banx/providers'
import { useTokenType } from '@banx/store/common'

export const useMarketsPreview = () => {
  const { tokenType } = useTokenType()

  const { data, isLoading } = useQuery(
    [USE_MARKETS_PREVIEW_QUERY_KEY, tokenType],
    () => core.fetchMarketsPreview({ tokenType }),
    {
      staleTime: 5000,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
    },
  )

  return {
    marketsPreview: data || [],
    isLoading,
  }
}
