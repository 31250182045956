import borrowImg from './assets/borrow.png'
import borrowImg_dark from './assets/borrow_dark.png'
import loansImg from './assets/loans.png'
import loansMarketImg from './assets/loansMarket.png'
import loansMarketImg_dark from './assets/loansMarket_dark.png'
import loansImg_dark from './assets/loans_dark.png'
import multiplyImg from './assets/multiply.png'
import multiplyHubImg from './assets/multiplyHub.png'
import multiplyHubImg_dark from './assets/multiplyHub_dark.png'
import multiplyJlpImg from './assets/multiplyJlp.png'
import multiplyJlpImg_dark from './assets/multiplyJlp_dark.png'
import multiplyLrtsImg from './assets/multiplyLrts.png'
import multiplyLrtsImg_dark from './assets/multiplyLrts_dark.png'
import multiplyImg_dark from './assets/multiply_dark.png'
import offersImg from './assets/offers.png'
import offersImg_dark from './assets/offers_dark.png'
import placeOfferImg from './assets/placeOffer.png'
import placeOfferImg_dark from './assets/placeOffer_dark.png'
import vaultsImg from './assets/vaults.png'
import vaultsImg_dark from './assets/vaults_dark.png'
import { OnboardingModalContentType } from './types'

import styles from './OnboardingModal.module.less'

const MULTIPLY_CONTENT = {
  title: 'Multiply',
  slides: [
    {
      img: <img className={styles.slideImg} src={multiplyImg} alt="multiply" />,
      imgDark: <img className={styles.slideImg} src={multiplyImg_dark} alt="multiply" />,
      text: null,
    },
  ],
}

const MULTIPLY_LRTS_CONTENT = {
  title: 'Multiply: lrtssol',
  slides: [
    {
      img: <img className={styles.slideImg} src={multiplyLrtsImg} alt="multiply lrtssol" />,
      imgDark: (
        <img className={styles.slideImg} src={multiplyLrtsImg_dark} alt="multiply lrtssol" />
      ),
      text: null,
    },
  ],
}

const MULTIPLY_JLP_CONTENT = {
  title: 'Multiply: JLP',
  slides: [
    {
      img: <img className={styles.slideImg} src={multiplyJlpImg} alt="multiply jlp" />,
      imgDark: <img className={styles.slideImg} src={multiplyJlpImg_dark} alt="multiply jlp" />,
      text: null,
    },
  ],
}

const MULTIPLY_HUBSOL_CONTENT = {
  title: 'Multiply: Hubsol',
  slides: [
    {
      img: <img className={styles.slideImg} src={multiplyHubImg} alt="multiply hubsol" />,
      imgDark: <img className={styles.slideImg} src={multiplyHubImg_dark} alt="multiply hubsol" />,
      text: null,
    },
  ],
}

const BORROW_CONTENT = {
  title: 'Borrow',
  slides: [
    {
      img: <img className={styles.slideImg} src={borrowImg} alt="borrow" />,
      imgDark: <img className={styles.slideImg} src={borrowImg_dark} alt="borrow" />,
      text: null,
    },
  ],
}

const VAULTS_CONTENT = {
  title: 'Vaults',
  slides: [
    {
      img: <img className={styles.slideImg} src={vaultsImg} alt="vaults" />,
      imgDark: <img className={styles.slideImg} src={vaultsImg_dark} alt="vaults" />,
      text: null,
    },
  ],
}

const PLACE_OFFER = {
  title: 'Place offer',
  slides: [
    {
      img: <img className={styles.slideImg} src={placeOfferImg} alt="place offer" />,
      imgDark: <img className={styles.slideImg} src={placeOfferImg_dark} alt="place offer" />,
      text: null,
    },
  ],
}

const LOANS_MARKET = {
  title: 'Loans market',
  slides: [
    {
      img: <img className={styles.slideImg} src={loansMarketImg} alt="loans market" />,
      imgDark: <img className={styles.slideImg} src={loansMarketImg_dark} alt="loans market" />,
      text: null,
    },
  ],
}

const OFFERS_CONTENT = {
  title: 'My offers',
  slides: [
    {
      img: <img className={styles.slideImg} src={offersImg} alt="offers" />,
      imgDark: <img className={styles.slideImg} src={offersImg_dark} alt="offers" />,
      text: null,
    },
  ],
}

const LOANS_CONTENT = {
  title: 'My loans',
  slides: [
    {
      img: <img className={styles.slideImg} src={loansImg} alt="loans" />,
      imgDark: <img className={styles.slideImg} src={loansImg_dark} alt="loans" />,
      text: null,
    },
  ],
}

export const CONTENT = {
  [OnboardingModalContentType.BORROW]: BORROW_CONTENT,
  [OnboardingModalContentType.MULTIPLY]: MULTIPLY_CONTENT,
  [OnboardingModalContentType.MULTIPLY_LRTS]: MULTIPLY_LRTS_CONTENT,
  [OnboardingModalContentType.MULTIPLY_JLP]: MULTIPLY_JLP_CONTENT,
  [OnboardingModalContentType.MULTIPLY_HUBSOL]: MULTIPLY_HUBSOL_CONTENT,
  [OnboardingModalContentType.VAULTS]: VAULTS_CONTENT,
  [OnboardingModalContentType.PLACE_OFFER]: PLACE_OFFER,
  [OnboardingModalContentType.LOANS_MARKET]: LOANS_MARKET,
  [OnboardingModalContentType.OFFERS]: OFFERS_CONTENT,
  [OnboardingModalContentType.LOANS]: LOANS_CONTENT,
}
