import { useCallback, useMemo, useState } from 'react'

import { filter, sumBy } from 'lodash'

import { createGlobalState } from '@banx/store'

import { LoansPreview } from '../types'

const useCollateralTickerStore = createGlobalState<string[]>([])

export const useFilterLenderTokenLoansPreviews = (loansPreviews: LoansPreview[]) => {
  const [isTerminationFilterEnabled, setTerminationFilterState] = useState(false)
  const [isLiquidatedFilterEnabled, setIsLiquidatedFilterState] = useState(false)
  const [isUnderwaterFilterEnabled, setIsUnderwaterFilterState] = useState(false)

  const [selectedCollateralTicker, setSelectedCollateralTicker] = useCollateralTickerStore()

  const toggleTerminationFilter = () => {
    setIsLiquidatedFilterState(false)
    setIsUnderwaterFilterState(false)
    setTerminationFilterState(!isTerminationFilterEnabled)
  }

  const toggleLiquidatedFilter = () => {
    setTerminationFilterState(false)
    setIsUnderwaterFilterState(false)
    setIsLiquidatedFilterState(!isLiquidatedFilterEnabled)
  }

  const toggleUnderwaterFilter = () => {
    setTerminationFilterState(false)
    setIsLiquidatedFilterState(false)
    setIsUnderwaterFilterState(!isUnderwaterFilterEnabled)
  }

  const applyActiveFilters = useCallback(
    (previews: LoansPreview[]) => {
      if (isTerminationFilterEnabled) {
        return previews.filter((preview) => preview.terminatingLoansAmount > 0)
      }

      if (isUnderwaterFilterEnabled) {
        return previews.filter((preview) => preview.underwaterLoansAmount > 0)
      }

      if (isLiquidatedFilterEnabled) {
        return previews.filter((preview) => preview.liquidatedLoansAmount > 0)
      }

      return previews
    },
    [isTerminationFilterEnabled, isUnderwaterFilterEnabled, isLiquidatedFilterEnabled],
  )

  const filteredLoansByCollateralTicker = useMemo(() => {
    if (!selectedCollateralTicker.length) return loansPreviews

    return filter(loansPreviews, (preview) =>
      selectedCollateralTicker.includes(preview.collareralTicker),
    )
  }, [loansPreviews, selectedCollateralTicker])

  const filteredLoansBySelectedCollateral = useMemo(
    () => applyActiveFilters(filteredLoansByCollateralTicker),
    [filteredLoansByCollateralTicker, applyActiveFilters],
  )

  const filteredLoansPreviews = useMemo(
    () => applyActiveFilters(loansPreviews),
    [loansPreviews, applyActiveFilters],
  )

  const terminatingLoansAmount = useMemo(
    () => sumBy(filteredLoansByCollateralTicker, (preview) => preview.terminatingLoansAmount || 0),
    [filteredLoansByCollateralTicker],
  )

  const liquidatedLoansAmount = useMemo(
    () => sumBy(filteredLoansByCollateralTicker, (preview) => preview.liquidatedLoansAmount || 0),
    [filteredLoansByCollateralTicker],
  )

  const underwaterLoansAmount = useMemo(
    () => sumBy(filteredLoansByCollateralTicker, (preview) => preview.underwaterLoansAmount || 0),
    [filteredLoansByCollateralTicker],
  )

  return {
    filteredLoansPreviewsBySelectedCollateral: filteredLoansBySelectedCollateral,
    filteredLoansPreviews,

    terminatingLoansAmount,
    isTerminationFilterEnabled,
    toggleTerminationFilter,

    liquidatedLoansAmount,
    isLiquidatedFilterEnabled,
    toggleLiquidatedFilter,

    underwaterLoansAmount,
    isUnderwaterFilterEnabled,
    toggleUnderwaterFilter,

    selectedCollateralTicker,
    setSelectedCollateralTicker,
  }
}
