import { useMemo, useState } from 'react'

import { map } from 'lodash'

import { buildLoansPreviewGroupedByMint } from '../helpers'
import { LoansPreview } from '../types'
import { useFilterLenderTokenLoansPreviews } from './useFilterLenderTokenLoansPreviews'
import { useLenderTokenLoans } from './useLenderTokenLoans'
import { useSortLenderTokenLoansPreviews } from './useSortLenderTokenLoansPreviews'

export const useLenderTokenLoansContent = () => {
  const { loans, isLoading } = useLenderTokenLoans()

  const loansPreviews = useMemo(() => buildLoansPreviewGroupedByMint(loans), [loans])

  const [expandedCollateralMint, setExpandedCollateralMint] = useState('')

  const handleCardToggle = (mint: string) => {
    setExpandedCollateralMint((prevMint) => (prevMint === mint ? '' : mint))
  }

  const {
    filteredLoansPreviews,
    filteredLoansPreviewsBySelectedCollateral,
    terminatingLoansAmount,
    isTerminationFilterEnabled,
    toggleTerminationFilter,
    liquidatedLoansAmount,
    isLiquidatedFilterEnabled,
    toggleLiquidatedFilter,
    underwaterLoansAmount,
    isUnderwaterFilterEnabled,
    toggleUnderwaterFilter,
    selectedCollateralTicker,
    setSelectedCollateralTicker,
  } = useFilterLenderTokenLoansPreviews(loansPreviews)

  const { sortedLoansPreviews, sortParams } = useSortLenderTokenLoansPreviews(
    filteredLoansPreviewsBySelectedCollateral,
  )

  const searchSelectParams = createSearchSelectParams({
    options: filteredLoansPreviews,
    selectedOptions: selectedCollateralTicker,
    onChange: setSelectedCollateralTicker,
  })

  return {
    loansPreviews: sortedLoansPreviews,
    isLoading,

    terminatingLoansAmount,
    isTerminationFilterEnabled,
    toggleTerminationFilter,

    liquidatedLoansAmount,
    isLiquidatedFilterEnabled,
    toggleLiquidatedFilter,

    underwaterLoansAmount,
    isUnderwaterFilterEnabled,
    toggleUnderwaterFilter,

    expandedCollateralMint,
    handleCardToggle,

    searchSelectParams,
    sortParams,
  }
}

interface CreateSearchSelectProps {
  options: LoansPreview[]
  selectedOptions: string[]
  onChange: (option: string[]) => void
}

const createSearchSelectParams = ({
  options,
  selectedOptions,
  onChange,
}: CreateSearchSelectProps) => {
  const searchSelectOptions = map(options, (option) => {
    const { collareralTicker = '', collateralLogoUrl = '' } = option || {}
    const loansAmount = option.loans.length

    return { collareralTicker, collateralLogoUrl, loansAmount }
  })

  const searchSelectParams = {
    options: searchSelectOptions,
    selectedOptions,
    onChange,
    labels: ['Collateral', 'Loans'],
    optionKeys: {
      labelKey: 'collareralTicker',
      valueKey: 'collareralTicker',
      imageKey: 'collateralLogoUrl',
      secondLabel: {
        key: 'loansAmount',
      },
    },
  }

  return searchSelectParams
}
