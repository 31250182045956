import { useCallback, useEffect, useMemo, useState } from 'react'

import { clamp, trimStart } from 'lodash'

import { useTokenType } from '@banx/store/common'
import { SyntheticOffer } from '@banx/store/nft'
import { getTokenDecimals } from '@banx/utils'

export const useOfferFormController = (syntheticOffer: SyntheticOffer) => {
  const { tokenType } = useTokenType()

  const { loanValue: syntheticLoanValue, loansAmount: syntheticLoansAmount } = syntheticOffer

  const decimals = getTokenDecimals(tokenType)

  const initialValues = useMemo(() => {
    return {
      loanValue: formatNumber(syntheticLoanValue / 10 ** decimals),
      loansAmount: syntheticOffer.isEdit ? String(syntheticLoansAmount) : '1',
    }
  }, [decimals, syntheticLoanValue, syntheticLoansAmount, syntheticOffer.isEdit])

  const [loanValue, setLoanValue] = useState(initialValues.loanValue)
  const [loansAmount, setLoansAmount] = useState(initialValues.loansAmount)

  useEffect(() => {
    const { loanValue, loansAmount } = initialValues

    setLoanValue(loanValue)
    setLoansAmount(loansAmount)
  }, [initialValues])

  const onLoanValueChange = useCallback((nextValue: string) => {
    setLoanValue(nextValue)
  }, [])

  const onLoanAmountChange = useCallback((nextValue: string) => {
    const sanitizedValue = trimStart(nextValue, '0')
    const numericValue = parseFloat(sanitizedValue) || 0
    const clampedValue = clamp(numericValue, 0, 10000)

    setLoansAmount(clampedValue.toString())
  }, [])

  const resetFormValues = () => {
    setLoanValue(initialValues.loanValue)
    setLoansAmount(initialValues.loansAmount)
  }

  const hasFormChanges = useMemo(() => {
    return loansAmount !== initialValues.loansAmount || loanValue !== initialValues.loanValue
  }, [initialValues, loansAmount, loanValue])

  return {
    loanValue,
    loansAmount,

    onLoanValueChange,
    onLoanAmountChange,

    hasFormChanges: Boolean(hasFormChanges),
    resetFormValues,
  }
}

const formatNumber = (value: number, defaultValue = '0') => {
  if (!value) return defaultValue

  const formattedValue = value.toFixed(2)
  //? Remove trailing zeros
  return formattedValue.replace(/\.?0+$/, '')
}
