import { FC, SVGProps } from 'react'

export const Burger: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.33301 8C4.33301 7.44772 4.78072 7 5.33301 7H26.6663C27.2186 7 27.6663 7.44772 27.6663 8C27.6663 8.55228 27.2186 9 26.6663 9H5.33301C4.78072 9 4.33301 8.55228 4.33301 8ZM4.33301 16C4.33301 15.4477 4.78072 15 5.33301 15H26.6663C27.2186 15 27.6663 15.4477 27.6663 16C27.6663 16.5523 27.2186 17 26.6663 17H5.33301C4.78072 17 4.33301 16.5523 4.33301 16ZM4.33301 24C4.33301 23.4477 4.78072 23 5.33301 23H26.6663C27.2186 23 27.6663 23.4477 27.6663 24C27.6663 24.5523 27.2186 25 26.6663 25H5.33301C4.78072 25 4.33301 24.5523 4.33301 24Z"
      fill="black"
    />
  </svg>
)

export const BurgerClose: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
    <g clipPath="url(#clip0_235_15533)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29289 7.29289C7.68342 6.90237 8.31658 6.90237 8.70711 7.29289L16 14.5858L23.2929 7.29289C23.6834 6.90237 24.3166 6.90237 24.7071 7.29289C25.0976 7.68342 25.0976 8.31658 24.7071 8.70711L17.4142 16L24.7071 23.2929C25.0976 23.6834 25.0976 24.3166 24.7071 24.7071C24.3166 25.0976 23.6834 25.0976 23.2929 24.7071L16 17.4142L8.70711 24.7071C8.31658 25.0976 7.68342 25.0976 7.29289 24.7071C6.90237 24.3166 6.90237 23.6834 7.29289 23.2929L14.5858 16L7.29289 8.70711C6.90237 8.31658 6.90237 7.68342 7.29289 7.29289Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_235_15533">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
