import { FC, useMemo } from 'react'

import { useConnection } from '@solana/wallet-adapter-react'
import classNames from 'classnames'
import { BN } from 'fbonds-core'
import { BASE_POINTS } from 'fbonds-core/lib/fbond-protocol/constants'
import { calcBorrowerTokenAPR } from 'fbonds-core/lib/fbond-protocol/helpers'
import { chain, isEmpty } from 'lodash'
import { Navigate, useNavigate } from 'react-router-dom'

import { OnboardingCarousel } from '@banx/components/OnboardingCarousel'
import Tooltip from '@banx/components/Tooltip'

import { useTokenBondOffers } from '@banx/hooks'
import { Multiply, StarSecondary } from '@banx/icons'
import { PATHS } from '@banx/router'
import { buildUrlWithModeAndToken } from '@banx/store'
import { AssetMode, useAssetMode, useSlippage, useTokenType } from '@banx/store/common'
import { getTokenDecimals, roundByMaxDigit } from '@banx/utils'

import {
  HUBSOL_PAIR,
  JLP_PAIR,
  LRTS_PAIR,
  MultiplyPair,
  calculateNetApr,
  createLeverageSimpleOffers,
} from '../LeveragePage'
import {
  useCollateralConversionRate,
  useCollateralYield,
  useMultiplyPair,
} from '../LeveragePage/hooks'
import { MOCK_TOKEN_ITEMS, TokenItem } from './constants'

import styles from './LeverageLanding.module.less'

export const LeverageLanding = () => {
  const navigate = useNavigate()
  const { tokenType } = useTokenType()
  const { currentAssetMode } = useAssetMode()

  if (currentAssetMode === AssetMode.NFT) {
    return (
      <Navigate
        to={buildUrlWithModeAndToken(PATHS.BORROW, AssetMode.NFT, tokenType)}
        replace={true}
      />
    )
  }

  const handleGoToLeveragePage = (ticker: string) => {
    const pathname = `${PATHS.LEVERAGE_BASE}/${ticker}`
    navigate(buildUrlWithModeAndToken(pathname, AssetMode.Token, tokenType))
  }

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.content}>
        <div className={styles.mainSection}>
          <div className={styles.mainSectionTitle}>
            <h1>Multiply</h1>
            <p>Boost your yield or leverage any token you want</p>
          </div>
          <OnboardingCarousel contentType="multiply" />
        </div>

        <div className={styles.tokensList}>
          <TokenItemLink
            key={HUBSOL_PAIR.collateralMint.toBase58()}
            pair={HUBSOL_PAIR}
            onClick={() => handleGoToLeveragePage(HUBSOL_PAIR.collateralTicker)}
          />
          <TokenItemLink
            key={LRTS_PAIR.collateralMint.toBase58()}
            pair={LRTS_PAIR}
            onClick={() => handleGoToLeveragePage(LRTS_PAIR.collateralTicker)}
            extraRewardsDescription="This market earns additional rewards: Adrastea restaking"
          />
          <TokenItemLink
            key={JLP_PAIR.collateralMint.toBase58()}
            pair={JLP_PAIR}
            onClick={() => handleGoToLeveragePage(JLP_PAIR.collateralTicker)}
          />
          <TokenItemLinkView
            ticker="Any token"
            customFooterText="Go long on anything"
            onClick={() => handleGoToLeveragePage('any')}
          />

          {MOCK_TOKEN_ITEMS.map((tokenItem, idx) => (
            <TokenItemLinkView
              key={idx}
              {...tokenItem}
              onClick={() => handleGoToLeveragePage(tokenItem.ticker)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

type TokenItemLinkProps = {
  pair: MultiplyPair
  onClick: () => void
  extraRewardsDescription?: string
}

//? Component
const TokenItemLink: FC<TokenItemLinkProps> = ({ pair, extraRewardsDescription, onClick }) => {
  const { collateralLogoUrl, collateralTicker } = pair
  const { maxMultiplier, maxNetApr } = useMultiplyMarketInfo(pair)

  const isMaxAprNeedToBeRounded = (maxNetApr || 0) > 100
  const formattedMaxApr =
    isMaxAprNeedToBeRounded && maxNetApr
      ? roundByMaxDigit(maxNetApr).toString()
      : maxNetApr?.toFixed()

  return (
    <TokenItemLinkView
      logoUrl={collateralLogoUrl}
      ticker={collateralTicker}
      maxApr={formattedMaxApr}
      extraRewardsDescription={extraRewardsDescription}
      maxMultiply={maxMultiplier}
      onClick={onClick}
    />
  )
}

//? Static layout
const TokenItemLinkView: FC<TokenItem & { onClick: () => void }> = ({
  ticker,
  logoUrl,
  maxApr,
  maxMultiply,
  extraRewardsDescription,
  disabled,
  customFooterText,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={classNames(styles.tokenItem, { [styles.tokenItemDisabled]: disabled })}
    >
      <div className={styles.tokenItemHeader}>
        {logoUrl && <img src={logoUrl} alt={ticker} />}
        <p className={styles.tokenItemTicker}>{ticker}</p>

        {!!maxApr && (
          <div className={styles.tokenItemApyWrapper}>
            <p className={styles.tokenItemApyTitle}>
              {extraRewardsDescription && (
                <Tooltip title={extraRewardsDescription}>
                  <StarSecondary />
                </Tooltip>
              )}
              Max apr
            </p>
            <p className={styles.tokenItemApyValue}>{maxApr}%</p>
          </div>
        )}
      </div>
      <div className={styles.tokenItemFooter}>
        {!!customFooterText && (
          <p className={styles.tokenItemMultiplyPlaceholder}>Go long on anything</p>
        )}

        {maxMultiply && !customFooterText && (
          <p className={styles.tokenItemMultiply}>
            <Multiply /> up to {maxMultiply}x multiply
          </p>
        )}
      </div>
    </div>
  )
}

const useMultiplyMarketInfo = (pair: MultiplyPair) => {
  const { collateralToken, isLoading: collateralTokenLoading } = useMultiplyPair(
    pair.collateralMint.toBase58(),
  )
  const { slippageBps } = useSlippage()

  const { connection } = useConnection()
  const { offers, isLoading: offersLoading } = useTokenBondOffers({
    marketPubkey: pair.marketPublicKey,
    lendingTokenType: pair.marketTokenType,
  })

  const { collateralYield, isLoading: collateralYieldLoading } = useCollateralYield(pair)

  const { rate: collateralConversionRate, isLoading: collateralConversionRateLoading } =
    useCollateralConversionRate({
      pair,
      collateralTokenMeta: collateralToken?.collateral,
      slippageBps,
      connection,
    })

  const leverageSimpleOffers = useMemo(() => {
    if (isEmpty(offers) || collateralConversionRate === 0 || !collateralToken) return []

    return createLeverageSimpleOffers({
      offers,
      lendingTokenType: pair.marketTokenType,
      collateralDecimals: collateralToken.collateral.decimals,
      collateralConversionRate,
    })
  }, [collateralConversionRate, collateralToken, offers, pair.marketTokenType])

  const maxMultiplier = useMemo(() => {
    if (isEmpty(leverageSimpleOffers)) return undefined

    return chain(leverageSimpleOffers)
      .map(({ maxMultiplier }) => maxMultiplier)
      .max()
      .value()
  }, [leverageSimpleOffers])

  const maxNetApr = useMemo(() => {
    if (
      isEmpty(leverageSimpleOffers) ||
      !collateralYield ||
      !collateralConversionRate ||
      !collateralToken
    )
      return undefined

    const amountToCalcMaxNetApr = pair.amountToCalcMaxNetApr ?? new BN(1)

    const maxNetAprInOffers = chain(leverageSimpleOffers)
      .filter(({ maxCollateralToReceive }) => maxCollateralToReceive.gte(amountToCalcMaxNetApr))
      .map(({ apr, maxMultiplier }) => {
        const marketUpfrontFee = collateralToken?.collateral.interestFee || 0
        const aprRate = calcBorrowerTokenAPR(apr.toNumber(), marketUpfrontFee)
        return calculateNetApr({
          totalCollateralAmount: new BN(maxMultiplier).mul(amountToCalcMaxNetApr),
          userEnteredCollateralAmount: amountToCalcMaxNetApr,
          conversionRate: collateralConversionRate,
          aprRate,
          collateralYield,
          collateralDecimals: collateralToken.collateral.decimals || 0,
          tokenDecimals: Math.log10(getTokenDecimals(pair.marketTokenType)),
        })
      })
      .max()
      .value()

    const collateralYieldPercent = (collateralYield.toNumber() / BASE_POINTS) * 100

    return maxNetAprInOffers > collateralYieldPercent ? maxNetAprInOffers : collateralYieldPercent
  }, [collateralConversionRate, collateralToken, collateralYield, leverageSimpleOffers, pair])

  const isLoading =
    collateralTokenLoading ||
    collateralYieldLoading ||
    collateralConversionRateLoading ||
    offersLoading

  return { maxNetApr, maxMultiplier, isLoading }
}
