import { FC, SVGProps } from 'react'

export const Pyth: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <rect x="1.5" y="1.5" width="13" height="13" rx="6.5" fill="#110F24" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.36641 4.05914C6.07044 4.33786 5.14148 5.25081 4.82665 6.55509C4.78662 6.72105 4.77429 7.03871 4.76345 8.1832L4.75 9.60513L5.13925 9.99221C5.35336 10.2051 5.53242 10.3793 5.5372 10.3793C5.54198 10.3793 5.55138 9.57236 5.55807 8.58608C5.57136 6.62164 5.56709 6.66888 5.77295 6.21873C6.09269 5.51968 6.70778 5.0171 7.45246 4.84647C7.86552 4.75187 8.38171 4.77092 8.75282 4.89453C9.94817 5.2927 10.6561 6.48741 10.4075 7.68685C10.1848 8.76123 9.3254 9.51621 8.1871 9.63755L8.00384 9.65707V10.0321C8.00384 10.2384 8.01461 10.4131 8.02774 10.4203C8.04087 10.4275 8.18073 10.4209 8.33849 10.4056C9.89982 10.2538 11.1404 8.97487 11.2432 7.41097C11.35 5.787 10.1865 4.3245 8.56242 4.04112C8.21241 3.98004 7.69803 3.98779 7.36641 4.05914ZM7.56745 5.66841C7.06551 5.81079 6.6557 6.20173 6.46785 6.71745L6.39432 6.91926L6.38473 9.06855L6.37517 11.2178L6.76719 11.6089L7.15924 12L7.17518 9.50705C7.18996 7.19398 7.19526 7.00493 7.24851 6.88767C7.41957 6.51092 7.85353 6.31738 8.27013 6.43205C8.41973 6.47325 8.65571 6.6825 8.7423 6.85079C8.88448 7.12709 8.84158 7.4899 8.6378 7.73501C8.53568 7.85783 8.27554 8.00941 8.16686 8.00941C8.01002 8.00941 8.00384 8.02511 8.00384 8.42357V8.79937H8.14391C8.22095 8.79937 8.37616 8.77115 8.48883 8.73665C9.23523 8.50806 9.71544 7.75409 9.60373 6.98613C9.46445 6.02876 8.49922 5.40412 7.56745 5.66841Z"
        fill="#E6DBFB"
      />
    </g>
    <defs>
      <clipPath id="clip0_26714_245383">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
