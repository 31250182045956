export enum OnboardingModalContentType {
  BORROW = 'borrow',
  MULTIPLY = 'multiply',
  MULTIPLY_LRTS = 'multiplyLrts',
  MULTIPLY_JLP = 'multiplyJlp',
  MULTIPLY_HUBSOL = 'multiplyHub',
  VAULTS = 'vaults',
  PLACE_OFFER = 'placeOffer',
  LOANS_MARKET = 'loansMarket',
  OFFERS = 'offers',
  LOANS = 'loans',
}
