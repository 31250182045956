import { useWallet } from '@solana/wallet-adapter-react'
import { NavLink } from 'react-router-dom'

import { BanxNotificationsButton } from '@banx/components/BanxNotifications'
import { WalletConnectButton } from '@banx/components/Buttons'
import ModeSwitcher from '@banx/components/ModeSwitcher'

import { Logo, LogoFull } from '@banx/icons'
import { PATHS } from '@banx/router'

import { BurgerIcon } from '../BurgerMenu'
import { Navbar } from '../Navbar'
import { ProtocolStats } from '../ProtocolStats'

import styles from './Header.module.less'

export const Header = () => {
  const { connected } = useWallet()

  return (
    <div className={styles.header}>
      <div className={styles.logoWrapper}>
        <NavLink to={PATHS.ROOT}>
          <LogoFull className={styles.fullLogo} />
          <Logo className={styles.logo} />
        </NavLink>
        <ProtocolStats />
      </div>

      <Navbar />

      <div className={styles.widgetContainer}>
        <ModeSwitcher className={styles.modeSwitcher} />
        {connected && <BanxNotificationsButton />}
        <WalletConnectButton />
        <BurgerIcon />
      </div>
    </div>
  )
}
