import { useNavigate } from 'react-router-dom'

import { BreadcrumbHeader } from '@banx/components/BreadcrumbHeader'
import EmptyList from '@banx/components/EmptyList'
import { Loader } from '@banx/components/Loader'

import { useFakeInfinityScroll } from '@banx/hooks'
import { PATHS } from '@banx/router'
import { buildUrlWithModeAndToken } from '@banx/store'
import { AssetMode } from '@banx/store/common'

import FilterSection from './components/FilterSection'
import { HeaderList } from './components/HeaderList'
import LendVaultCard from './components/LendVaultCard'
import { useLendVaultsContent } from './hooks'

import styles from './LendVaultsPage.module.less'

const LendVaultsPage = () => {
  const navigate = useNavigate()

  const {
    vaultsPreview,
    emptyMessage,
    isLoading,
    sortParams,
    searchQuery,
    setSearchQuery,
    selectedLendingToken,
    handleSelectedTokenChange,
    isUserDepositFilterEnabled,
    toggleUserDepositFilter,
    depositedVaultsAmount,
  } = useLendVaultsContent()

  const { data, fetchMoreTrigger } = useFakeInfinityScroll({ rawData: vaultsPreview })

  const handleCardClick = (vaultPubkey: string) => {
    const basePath = `${PATHS.LEND_VAULTS}/${vaultPubkey}`
    navigate(buildUrlWithModeAndToken(basePath, AssetMode.Token, null))
  }

  return (
    <div className={styles.pageWrapper}>
      <BreadcrumbHeader
        breadcrumbs={[{ title: 'Lend', path: PATHS.LEND }, { title: 'Vaults' }]}
        onboardContentType="vaults"
      />

      <div className={styles.content}>
        <FilterSection
          sortParams={sortParams}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          selectedLendingToken={selectedLendingToken}
          handleSelectedTokenChange={handleSelectedTokenChange}
          isUserDepositFilterEnabled={isUserDepositFilterEnabled}
          toggleUserDepositFilter={toggleUserDepositFilter}
          depositedVaultsAmount={depositedVaultsAmount}
        />

        {!emptyMessage && <HeaderList />}

        {isLoading && <Loader />}
        {emptyMessage && <EmptyList message={emptyMessage} />}

        {!emptyMessage && (
          <div className={styles.vaultsList}>
            {data.map((preview) => (
              <LendVaultCard
                key={preview.vaultPubkey}
                vaultPreview={preview}
                onClick={() => handleCardClick(preview.vaultPubkey)}
              />
            ))}
            <div ref={fetchMoreTrigger} />
          </div>
        )}
      </div>
    </div>
  )
}

export default LendVaultsPage
