import { every, map } from 'lodash'
import { useNavigate } from 'react-router-dom'

import { DoughnutChartProps } from '@banx/components/Charts'
import { DisplayValue } from '@banx/components/TableComponents'

import { UserPortfolio } from '@banx/api/common'
import { PATHS } from '@banx/router'
import { buildUrlWithModeAndToken } from '@banx/store'
import { useAssetMode, useTokenType } from '@banx/store/common'
import { getTokenDecimals } from '@banx/utils'

import {
  ALLOCATION_STATUS_COLORS,
  ALLOCATION_STATUS_DISPLAY_NAMES,
  AllocationStatus,
  DEFAULT_NO_DATA_CHART,
} from './constants'

import styles from './MyOffers.module.less'

export const useOffersAllocation = (offers: UserPortfolio['offers'] | undefined) => {
  const { active = 0, underwater = 0, escrow = 0, terminating = 0 } = offers ?? {}
  const totalFunds = escrow + active + underwater + terminating

  const navigate = useNavigate()
  const { tokenType } = useTokenType()
  const { currentAssetMode } = useAssetMode()

  const allocationData = createAllocationsData(escrow, active, underwater, terminating)
  const allocationValues = map(
    allocationData,
    ({ value }) => value / 10 ** getTokenDecimals(tokenType),
  )
  const hasNoAllocation = every(allocationValues, (value) => value === 0)

  //? Chart configuration
  const chartData: DoughnutChartProps = createChartData(allocationValues, totalFunds)

  const goToLendPage = () => {
    navigate(buildUrlWithModeAndToken(PATHS.LEND, currentAssetMode, tokenType))
  }

  const goToOffersPage = () => {
    navigate(buildUrlWithModeAndToken(PATHS.OFFERS, currentAssetMode, tokenType))
  }

  const buttonProps = {
    onClick: hasNoAllocation ? goToLendPage : goToOffersPage,
    text: hasNoAllocation ? 'Lend' : 'Manage my offers',
  }

  return {
    allocationData,
    chartData,
    buttonProps,
  }
}

const createAllocationsData = (
  escrow: number,
  active: number,
  underwater: number,
  terminating: number,
) => {
  const allocationStatusToValueMap = {
    [AllocationStatus.Vault]: escrow,
    [AllocationStatus.Active]: active,
    [AllocationStatus.Underwater]: underwater,
    [AllocationStatus.Terminating]: terminating,
  }

  return map(allocationStatusToValueMap, (value, status) => ({
    label: ALLOCATION_STATUS_DISPLAY_NAMES[status as AllocationStatus],
    key: status,
    value,
  }))
}

const createChartData = (values: number[], totalFunds: number): DoughnutChartProps => ({
  data: isAllZero(values) ? DEFAULT_NO_DATA_CHART.value : values,
  colors: isAllZero(values)
    ? DEFAULT_NO_DATA_CHART.colors
    : Object.values(ALLOCATION_STATUS_COLORS),
  statInfoProps: {
    label: 'Funds',
    value: <DisplayValue value={totalFunds} />,
  },
  className: styles.doughnutChart,
})

const isAllZero = (values: number[]) => every(values, (value) => value === 0)
