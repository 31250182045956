import { useMemo, useState } from 'react'

import { isEmpty } from 'lodash'

import { filterBySearchQuery } from '@banx/components/Search'

import { core } from '@banx/api/nft'
import { MESSAGES } from '@banx/constants/messages'
import {
  isLoanAbleToClaim,
  isLoanAbleToTerminate,
  useLenderLoans,
} from '@banx/pages/nftLending/OffersPage'
import { isLoanListed, isUnderWaterLoan } from '@banx/utils'

import { useSortedLoans } from './useSortedLoans'

export const useLoansTable = () => {
  const { loans, addMints: hideLoans, updateOrAddLoan, loading } = useLenderLoans()

  const {
    filteredLoans,
    searchQuery,
    setSearchQuery,
    isUnderwaterFilterActive,
    onToggleUnderwaterFilter,
    underwaterLoansCount,
  } = useFilterLoans(loans)

  const { sortedLoans, sortParams } = useSortedLoans(filteredLoans)

  const loansToClaim = useMemo(() => sortedLoans.filter(isLoanAbleToClaim), [sortedLoans])
  const loansToTerminate = useMemo(() => {
    return sortedLoans.filter((loan) => isLoanAbleToTerminate(loan) && !isLoanListed(loan))
  }, [sortedLoans])

  const isNoLoans = !loading && isEmpty(loans)
  const isFilteredListEmpty = !loading && isEmpty(filteredLoans)

  const filteredListEmptyMessage = (() => {
    if (isFilteredListEmpty && searchQuery) return MESSAGES.EMPTY_SEARCH_RESULTS
    if (isFilteredListEmpty) return MESSAGES.EMPTY_FILTERED_LIST
    return ''
  })()

  return {
    loans: sortedLoans,
    hideLoans,
    updateOrAddLoan,
    loading,

    isNoLoans,
    filteredListEmptyMessage,

    searchQuery,
    setSearchQuery,

    loansToClaim,
    loansToTerminate,

    underwaterLoansCount,

    isUnderwaterFilterActive,
    onToggleUnderwaterFilter,

    sortViewParams: { sortParams },
  }
}

const useFilterLoans = (loans: core.Loan[]) => {
  const [isUnderwaterFilterActive, setIsUnderwaterFilterActive] = useState(false)
  const [searchQuery, setSearchQuery] = useState<string>('')

  const filteredBySearchQuery = useMemo(() => {
    return filterBySearchQuery(loans, searchQuery, [
      (loan) => loan.nft.meta.collectionName,
      (loan) => loan.nft.meta.name,
      (loan) => loan.nft.mint,
    ])
  }, [loans, searchQuery])

  const onToggleUnderwaterFilter = () => {
    setIsUnderwaterFilterActive(!isUnderwaterFilterActive)
  }

  const underwaterLoans = useMemo(
    () => filteredBySearchQuery.filter(isUnderWaterLoan),
    [filteredBySearchQuery],
  )

  const filteredLoans = useMemo(() => {
    if (isUnderwaterFilterActive) return underwaterLoans
    return filteredBySearchQuery
  }, [filteredBySearchQuery, isUnderwaterFilterActive, underwaterLoans])

  const underwaterLoansCount = underwaterLoans.length > 0 ? underwaterLoans.length : null

  return {
    filteredLoans,

    searchQuery,
    setSearchQuery,

    underwaterLoansCount,
    isUnderwaterFilterActive,
    onToggleUnderwaterFilter,
  }
}
