import { FC, PropsWithChildren } from 'react'

import { BanxNotificationsSider } from '@banx/components/BanxNotifications'
import { WalletAccountSidebar } from '@banx/components/WalletAccountSidebar'
import { ModalPortal } from '@banx/components/modals'

import BurgerMenu from './components/BurgerMenu'
import { Header } from './components/Header'
import TopNotification from './components/TopNotification'

import styles from './Layout.module.less'

export const AppLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={styles.layout}>
      <TopNotification />
      <Header />
      <WalletAccountSidebar />

      <div className={styles.container}>
        <BurgerMenu />
        <ModalPortal />
        <div className={styles.content}>
          {children}
          <BanxNotificationsSider className={styles.notificationsSider} />
        </div>
      </div>
    </div>
  )
}
