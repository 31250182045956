import { useState } from 'react'

import { useWallet } from '@solana/wallet-adapter-react'
import { first, groupBy, isEmpty, map } from 'lodash'

import { createPercentValueJSX } from '@banx/components/TableComponents'

import { core } from '@banx/api/tokens'
import { useMarketTokenRewards } from '@banx/hooks'

import { useTokenMarketsPreview } from '../../hooks'
import { useFilteredMarkets } from './useFilteredMarkets'
import { useSortedMarkets } from './useSortedMarkets'

export const usePlaceTokenOffersContent = () => {
  const { connected } = useWallet()

  const { marketsPreview, isLoading: isLoadingMarkets } = useTokenMarketsPreview()

  const [visibleMarketPubkey, setMarketPubkey] = useState('')

  const { allRewardsData } = useMarketTokenRewards(visibleMarketPubkey)

  const onCardClick = (marketPubkey: string) => {
    const isSameMarketPubkey = visibleMarketPubkey === marketPubkey
    const nextValue = !isSameMarketPubkey ? marketPubkey : ''
    return setMarketPubkey(nextValue)
  }

  const {
    filteredMarkets,
    filteredByCategory,
    selectedCategory,
    onChangeCategory,
    selectedCollections,
    setSelectedCollections,
    isHotFilterActive,
    onToggleHotFilter,
    isDisabledHotFilter,
    isExtraRewardFilterActive,
    onToggleExtraRewardFilter,
    isDisabledExtraRewardFilter,
  } = useFilteredMarkets(marketsPreview, allRewardsData)

  const { sortedMarkets, sortParams } = useSortedMarkets(filteredMarkets)

  const searchSelectParams = createSearchSelectParams({
    options: filteredByCategory,
    selectedOptions: selectedCollections,
    onChange: setSelectedCollections,
  })

  const isLoading = isLoadingMarkets && isEmpty(marketsPreview)
  const showEmptyList = connected && !isLoadingMarkets && isEmpty(filteredMarkets)

  return {
    marketsPreview: sortedMarkets,
    visibleMarketPubkey,
    onCardClick,

    selectedCategory,
    onChangeCategory,

    isHotFilterActive,
    onToggleHotFilter,
    isDisabledHotFilter,

    isExtraRewardFilterActive,
    onToggleExtraRewardFilter,
    isDisabledExtraRewardFilter,

    searchSelectParams,
    sortParams,

    showEmptyList,
    isLoading,
  }
}

interface CreateSearchSelectProps {
  options: core.TokenMarketPreview[]
  selectedOptions: string[]
  onChange: (option: string[]) => void
}

const createSearchSelectParams = ({
  options,
  selectedOptions,
  onChange,
}: CreateSearchSelectProps) => {
  const marketsGroupedByTicker = groupBy(options, (market) => market.collateral.ticker)

  const searchSelectOptions = map(marketsGroupedByTicker, (groupedMarkets) => {
    const firstMarketInGroup = first(groupedMarkets)
    const { ticker = '', logoUrl = '' } = firstMarketInGroup?.collateral || {}
    const marketApr = firstMarketInGroup?.marketApr

    return { ticker, logoUrl, marketApr }
  })

  const searchSelectParams = {
    options: searchSelectOptions,
    selectedOptions,
    onChange,
    labels: ['Collateral', 'Avg APR'],
    optionKeys: {
      labelKey: 'ticker',
      valueKey: 'marketPubkey',
      imageKey: 'logoUrl',
      secondLabel: {
        key: 'marketApr',
        format: (value: number) => createPercentValueJSX(value),
      },
    },
  }

  return searchSelectParams
}
