import { FC, ReactNode } from 'react'

import { LendingTokenType } from 'fbonds-core/lib/fbond-protocol/types'
import { useNavigate } from 'react-router-dom'

import { Button } from '@banx/components/Buttons'
import { OnboardingCarousel } from '@banx/components/OnboardingCarousel'
import { TokenSwitcher } from '@banx/components/TokenSwitcher'

import { CircleCheck, Pencil, SOL, TableView, USDC } from '@banx/icons'
import { PATHS } from '@banx/router'
import { buildUrlWithModeAndToken } from '@banx/store'
import { AssetMode, useTokenType } from '@banx/store/common'
import { isUsdcTokenType } from '@banx/utils'

import styles from './LendLandingPage.module.less'

const LendLandingPage = () => {
  const navigate = useNavigate()
  const { tokenType } = useTokenType()

  const handleGoToPlaceOfferPage = () => {
    navigate(buildUrlWithModeAndToken(PATHS.LEND_MARKERS, AssetMode.NFT, tokenType))
  }

  const handleGoToLoanMarketPage = () => {
    navigate(buildUrlWithModeAndToken(PATHS.LEND_LOANS_MARKET, AssetMode.NFT, tokenType))
  }

  return (
    <div className={styles.landingPageWrapper}>
      <div className={styles.landingPageSection}>
        <div className={styles.introSection}>
          <h3>Lend your assets</h3>
          <p>
            Earn high yield on your SOL or USDC through active or passive lending and get returns
            while your funds are idle
          </p>
        </div>

        <OnboardingCarousel contentType="lendToken" />
      </div>

      <TokenSwitcher />

      <div className={styles.overviewContainer}>
        <OverviewBlock
          icon={<Pencil />}
          title="Place offer"
          apy="Up to 500% APY"
          features={['Flexible Settings', 'Customizable Yield', 'Full Control']}
          buttonText="Create offer"
          onClick={handleGoToPlaceOfferPage}
          tokenType={tokenType}
        />
        <OverviewBlock
          icon={<TableView />}
          title="Loans market"
          apy="30% - 80% APY"
          features={['Instant yield']}
          buttonText="Explore market"
          onClick={handleGoToLoanMarketPage}
          tokenType={tokenType}
        />
      </div>
    </div>
  )
}

export default LendLandingPage

interface OverviewBlockProps {
  icon: ReactNode
  title: string
  apy: string
  features: string[]
  buttonText: string
  onClick: () => void
  tokenType: LendingTokenType
}

const OverviewBlock: FC<OverviewBlockProps> = ({
  icon,
  title,
  apy,
  features,
  buttonText,
  onClick,
  tokenType,
}) => (
  <div onClick={onClick} className={styles.overviewBlock}>
    <div className={styles.overviewBlockHeader}>
      {icon}
      <h3>{title}</h3>
    </div>
    <div className={styles.apyContainer}>
      {isUsdcTokenType(tokenType) ? <USDC /> : <SOL />}
      <span>{apy}</span>
    </div>
    <ul className={styles.featuresList}>
      {features.map((feature, index) => (
        <li key={index} className={styles.featuresListItem}>
          <CircleCheck />
          {feature}
        </li>
      ))}
    </ul>
    <Button variant="secondary">{buttonText}</Button>
  </div>
)
