export enum BorrowType {
  StakedBanx = 'StakedBanx',
  CoreNft = 'CoreNft',
  CNft = 'CNft',
  Default = 'Default',
}

export enum ListingType {
  StakedBanx = 'StakedBanx',
  CoreNft = 'CoreNft',
  CNft = 'CNft',
  Default = 'Default',
}
