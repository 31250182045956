import { useMemo, useState } from 'react'

import { isEmpty } from 'lodash'

import { filterBySearchQuery } from '@banx/components/Search'

import { MESSAGES } from '@banx/constants/messages'
import { useTokenType } from '@banx/store/common'

import { useBorrowNftsAndMarketsQuery } from '../../hooks'
import { useSortedMarkets } from './useSortedMarkets'

export const useRequestLoansContent = () => {
  const { marketsPreview, isLoading } = useBorrowNftsAndMarketsQuery()

  const { tokenType, setTokenType } = useTokenType()

  const [visibleMarketPubkey, setMarketPubkey] = useState('')
  const [searchQuery, setSearchQuery] = useState<string>('')

  const onCardClick = (marketPubkey: string) => {
    const isSameMarketPubkey = visibleMarketPubkey === marketPubkey
    const nextValue = !isSameMarketPubkey ? marketPubkey : ''
    return setMarketPubkey(nextValue)
  }

  const filteredBySearchQuery = useMemo(() => {
    return filterBySearchQuery(marketsPreview, searchQuery, [(preview) => preview.collectionName])
  }, [marketsPreview, searchQuery])

  const { sortedMarkets, sortParams } = useSortedMarkets(filteredBySearchQuery)

  const isNoMarkets = isEmpty(marketsPreview) && !isLoading
  const isFilteredListEmpty = isEmpty(filteredBySearchQuery) && !isLoading

  const filteredListEmptyMessage = (() => {
    if (isFilteredListEmpty && searchQuery) return MESSAGES.EMPTY_SEARCH_RESULTS
    if (isFilteredListEmpty) return MESSAGES.EMPTY_FILTERED_LIST
    return ''
  })()

  return {
    markets: sortedMarkets,
    visibleMarketPubkey,
    isNoMarkets,
    isFilteredListEmpty,
    filteredListEmptyMessage,
    searchQuery,
    setSearchQuery,
    onCardClick,
    sortParams,
    isLoading,
    tokenType,
    setTokenType,
  }
}
