import { FC, SVGProps } from 'react'

export const Switchboard: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="1.5" y="1.5" width="13" height="13" rx="6.5" fill="#E6F2F0" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.57812 5.56456C5.92486 5.73642 5.51584 6.09312 5.29807 6.68085C5.18363 6.98971 5.18356 7.57607 5.29789 7.90268C5.49557 8.46735 5.90678 8.8432 6.54167 9.03955C6.81248 9.12328 8.6992 9.15918 8.92834 9.08496C9.21392 8.99246 9.31108 8.69596 9.13415 8.45692C9.00866 8.28738 8.94438 8.27794 7.86383 8.27033C6.88241 8.26339 6.84569 8.26061 6.65855 8.17892C6.05976 7.91751 5.9062 7.06118 6.37666 6.60667C6.65276 6.33987 6.61907 6.34339 8.89844 6.34339H10.9141L11.0343 6.24145C11.1363 6.1549 11.5 5.59256 11.5 5.52137C11.5 5.50851 10.4459 5.49893 9.15755 5.5001C7.03451 5.50203 6.7929 5.50806 6.57812 5.56456ZM7.06831 6.90732C6.79939 6.98788 6.68976 7.3141 6.85787 7.53359C6.99291 7.7099 7.04479 7.71764 8.15196 7.72687L9.1849 7.73547L9.37262 7.83079C9.71161 8.00294 9.89583 8.31644 9.89583 8.72122C9.89583 8.98428 9.79955 9.2211 9.62334 9.39131C9.34724 9.65811 9.38093 9.65459 7.10156 9.65459H5.08589L4.96568 9.75653C4.86371 9.84308 4.5 10.4054 4.5 10.4766C4.5 10.4895 5.54486 10.5 6.82189 10.5C9.37204 10.5 9.38224 10.4993 9.84355 10.2833C10.0888 10.1684 10.4037 9.88986 10.5375 9.66938C10.7201 9.36835 10.7807 9.13463 10.7831 8.72111C10.7849 8.40641 10.7697 8.28886 10.702 8.0953C10.5082 7.5411 10.1349 7.19183 9.49796 6.96886C9.31465 6.90464 9.18858 6.89636 8.23698 6.88576C7.63556 6.87903 7.13167 6.88833 7.06831 6.90732Z"
      fill="black"
    />
  </svg>
)
