import { FC, SVGProps } from 'react'

export const PencilLtv: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.1852 3.89275C27.771 3.70242 28.402 3.70242 28.9877 3.89275C29.3974 4.02587 29.7192 4.25367 29.9909 4.48432C30.2429 4.69824 30.5215 4.97693 30.8249 5.28037L30.825 5.28043L33.7821 8.23755C34.0856 8.54096 34.3643 8.81962 34.5782 9.07162C34.8088 9.34331 35.0366 9.66507 35.1698 10.0748C35.3601 10.6605 35.3601 11.2915 35.1698 11.8773C35.0366 12.287 34.8088 12.6088 34.5782 12.8804C34.3643 13.1324 34.0857 13.411 33.7823 13.7143L33.7822 13.7144L30.9285 16.5681L22.4944 8.13403L25.348 5.28043C25.6514 4.97697 25.93 4.69826 26.1821 4.48432C26.4537 4.25367 26.7755 4.02587 27.1852 3.89275ZM20.2446 10.3838L28.6787 18.8179L16.2545 31.2421L16.1742 31.3225C15.4021 32.0947 14.9043 32.5927 14.3289 32.9939C13.8193 33.3494 13.2696 33.6436 12.6911 33.8705C12.0381 34.1266 11.3477 34.2646 10.2769 34.4786L10.1654 34.5009L6.9178 35.1504L6.91759 35.1505C6.62696 35.2086 6.33208 35.2677 6.08137 35.2945C5.81578 35.323 5.42544 35.339 5.01987 35.1712C4.50877 34.9598 4.10274 34.5537 3.89129 34.0426C3.7235 33.6371 3.73952 33.2467 3.76798 32.9811C3.79484 32.7303 3.8539 32.4353 3.9121 32.1446L4.56161 28.8971L4.58389 28.7856C4.7979 27.7148 4.9359 27.0244 5.19203 26.3714C5.41892 25.7929 5.71313 25.2432 6.06857 24.7336C6.46982 24.1582 6.96777 23.6604 7.74002 22.8884L7.82038 22.808L20.2446 10.3838ZM15.6471 19.7433L14.9075 20.4829L19.9211 22.8656L20.6184 22.1682L18.2358 17.1546L17.4962 17.8943L19.4192 21.6558L19.4086 21.6663L15.6471 19.7433ZM13.5604 23.0451L12.4562 24.1493L11.8487 23.5417L14.7755 20.6149L15.383 21.2225L14.2789 22.3266L17.3694 25.4172L16.651 26.1357L13.5604 23.0451ZM9.73444 25.656L10.4529 24.9375L13.5435 28.028L15.3926 26.179L16.0001 26.7865L13.4326 29.3541L9.73444 25.656Z"
      fill="#8C8C8C"
    />
  </svg>
)
