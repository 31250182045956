import { BN, web3 } from 'fbonds-core'
import {
  BANX_TOKEN_MINT,
  BASE_POINTS,
  PROTOCOL_FEE_TOKEN_BN,
} from 'fbonds-core/lib/fbond-protocol/constants'
import { calculateCurrentInterestSolPure } from 'fbonds-core/lib/fbond-protocol/functions/perpetual'
import { calcBorrowerTokenAPR } from 'fbonds-core/lib/fbond-protocol/helpers'
import moment from 'moment'

import { CollateralToken } from '@banx/api/tokens'
import { SECONDS_IN_DAY } from '@banx/constants'
import {
  ZERO_BN,
  adjustTokenAmountWithUpfrontFee,
  bnToHuman,
  calcWeightedAverage,
  stringToBN,
  sumBNs,
} from '@banx/utils'

import { BorrowToken } from './../constants'
import { getMaxBorrowableAmount } from './OrderBook/helpers'
import { EnhancedBorrowOffer } from './hooks'

interface GetErrorMessageProps {
  borrowToken: BorrowToken | undefined
  collateral: CollateralToken | undefined
  borrowInputValue: string
}

export const getErrorMessage = ({
  borrowToken,
  borrowInputValue,
  collateral,
}: GetErrorMessageProps) => {
  if (!borrowToken || !collateral) return undefined

  const borrowAmount = stringToBN(borrowInputValue, borrowToken?.collateral.decimals)

  if (collateral?.amountInWallet.isZero()) {
    return `You don't have any ${collateral.collateral.ticker}`
  }

  if (borrowAmount.isZero()) {
    return 'Enter an amount to borrow'
  }

  return
}

export const getSummaryInfo = (offers: EnhancedBorrowOffer[], marketPubkey: string) => {
  const totalAmountToGet = sumBNs(offers.map((offer) => new BN(offer.maxTokenToGet)))
  const totalCollateralsAmount = sumBNs(offers.map((offer) => new BN(offer.maxCollateralToReceive)))

  const upfrontFee = totalAmountToGet.mul(PROTOCOL_FEE_TOKEN_BN).div(new BN(BASE_POINTS)).toNumber()

  const amountToGetArray = offers.map((offer) => parseFloat(offer.maxTokenToGet))

  const aprRateArray = offers.map((offer) =>
    calcBorrowerTokenAPR(parseFloat(offer.apr), new web3.PublicKey(marketPubkey)),
  )

  const weightedApr = calcWeightedAverage(aprRateArray, amountToGetArray)

  const weeklyFee = calculateCurrentInterestSolPure({
    loanValue: totalAmountToGet.toNumber(),
    startTime: moment().unix(),
    currentTime: moment().unix() + SECONDS_IN_DAY * 7,
    rateBasePoints: weightedApr,
  })

  const adjustedTotalAmountToGet = adjustTokenAmountWithUpfrontFee(totalAmountToGet)

  return {
    upfrontFee,
    weightedApr,
    weeklyFee,
    totalAmountToGet: adjustedTotalAmountToGet,
    totalCollateralsAmount: totalCollateralsAmount,
  }
}

export const getInitialCollateral = (collateralsList: CollateralToken[]) => {
  const [firstCollateral] = collateralsList

  if (!firstCollateral?.amountInWallet.isZero()) {
    return firstCollateral
  }

  return (
    collateralsList.find(({ collateral }) => collateral.mint === BANX_TOKEN_MINT.toBase58()) ||
    firstCollateral
  )
}

export const calcTotalCollateralAmount = (
  selectedOffers: EnhancedBorrowOffer[],
  collateralToken: CollateralToken,
): number => {
  const maxCollateralAmount = sumBNs(
    selectedOffers.map((offer) => new BN(offer.maxCollateralToReceive)),
  )

  const availableAmount = BN.min(collateralToken.amountInWallet, maxCollateralAmount)
  return bnToHuman(availableAmount, collateralToken.collateral.decimals)
}

export const calculateRequiredBorrowAmount = (props: {
  offers: EnhancedBorrowOffer[]
  borrowInputValue: string
  collateralToken: CollateralToken | undefined
  tokenDecimals: number
}) => {
  const { offers, borrowInputValue, collateralToken, tokenDecimals } = props

  if (!collateralToken || !offers?.length) return ZERO_BN

  const activeOffers = offers.filter((offer) => !offer.disabled)
  const maxBorrowable = getMaxBorrowableAmount({
    offers: activeOffers,
    collateralAmount: collateralToken.amountInWallet,
    tokenDecimals,
  })

  const requestedBorrowAmount = adjustTokenAmountWithUpfrontFee(
    stringToBN(borrowInputValue, tokenDecimals),
    true,
  )

  return BN.min(maxBorrowable, requestedBorrowAmount)
}

export const calculateRemainingAmount = (
  selectedOffers: EnhancedBorrowOffer[],
  requiredAmount: BN,
): BN => {
  const borrowedAmount = sumBNs(selectedOffers.map((offer) => new BN(offer.maxTokenToGet)))
  return BN.max(requiredAmount.sub(borrowedAmount), ZERO_BN)
}
