import { useMemo, useState } from 'react'

import { filter, isEmpty } from 'lodash'

import { filterBySearchQuery } from '@banx/components/Search'

import { MESSAGES } from '@banx/constants/messages'
import { useMarketsPreview } from '@banx/hooks'
import { useTokenType } from '@banx/store/common'

import { useSortedMarkets } from './useSortedMarkets'

export const useLendPageContent = () => {
  const { marketsPreview, isLoading } = useMarketsPreview()
  const { tokenType, setTokenType } = useTokenType()

  const [isHotFilterActive, setIsHotFilterActive] = useState(false)
  const [searchQuery, setSearchQuery] = useState<string>('')

  const filtereMarketsBySearch = useMemo(() => {
    return filterBySearchQuery(marketsPreview, searchQuery, [(market) => market.collectionName])
  }, [marketsPreview, searchQuery])

  const { sortedMarkets, sortParams } = useSortedMarkets(filtereMarketsBySearch)

  const hotMarkets = filter(sortedMarkets, (market) => market.isHot)
  const filteredHotMarkets = isHotFilterActive ? hotMarkets : sortedMarkets

  const isNoMarkets = !isLoading && isEmpty(marketsPreview)
  const isFilteredListEmpty = !isLoading && isEmpty(filteredHotMarkets)

  const emptyMessage = (() => {
    if (isNoMarkets) return MESSAGES.NO_MARKETS_AVAILABLE
    if (isFilteredListEmpty && searchQuery) return MESSAGES.EMPTY_SEARCH_RESULTS
    if (isFilteredListEmpty) return MESSAGES.EMPTY_FILTERED_LIST
    return
  })()

  return {
    marketsPreview: filteredHotMarkets,
    isLoading,
    searchQuery,
    setSearchQuery,
    emptyMessage,
    sortParams,
    tokenType,
    setTokenType,
    isHotFilterActive,
    hotMarkets,
    onToggleHotFilter: () => setIsHotFilterActive(!isHotFilterActive),
  }
}
