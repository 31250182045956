import { FC, SVGProps } from 'react'

export const SOL: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4951_37312)">
      <g clipPath="url(#clip1_4951_37312)">
        <path
          d="M4.68454 10.4547C4.74712 10.3828 4.83317 10.3409 4.92444 10.3409H13.2009C13.3522 10.3409 13.4278 10.5505 13.3209 10.6733L11.6859 12.5507C11.6233 12.6226 11.5373 12.6645 11.446 12.6645H3.16953C3.01829 12.6645 2.94267 12.4549 3.04958 12.3321L4.68454 10.4547Z"
          fill="url(#paint0_linear_4951_37312)"
        />
        <path
          d="M4.68454 3.44492C4.74973 3.37305 4.83578 3.33113 4.92444 3.33113H13.2009C13.3522 3.33113 13.4278 3.54073 13.3209 3.6635L11.6859 5.54095C11.6233 5.61281 11.5373 5.65473 11.446 5.65473H3.16953C3.01829 5.65473 2.94267 5.44513 3.04958 5.32236L4.68454 3.44492Z"
          fill="url(#paint1_linear_4951_37312)"
        />
        <path
          d="M11.6859 6.92734C11.6233 6.85547 11.5373 6.81355 11.446 6.81355H3.16953C3.01829 6.81355 2.94267 7.02316 3.04958 7.14592L4.68454 9.02337C4.74712 9.09523 4.83317 9.13715 4.92444 9.13715H13.2009C13.3522 9.13715 13.4278 8.92755 13.3209 8.80478L11.6859 6.92734Z"
          fill="url(#paint2_linear_4951_37312)"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_4951_37312"
        x1="12.4103"
        y1="2.20961"
        x2="5.33975"
        y2="14.0034"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4951_37312"
        x1="9.90569"
        y1="0.708054"
        x2="2.83516"
        y2="12.5018"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4951_37312"
        x1="11.15"
        y1="1.45406"
        x2="4.07948"
        y2="13.2478"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <clipPath id="clip0_4951_37312">
        <rect width="16" height="16" fill="white" />
      </clipPath>
      <clipPath id="clip1_4951_37312">
        <rect width="10.3704" height="9.33333" fill="white" transform="translate(3 3.33113)" />
      </clipPath>
    </defs>
  </svg>
)

export const SOLFilled: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="8" cy="8" r="8" fill="var(--bg-tertiary)" />
    <g>
      <path
        d="M4.93908 10.2662C4.99685 10.1998 5.07628 10.1611 5.16052 10.1611H12.8004C12.94 10.1611 13.0098 10.3546 12.9111 10.4679L11.4019 12.201C11.3441 12.2673 11.2647 12.306 11.1804 12.306H3.54061C3.401 12.306 3.3312 12.1125 3.42989 11.9992L4.93908 10.2662Z"
        fill="url(#paint0_linear_24289_79815)"
      />
      <path
        d="M4.93908 3.79546C4.99925 3.72913 5.07868 3.69043 5.16052 3.69043H12.8004C12.94 3.69043 13.0098 3.88391 12.9111 3.99723L11.4019 5.73026C11.3441 5.7966 11.2647 5.83529 11.1804 5.83529H3.54061C3.401 5.83529 3.3312 5.64181 3.42989 5.52849L4.93908 3.79546Z"
        fill="url(#paint1_linear_24289_79815)"
      />
      <path
        d="M11.4019 7.00933C11.3441 6.94299 11.2647 6.9043 11.1804 6.9043H3.54061C3.401 6.9043 3.3312 7.09778 3.42989 7.2111L4.93908 8.94413C4.99685 9.01046 5.07628 9.04916 5.16052 9.04916H12.8004C12.94 9.04916 13.0098 8.85568 12.9111 8.74236L11.4019 7.00933Z"
        fill="url(#paint2_linear_24289_79815)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_24289_79815"
        x1="12.0705"
        y1="2.65535"
        x2="5.54388"
        y2="13.5419"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_24289_79815"
        x1="9.75861"
        y1="1.26913"
        x2="3.23196"
        y2="12.1557"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_24289_79815"
        x1="10.9072"
        y1="1.95707"
        x2="4.38056"
        y2="12.8436"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <clipPath id="clip0_24289_79815">
        <rect
          width="9.57265"
          height="8.61539"
          fill="white"
          transform="translate(3.38464 3.69043)"
        />
      </clipPath>
    </defs>
  </svg>
)
