import { FC, SVGProps } from 'react'

export const StarSecondary: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M7 1.3125L8.28643 4.5743C8.40518 4.87539 8.46456 5.02594 8.55548 5.15289C8.63606 5.26541 8.73459 5.36393 8.84711 5.44452C8.97406 5.53544 9.12461 5.59481 9.4257 5.71356L12.6875 7L9.4257 8.28643C9.12461 8.40518 8.97406 8.46456 8.84711 8.55548C8.73459 8.63606 8.63606 8.73459 8.55548 8.84711C8.46456 8.97406 8.40518 9.12461 8.28643 9.4257L7 12.6875L5.71356 9.4257C5.59481 9.12461 5.53544 8.97406 5.44452 8.84711C5.36393 8.73459 5.26541 8.63606 5.15289 8.55548C5.02594 8.46456 4.87539 8.40518 4.5743 8.28643L1.3125 7L4.5743 5.71356C4.87539 5.59481 5.02594 5.53544 5.15289 5.44452C5.26541 5.36393 5.36393 5.26541 5.44452 5.15289C5.53544 5.02594 5.59481 4.87539 5.71356 4.5743L7 1.3125Z"
        fill="#FAAD14"
      />
    </g>
    <defs>
      <clipPath id="clip0_21401_15372">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
