import { FC, SVGProps } from 'react'

export const Dashboard: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <g id="Icon/User">
      <path
        id="Icon (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0003 3.75C10.2054 3.75 8.7503 5.20507 8.7503 7C8.7503 8.79493 10.2054 10.25 12.0003 10.25C13.7952 10.25 15.2503 8.79493 15.2503 7C15.2503 5.20507 13.7952 3.75 12.0003 3.75ZM7.2503 7C7.2503 4.37665 9.37695 2.25 12.0003 2.25C14.6237 2.25 16.7503 4.37665 16.7503 7C16.7503 9.62335 14.6237 11.75 12.0003 11.75C9.37695 11.75 7.2503 9.62335 7.2503 7ZM12.0003 14.75C8.54853 14.75 5.75031 17.5482 5.75031 21C5.75031 21.4142 5.41452 21.75 5.00031 21.75C4.58609 21.75 4.25031 21.4142 4.25031 21C4.25031 16.7198 7.7201 13.25 12.0003 13.25C16.2805 13.25 19.7503 16.7198 19.7503 21C19.7503 21.4142 19.4145 21.75 19.0003 21.75C18.5861 21.75 18.2503 21.4142 18.2503 21C18.2503 17.5482 15.4521 14.75 12.0003 14.75Z"
        fill="#1B1B1B"
      />
    </g>
  </svg>
)
