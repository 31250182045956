import { FC, SVGProps } from 'react'

export const Interest: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="260"
    height="32"
    viewBox="0 0 260 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M94.8116 4.80078H98.3316L106.428 27.2008H102.652L100.732 21.8888H92.3796L90.4596 27.2008H86.6836L94.8116 4.80078ZM99.8676 18.7848L96.5716 9.31278H96.5076L93.2756 18.7848H99.8676Z"
      fill="#8C8C8C"
    />
    <path
      d="M108.979 4.80078H112.371L122.931 21.0888H122.995V4.80078H126.547V27.2008H123.155L112.595 10.9448H112.531V27.2008H108.979V4.80078Z"
      fill="#8C8C8C"
    />
    <path
      d="M136.865 17.9848L128.961 4.80078H133.025L138.689 14.5288H138.753L144.417 4.80078H148.481L140.577 17.9848V27.2008H136.865V17.9848Z"
      fill="#8C8C8C"
    />
    <path
      d="M150.481 8.43239V5.52039L151.601 4.40039H154.037L155.157 5.52039V8.43239L154.037 9.55239H151.601L150.481 8.43239ZM157.775 4.40039H159.679L154.317 14.2004H152.413L157.775 4.40039ZM153.225 8.15239L153.533 7.84439V6.10839L153.225 5.80039H152.413L152.105 6.10839V7.84439L152.413 8.15239H153.225ZM156.935 13.0804V10.1684L158.055 9.04839H160.491L161.611 10.1684V13.0804L160.491 14.2004H158.055L156.935 13.0804ZM159.679 12.8004L159.987 12.4924V10.7564L159.679 10.4484H158.867L158.559 10.7564V12.4924L158.867 12.8004H159.679Z"
      fill="#8C8C8C"
    />
    <path
      d="M153.553 19.1992H155.041L158.113 27.5992H156.457L155.773 25.7392H152.821L152.137 27.5992H150.481L153.553 19.1992ZM155.413 24.3952L154.297 21.2032H154.273L153.193 24.3952H155.413Z"
      fill="#8C8C8C"
    />
    <path
      d="M158.959 19.1992H164.227L165.511 20.4952V23.4352L164.215 24.7432H160.591V27.5992H158.959V19.1992ZM163.483 23.3752L163.903 22.9552V20.9872L163.483 20.5672H160.591V23.3752H163.483Z"
      fill="#8C8C8C"
    />
    <path
      d="M173.317 24.8152V27.5992H171.685V25.1872L170.965 24.3832H168.349V27.5992H166.717V19.1992H171.985L173.269 20.4952V23.0392L172.453 23.8672L173.317 24.8152ZM168.349 23.0392H171.241L171.661 22.6192V20.9872L171.241 20.5672H168.349V23.0392Z"
      fill="#8C8C8C"
    />
  </svg>
)
