import { FC, SVGProps } from 'react'

import classNames from 'classnames'

export const StakeFilled: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={classNames(props.className, 'stake')}
  >
    <g clipPath="url(#clip0_12671_4244)">
      <path
        d="M0.197266 10.9181V8.65576C1.23005 9.65576 3.49235 11.7049 9.29563 11.7049C14.0958 11.7049 16.935 9.75412 17.7546 8.75412L17.8038 10.9181C17.4104 11.9508 15.1579 14.0754 9.29563 14.3115C3.43333 14.5476 0.78743 12.1476 0.197266 10.9181Z"
        fill="#1B1B1B"
      />
      <path
        d="M0.197266 14.5575V12.2952C1.23005 13.2952 3.49235 15.3443 9.29563 15.3443C14.0958 15.3443 16.935 13.3935 17.7546 12.3935L17.8038 14.5575C17.4104 15.5902 15.1579 17.7148 9.29563 17.9509C3.43333 18.187 0.78743 15.787 0.197266 14.5575Z"
        fill="#1B1B1B"
      />
      <path
        d="M17.8031 3.88525C17.9015 4.22951 17.8945 4.69318 17.9015 5.21311C17.91 5.84802 17.9015 6.39344 17.8031 6.83607C16.6199 9.73771 12.8923 10.7213 9.04902 10.7213C5.40479 10.7213 1.08181 9.59016 0.196593 6.83607C0.098281 6.34426 0.0955819 5.9081 0.0982193 5.31148C0.100687 4.75318 0.0982194 4.27869 0.196566 3.88525C0.625917 2.1676 3.94265 0 9.04902 0C14.1554 0 17.3113 1.96721 17.8031 3.88525Z"
        fill="#1B1B1B"
      />
      <ellipse cx="9.04979" cy="4.3279" rx="7.77049" ry="3.34426" fill="#98EC2D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4247 5.89402C11.78 5.89402 12.8787 5.42709 12.8787 4.85111C12.8787 4.27513 11.78 3.8082 10.4247 3.8082C9.06942 3.8082 7.97073 4.27513 7.97073 4.85111C7.97073 5.42709 9.06942 5.89402 10.4247 5.89402ZM10.4247 6.37629C12.4068 6.37629 14.0135 5.69344 14.0135 4.85111C14.0135 4.00877 12.4068 3.32593 10.4247 3.32593C8.44269 3.32593 6.83594 4.00877 6.83594 4.85111C6.83594 5.69344 8.44269 6.37629 10.4247 6.37629Z"
        fill="#1B1B1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9968 2.45399C13.6245 2.71315 14.112 3.03334 14.4062 3.39202C13.5366 3.03047 12.3465 2.8076 11.0338 2.8076C8.58172 2.8076 6.55703 3.58531 6.25096 4.592C6.22455 4.50517 6.21094 4.41663 6.21094 4.32675C6.21094 3.19476 8.37022 2.2771 11.0338 2.2771C11.7328 2.2771 12.3971 2.3403 12.9968 2.45399Z"
        fill="#1B1B1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5437 4.79017C14.66 4.59759 14.7218 4.39573 14.7218 4.18774C14.7218 4.0222 14.6826 3.86055 14.608 3.70436C13.8093 3.32031 12.6727 3.06234 11.3754 3.0187C11.1645 3.01161 10.9558 3.01039 10.75 3.01473C12.7882 3.19707 14.3147 3.91938 14.5437 4.79017Z"
        fill="#1B1B1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1031 2.15227C11.7228 2.11547 11.327 2.09604 10.9204 2.09604C7.99049 2.09604 5.61529 3.10547 5.61529 4.35066C5.61529 5.46151 7.50562 6.38472 9.99377 6.571C9.68172 6.59353 9.36101 6.60527 9.03385 6.60527C5.89238 6.60527 3.3457 5.52298 3.3457 4.18789C3.3457 2.85281 5.89238 1.77051 9.03385 1.77051C10.1643 1.77051 11.2177 1.91064 12.1031 2.15227Z"
        fill="#1B1B1B"
      />
    </g>
    <defs>
      <clipPath id="clip0_12671_4244">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
