import { FC, useEffect, useMemo } from 'react'

import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

import { BreadcrumbHeader } from '@banx/components/BreadcrumbHeader'
import { Button } from '@banx/components/Buttons'
import {
  FilterDropdown,
  MARKET_OPTIONS_WITHOUT_ALL,
  TokenDropdown,
} from '@banx/components/Dropdowns'
import EmptyList from '@banx/components/EmptyList'
import { Search } from '@banx/components/Search'
import Table from '@banx/components/Table'
import Tooltip from '@banx/components/Tooltip'

import { MESSAGES } from '@banx/constants/messages'
import { Hourglass, Snowflake } from '@banx/icons'
import { PATHS } from '@banx/router'
import { buildUrlWithModeAndToken } from '@banx/store'
import { AssetMode, ViewState, useTableView, useTokenType } from '@banx/store/common'

import { Summary } from './Summary'
import { getTableColumns } from './columns'
import { useInstantLendTable } from './hooks'
import { useLoansState } from './loansState'

import styles from './InstantLendTable.module.less'

const InstantLendTable = () => {
  const { tokenType, setTokenType } = useTokenType()
  const { viewState } = useTableView()

  const {
    loans,
    loading,
    isNoLoans,
    filteredListEmptyMessage,
    sortViewParams,
    searchQuery,
    setSearchQuery,
    auctionLoansAmount,
    freezeLoansAmount,
    isAuctionFilterEnabled,
    toggleAuctionFilter,
    isFreezeFilterEnabled,
    toggleFreezeFilter,
  } = useInstantLendTable()

  const {
    selection,
    toggle: toggleLoanInSelection,
    find: findLoanInSelection,
    set: setSelection,
    clear: clearSelection,
  } = useLoansState()

  //? Clear selection when tokenType changes
  //? To prevent selection transfering from one tokenType to another
  useEffect(() => {
    clearSelection()
  }, [clearSelection, tokenType])

  const hasSelectedLoans = !!selection.length

  const onSelectAll = () => {
    if (hasSelectedLoans) {
      clearSelection()
    } else {
      setSelection(loans)
    }
  }

  const columns = getTableColumns({
    isCardView: viewState === ViewState.CARD,
    toggleLoanInSelection,
    findLoanInSelection,
    onSelectAll,
    hasSelectedLoans,
  })

  const rowParams = useMemo(() => {
    return {
      onRowClick: toggleLoanInSelection,
    }
  }, [toggleLoanInSelection])

  const customJSX = (
    <>
      <TokenDropdown
        option={tokenType}
        options={MARKET_OPTIONS_WITHOUT_ALL}
        onChange={setTokenType}
      />
      <FilterDropdown>
        <div className={styles.filterButtonsContainer}>
          <span className={styles.filterButtonsTitle}>Tags</span>
          <div className={styles.filterButtons}>
            <AuctionFilterButton
              loansAmount={auctionLoansAmount}
              isActive={isAuctionFilterEnabled}
              onClick={toggleAuctionFilter}
            />
            <FreezeFilterButton
              loansAmount={freezeLoansAmount}
              isActive={isFreezeFilterEnabled}
              onClick={toggleFreezeFilter}
            />
          </div>
        </div>
      </FilterDropdown>
      <Search value={searchQuery} onChange={setSearchQuery} />
    </>
  )

  return (
    <div className={styles.pageWrapper}>
      <BreadcrumbHeader
        breadcrumbs={[{ title: 'Earn', path: PATHS.LEND }, { title: 'Loans market' }]}
        onboardContentType="loansMarket"
      />
      <div className={styles.tableRoot}>
        <Table
          data={loans}
          columns={columns}
          className={styles.refinanceTable}
          rowParams={rowParams}
          sortViewParams={sortViewParams}
          loading={loading}
          customJSX={customJSX}
          emptyMessage={isNoLoans ? <NoLoans /> : <EmptyList message={filteredListEmptyMessage} />}
          showCard
        />
        {!isNoLoans && !filteredListEmptyMessage && !loading && <Summary loans={loans} />}
      </div>
    </div>
  )
}

export default InstantLendTable

const NoLoans = () => {
  const navigate = useNavigate()

  const goToLendPage = () => {
    navigate(buildUrlWithModeAndToken(PATHS.LEND, AssetMode.NFT, null))
  }

  return (
    <EmptyList
      message={MESSAGES.LENDER_NO_LOANS}
      buttonProps={{ text: 'Lend', onClick: goToLendPage }}
    />
  )
}

interface FilterButtonProps {
  onClick: () => void
  isActive: boolean
  loansAmount: number | null
}

const AuctionFilterButton: FC<FilterButtonProps> = ({ isActive, onClick, loansAmount }) => (
  <Tooltip title={loansAmount ? 'Expiring loans' : 'No expiring loans currently'}>
    <div
      className={classNames(styles.filterButtonWrapper, styles.auction)}
      data-loans-amount={loansAmount}
    >
      <Button
        className={classNames(
          styles.auctionFilterButton,
          { [styles.active]: isActive },
          { [styles.disabled]: !loansAmount },
        )}
        disabled={!loansAmount}
        onClick={onClick}
        variant="tertiary"
        type="circle"
      >
        <Hourglass className={styles.hourglassIcon} />
        Expiring
      </Button>
    </div>
  </Tooltip>
)

const FreezeFilterButton: FC<FilterButtonProps> = ({ isActive, onClick, loansAmount }) => (
  <Tooltip title={loansAmount ? 'Loans with freeze' : 'No loans with freeze currently'}>
    <div
      className={classNames(styles.filterButtonWrapper, styles.freeze)}
      data-loans-amount={loansAmount}
    >
      <Button
        className={classNames(
          styles.freezeFilterButton,
          { [styles.active]: isActive },
          { [styles.disabled]: !loansAmount },
        )}
        disabled={!loansAmount}
        onClick={onClick}
        variant="tertiary"
        type="circle"
      >
        <Snowflake className={styles.snowflakeIcon} />
        Freeze
      </Button>
    </div>
  </Tooltip>
)
