import { useState } from 'react'

import { BreadcrumbHeader } from '@banx/components/BreadcrumbHeader'
import EmptyList from '@banx/components/EmptyList'
import { Loader } from '@banx/components/Loader'

import { useFakeInfinityScroll } from '@banx/hooks'
import { PATHS } from '@banx/router'

import FilterSection from './components/FilterSection'
import { HeaderList } from './components/HeaderList'
import LendCard from './components/LendCard'
import { useLendPageContent } from './hooks'

import styles from './PlaceOffersContent.module.less'

const PlaceOfferContent = () => {
  const {
    marketsPreview,
    isLoading,
    searchQuery,
    setSearchQuery,
    sortParams,
    emptyMessage,
    tokenType,
    setTokenType,
    onToggleHotFilter,
    isHotFilterActive,
    hotMarkets,
  } = useLendPageContent()

  const [visibleMarketPubkey, setMarketPubkey] = useState('')

  const onLendCardClick = (marketPubkey: string) => {
    const isSameMarketPubkey = visibleMarketPubkey === marketPubkey
    const nextValue = !isSameMarketPubkey ? marketPubkey : ''
    return setMarketPubkey(nextValue)
  }

  const { data: markets, fetchMoreTrigger } = useFakeInfinityScroll({ rawData: marketsPreview })

  return (
    <div className={styles.pageWrapper}>
      <BreadcrumbHeader
        breadcrumbs={[{ title: 'Earn', path: PATHS.LEND }, { title: 'Markets' }]}
        onboardContentType="placeOffer"
      />
      <div className={styles.content}>
        <FilterSection
          sortParams={sortParams}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          selectedLendingToken={tokenType}
          handleSelectedTokenChange={setTokenType}
          isHotFilterActive={isHotFilterActive}
          onToggleHotFilter={onToggleHotFilter}
          hotMarkets={hotMarkets}
        />

        {!emptyMessage && !isLoading && <HeaderList />}
        {isLoading && <Loader />}
        {emptyMessage && <EmptyList message={emptyMessage} />}

        {!emptyMessage && (
          <div className={styles.marketsList}>
            {markets.map((market) => (
              <LendCard
                key={market.marketPubkey}
                market={market}
                onCardClick={() => onLendCardClick(market.marketPubkey)}
                isCardOpen={visibleMarketPubkey === market.marketPubkey}
              />
            ))}
            <div ref={fetchMoreTrigger} />
          </div>
        )}
      </div>
    </div>
  )
}

export default PlaceOfferContent
