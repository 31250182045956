import { useWallet } from '@solana/wallet-adapter-react'

import EmptyList from '@banx/components/EmptyList'
import { Loader } from '@banx/components/Loader'

import CollateralLoansCard from './components/CollateralLoansCard'
import { FilterSection } from './components/FilterSection'
import { HeaderList } from './components/HeaderList'
import { useLenderTokenLoansContent } from './hooks'

import styles from './LenderTokenLoansContent.module.less'

const LenderTokenLoansContent = () => {
  const { connected } = useWallet()

  const {
    loansPreviews,
    isLoading,
    terminatingLoansAmount,
    isTerminationFilterEnabled,
    toggleTerminationFilter,
    liquidatedLoansAmount,
    isLiquidatedFilterEnabled,
    toggleLiquidatedFilter,
    underwaterLoansAmount,
    isUnderwaterFilterEnabled,
    toggleUnderwaterFilter,
    expandedCollateralMint,
    handleCardToggle,
    searchSelectParams,
    sortParams,
  } = useLenderTokenLoansContent()

  const noData = !loansPreviews.length && !isLoading
  const showEmptyList = noData || !connected

  if (showEmptyList) return <EmptyList message="Connect wallet to see your loans" />

  return (
    <div className={styles.content}>
      <FilterSection
        searchSelectParams={searchSelectParams}
        sortParams={sortParams}
        terminatingLoansAmount={terminatingLoansAmount}
        liquidatedLoansAmount={liquidatedLoansAmount}
        isTerminationFilterEnabled={isTerminationFilterEnabled}
        toggleTerminationFilter={toggleTerminationFilter}
        isLiquidatedFilterEnabled={isLiquidatedFilterEnabled}
        toggleLiquidatedFilter={toggleLiquidatedFilter}
        underwaterLoansAmount={underwaterLoansAmount}
        toggleUnderwaterFilter={toggleUnderwaterFilter}
        isUnderwaterFilterEnabled={isUnderwaterFilterEnabled}
      />

      <HeaderList />

      {isLoading && <Loader />}

      {!noData && (
        <div className={styles.cardsList}>
          {loansPreviews.map((preview) => (
            <CollateralLoansCard
              key={preview.collateralMint}
              loansPreview={preview}
              onClick={() => handleCardToggle(preview.collateralMint)}
              isExpanded={expandedCollateralMint === preview.collateralMint}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default LenderTokenLoansContent
