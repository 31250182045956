import { useMemo } from 'react'

import { useWallet } from '@solana/wallet-adapter-react'
import { useQuery } from '@tanstack/react-query'
import produce from 'immer'
import { chain, maxBy } from 'lodash'
import { create } from 'zustand'

import { TokenLoan, fetchTokenLenderLoans } from '@banx/api/tokens'
import { useTokenType } from '@banx/store/common'

import { useLenderTokenLoansOptimistic } from './useLenderTokenLoansOptimistic'

interface HiddenLoansPubkeysState {
  pubkeys: string[]
  addLoansPubkeys: (pubkeys: string[]) => void
}

const useHiddenLoansPubkeys = create<HiddenLoansPubkeysState>((set) => ({
  pubkeys: [],
  addLoansPubkeys: (pubkeys) => {
    set(
      produce((state: HiddenLoansPubkeysState) => {
        state.pubkeys = pubkeys.map((pubkey) => pubkey)
      }),
    )
  },
}))

export const useLenderTokenLoans = () => {
  const { publicKey } = useWallet()
  const walletPubkey = publicKey?.toBase58() || ''

  const { tokenType } = useTokenType()

  const {
    loans: optimisticLoans,
    addLoans,
    findLoan,
    updateLoans,
  } = useLenderTokenLoansOptimistic()

  const { pubkeys: hiddenLoansPubkeys, addLoansPubkeys } = useHiddenLoansPubkeys()

  const { data: loans, isLoading } = useQuery(
    ['lenderTokenLoans', walletPubkey, tokenType],
    () => fetchTokenLenderLoans({ walletPublicKey: walletPubkey, tokenType }),
    {
      staleTime: 30 * 1000,
      refetchOnWindowFocus: false,
    },
  )

  const walletOptimisticLoans = useMemo(() => {
    if (!walletPubkey) return []
    return optimisticLoans.filter(({ wallet }) => wallet === walletPubkey)
  }, [optimisticLoans, walletPubkey])

  const mergedLoans = useMemo(() => {
    if (isLoading || !loans) {
      return []
    }

    return chain(loans)
      .concat(walletOptimisticLoans.map(({ loan }) => loan))
      .groupBy((loan) => loan.publicKey)
      .map((loans) => maxBy(loans, (loan) => loan.fraktBond.lastTransactedAt))
      .compact()
      .filter((loan) => !hiddenLoansPubkeys.includes(loan.publicKey))
      .value()
  }, [loans, isLoading, walletOptimisticLoans, hiddenLoansPubkeys])

  const updateOrAddLoan = (loan: TokenLoan) => {
    const loanExists = !!findLoan(loan.publicKey, walletPubkey)
    return loanExists ? updateLoans(loan, walletPubkey) : addLoans(loan, walletPubkey)
  }

  return {
    loans: mergedLoans,
    isLoading,
    updateOrAddLoan,
    addLoansPubkeys,
  }
}
