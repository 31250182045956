import { FC, SVGProps } from 'react'

export const Dexscreener: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.11883 6.13843C9.5165 5.94014 10.0225 5.64824 10.5291 5.24832C10.6359 5.47014 10.6476 5.66371 10.5926 5.82001C10.5536 5.93017 10.4801 6.02598 10.3835 6.1022C10.2788 6.18458 10.1481 6.24455 10.0037 6.27697C9.72952 6.33872 9.40946 6.30266 9.11883 6.13843ZM9.18777 8.17016L9.71418 8.47423C8.63934 9.07664 8.34715 10.1953 8.00005 11.2848C7.65299 10.1953 7.36075 9.07664 6.28595 8.47423L6.81237 8.17016C6.86329 8.15091 6.90682 8.11604 6.93671 8.07054C6.9666 8.02504 6.98132 7.97125 6.97877 7.91687C6.93054 6.8965 7.20605 6.44527 7.57768 6.16196C7.71097 6.06047 7.85648 6.00951 8.00005 6.00951C8.14361 6.00951 8.28912 6.06047 8.42246 6.16196C8.79409 6.44527 9.0696 6.8965 9.02137 7.91687C9.01882 7.97125 9.03354 8.02504 9.06343 8.07054C9.09332 8.11604 9.13685 8.15091 9.18777 8.17016ZM8.00005 1.50757C8.60853 1.52391 9.21854 1.64203 9.74794 1.87183C10.1145 2.03117 10.4566 2.24163 10.7664 2.49331C10.9062 2.60694 11.0214 2.7167 11.1483 2.84306C11.4905 2.85489 11.9906 2.47473 12.2228 2.119C11.8232 3.42897 9.99984 4.97592 8.73749 5.56786C8.73697 5.56764 8.73663 5.56734 8.73624 5.56708C8.50969 5.39409 8.25489 5.3076 8.00005 5.3076C7.7452 5.3076 7.49045 5.39409 7.2639 5.56708C7.26351 5.5673 7.26316 5.56768 7.26264 5.56786C6.00026 4.97592 4.17692 3.42897 3.77734 2.119C4.00948 2.47473 4.50959 2.85489 4.85179 2.84306C4.97872 2.71674 5.0939 2.60694 5.23373 2.49331C5.54348 2.24163 5.88555 2.03117 6.25215 1.87183C6.7816 1.64203 7.3916 1.52391 8.00005 1.50757ZM6.88127 6.13843C6.48364 5.94014 5.97759 5.64824 5.47103 5.24832C5.36421 5.47014 5.35251 5.66371 5.4075 5.82001C5.44654 5.93017 5.52004 6.02598 5.61663 6.1022C5.72132 6.18458 5.85201 6.24455 5.99649 6.27697C6.27061 6.33872 6.59063 6.30266 6.88127 6.13843Z"
        fill="#8C8C8C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0839 4.75816C11.3628 4.47671 11.6086 4.16519 11.8062 3.88721L11.9065 4.0761C12.2296 4.7225 12.3975 5.3663 12.3975 6.09084L12.3968 7.24056L12.4029 7.83656C12.4263 9.29976 12.7429 10.7802 13.46 12.1349L11.9595 10.925L10.8978 12.6479L9.78243 11.5979L8.00004 14.4903L6.21765 11.5979L5.1023 12.6479L4.04059 10.925L2.54004 12.135C3.25721 10.7802 3.5738 9.2998 3.59724 7.83661L3.60331 7.2406L3.60262 6.09088C3.60262 5.3663 3.77045 4.7225 4.09363 4.07614L4.19394 3.88725C4.39154 4.16523 4.63724 4.47671 4.91618 4.75821L4.82908 4.93912C4.65986 5.29051 4.60383 5.68342 4.73565 6.05708C4.82063 6.29775 4.97572 6.50415 5.17566 6.66171C5.36979 6.81472 5.59955 6.91785 5.84009 6.97202C5.99678 7.00729 6.15642 7.02181 6.31502 7.01687C6.27802 7.22665 6.26185 7.44422 6.26081 7.66592L4.84537 8.48344L5.93763 9.0957C6.02493 9.14465 6.10804 9.20072 6.18611 9.26336C7.08662 10.0719 7.62802 12.4637 8.00008 13.6321C8.37219 12.4637 8.91355 10.0719 9.8141 9.26336C9.89216 9.20071 9.97527 9.14464 10.0626 9.0957L11.1548 8.48344L9.73935 7.66592C9.73831 7.44422 9.72215 7.22665 9.68514 7.01687C9.84374 7.02181 10.0034 7.00729 10.1601 6.97202C10.4006 6.91785 10.6304 6.81472 10.8245 6.66171C11.0244 6.50415 11.1795 6.29775 11.2645 6.05708C11.3963 5.68342 11.3403 5.29056 11.1711 4.93912L11.084 4.75821L11.0839 4.75816Z"
        fill="#8C8C8C"
      />
    </g>
    <defs>
      <clipPath>
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
