import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'

import { MESSAGES } from '@banx/constants/messages'
import { useMarketTokenRewards } from '@banx/hooks'
import { PATHS } from '@banx/router'
import { buildUrlWithModeAndToken } from '@banx/store'
import { AssetMode, useTokenType } from '@banx/store/common'

import { useTokenMarketsPreview } from '../../hooks'
import { useFilteredMarkets } from './useFilteredMarkets'
import { useSortedMarkets } from './useSortedMarkets'

export const usePlaceTokenOffersContent = () => {
  const { tokenType, setTokenType } = useTokenType()
  const navigate = useNavigate()

  const { marketsPreview, isLoading } = useTokenMarketsPreview()

  const { allRewardsData } = useMarketTokenRewards('')

  const onCardClick = (marketPubkey: string) => {
    const pathname = `${PATHS.LEND_MARKERS}/${marketPubkey}`
    return navigate(buildUrlWithModeAndToken(pathname, AssetMode.Token, tokenType))
  }

  const {
    filteredMarkets,
    searchQuery,
    setSearchQuery,
    selectedCategory,
    onChangeCategory,
    isHotFilterActive,
    onToggleHotFilter,
    isDisabledHotFilter,
    isExtraRewardFilterActive,
    onToggleExtraRewardFilter,
    isDisabledExtraRewardFilter,
  } = useFilteredMarkets(marketsPreview, allRewardsData)

  const { sortedMarkets, sortParams } = useSortedMarkets(filteredMarkets)

  const isNoMarkets = !isLoading && isEmpty(marketsPreview)
  const isFilteredListEmpty = !isLoading && isEmpty(filteredMarkets)

  const emptyMessage = (() => {
    if (isNoMarkets) return MESSAGES.NO_MARKETS_AVAILABLE
    if (isFilteredListEmpty && searchQuery) return MESSAGES.EMPTY_SEARCH_RESULTS
    if (isFilteredListEmpty) return MESSAGES.EMPTY_FILTERED_LIST
    return
  })()

  return {
    marketsPreview: sortedMarkets,
    isLoading,
    onCardClick,
    emptyMessage,

    searchQuery,
    setSearchQuery,

    tokenType,
    setTokenType,

    selectedCategory,
    onChangeCategory,

    isHotFilterActive,
    onToggleHotFilter,
    isDisabledHotFilter,

    isExtraRewardFilterActive,
    onToggleExtraRewardFilter,
    isDisabledExtraRewardFilter,

    sortParams,
  }
}
