import { FC, SVGProps } from 'react'

export const HorizontalDots: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.25 11.9999C4.25 11.0334 5.0335 10.2499 6 10.2499C6.9665 10.2499 7.75 11.0334 7.75 11.9999C7.75 12.9664 6.9665 13.7499 6 13.7499C5.0335 13.7499 4.25 12.9664 4.25 11.9999ZM10.25 11.9999C10.25 11.0334 11.0335 10.2499 12 10.2499C12.9665 10.2499 13.75 11.0334 13.75 11.9999C13.75 12.9664 12.9665 13.7499 12 13.7499C11.0335 13.7499 10.25 12.9664 10.25 11.9999ZM16.25 11.9999C16.25 11.0334 17.0335 10.2499 18 10.2499C18.9665 10.2499 19.75 11.0334 19.75 11.9999C19.75 12.9664 18.9665 13.7499 18 13.7499C17.0335 13.7499 16.25 12.9664 16.25 11.9999Z"
      fill="#1B1B1B"
    />
  </svg>
)
