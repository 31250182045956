import { FC, SVGProps } from 'react'

export const Timer: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
    <path
      d="M7.99079 16.2411C12.2773 16.2411 15.7879 12.7305 15.7879 8.44392C15.7879 4.1649 12.2849 0.654297 7.99832 0.654297C7.53125 0.654297 7.26758 0.933036 7.26758 1.38504V4.15737C7.26758 4.54157 7.53878 4.84291 7.92299 4.84291C8.3072 4.84291 8.5784 4.54157 8.5784 4.15737V2.22126C11.7651 2.51507 14.221 5.18192 14.221 8.44392C14.221 11.8942 11.4562 14.6816 7.99079 14.6816C4.53292 14.6816 1.75307 11.8942 1.7606 8.44392C1.76814 6.95982 2.28795 5.58119 3.16183 4.51897C3.4481 4.12723 3.4933 3.71289 3.16936 3.38895C2.84542 3.06501 2.32561 3.09514 1.98661 3.53962C0.879185 4.88058 0.201172 6.59068 0.201172 8.44392C0.201172 12.7305 3.71177 16.2411 7.99079 16.2411ZM9.20368 9.60407C9.82143 8.94866 9.69336 8.05971 8.95508 7.55497L5.12807 4.92578C4.67606 4.62444 4.23912 5.06892 4.54046 5.52093L7.16964 9.3404C7.67438 10.0862 8.56334 10.2218 9.20368 9.60407Z"
      fill="#5D5FEF"
    />
  </svg>
)
