import { LendingTokenType } from 'fbonds-core/lib/fbond-protocol/types'

import Checkbox from '@banx/components/Checkbox'
import { ColumnType } from '@banx/components/Table'
import {
  CollateralTokenCell,
  DisplayValue,
  HeaderCell,
  HorizontalCell,
  createPercentValueJSX,
} from '@banx/components/TableComponents'

import { TokenLoan } from '@banx/api/tokens'
import { getTokenLoanSupply } from '@banx/utils'

import { TableColumnKey } from '../../constants'
import { SortColumnOption } from '../../hooks/useSortedLoans'
import { TokenLoanOptimistic } from '../../loansCart'
import { ActionsCell, ClaimCell, LTVCell, StatusCell } from './tableCells'

import styles from './ExpandedCardContent.module.less'

interface GetTableColumnsProps {
  findLoanInSelection: (loanPubkey: string) => TokenLoanOptimistic | null
  onRowClick: (loan: TokenLoan) => void
  onSelectAll: () => void
  hasSelectedLoans: boolean
  tokenType: LendingTokenType
  onSort: (value: SortColumnOption<TableColumnKey>) => void
  selectedSortOption: SortColumnOption<TableColumnKey>
}

export const getTableColumns = ({
  findLoanInSelection,
  onRowClick,
  onSelectAll,
  hasSelectedLoans,
  onSort,
  selectedSortOption,
}: GetTableColumnsProps) => {
  const columns: ColumnType<TokenLoan>[] = [
    {
      key: 'collateral',
      title: (
        <div className={styles.checkboxCell}>
          <Checkbox className={styles.checkbox} onChange={onSelectAll} checked={hasSelectedLoans} />
          <HeaderCell label="Collateral" className={styles.headerCellText} />
        </div>
      ),
      render: (loan) => {
        return (
          <CollateralTokenCell
            amount={getTokenLoanSupply(loan)}
            onCheckboxClick={() => onRowClick(loan)}
            selected={!!findLoanInSelection(loan.publicKey)}
            collateralPrice={loan.collateralPrice}
          />
        )
      },
    },
    {
      key: TableColumnKey.CLAIM,
      title: (
        <HeaderCell
          label="Claim"
          className={styles.headerCellText}
          columnKey={TableColumnKey.CLAIM}
          onSort={onSort}
          selectedSortOption={selectedSortOption}
        />
      ),
      render: (loan) => <ClaimCell loan={loan} />,
    },
    {
      key: TableColumnKey.LTV,
      title: (
        <HeaderCell
          label="LTV"
          className={styles.headerCellText}
          columnKey={TableColumnKey.LTV}
          onSort={onSort}
          selectedSortOption={selectedSortOption}
        />
      ),
      render: (loan) => <LTVCell loan={loan} />,
    },
    {
      key: TableColumnKey.REPAID,
      title: (
        <HeaderCell
          label="Repaid"
          className={styles.headerCellText}
          columnKey={TableColumnKey.REPAID}
          onSort={onSort}
          selectedSortOption={selectedSortOption}
        />
      ),
      render: (loan) => (
        <HorizontalCell
          value={<DisplayValue value={loan.bondTradeTransaction.lenderFullRepaidAmount} />}
        />
      ),
    },
    {
      key: TableColumnKey.APR,
      title: (
        <HeaderCell
          label="APR"
          className={styles.headerCellText}
          columnKey={TableColumnKey.APR}
          onSort={onSort}
          selectedSortOption={selectedSortOption}
        />
      ),
      render: (loan) => (
        <HorizontalCell
          value={createPercentValueJSX(loan.bondTradeTransaction.amountOfBonds / 100)}
          isHighlighted
        />
      ),
    },
    {
      key: TableColumnKey.STATUS,
      title: (
        <HeaderCell
          label="Status"
          className={styles.headerCellText}
          columnKey={TableColumnKey.STATUS}
          onSort={onSort}
          selectedSortOption={selectedSortOption}
        />
      ),
      render: (loan) => <StatusCell loan={loan} />,
    },
    {
      key: 'actionsCell',
      render: (loan) => <ActionsCell loan={loan} />,
    },
  ]

  return columns
}
