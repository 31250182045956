import { BN } from 'fbonds-core'
import { PUBKEY_PLACEHOLDER } from 'fbonds-core/lib/fbond-protocol/constants'
import { LendingTokenType } from 'fbonds-core/lib/fbond-protocol/types'

import Checkbox from '@banx/components/Checkbox'
import { ColumnType } from '@banx/components/Table'
import { DisplayValue, HeaderCell, createPercentValueJSX } from '@banx/components/TableComponents'

import { CollateralToken } from '@banx/api/tokens'

import { EnhancedBorrowOffer } from '../hooks'
import { AprCell, BorrowCell } from './cells'

import styles from './OrderBook.module.less'

type GetTableColumns = (props: {
  onSelectAll: () => void
  findSelectedOffer: (publicKey: string) => EnhancedBorrowOffer | null
  toggleOfferInSelection: (offer: EnhancedBorrowOffer) => void
  isCartEmpty: boolean
  remainingBorrowAmount: BN
  tokenType: LendingTokenType
  collateral: CollateralToken | undefined
}) => ColumnType<EnhancedBorrowOffer>[]

export const getTableColumns: GetTableColumns = ({
  onSelectAll,
  findSelectedOffer,
  toggleOfferInSelection,
  isCartEmpty,
  remainingBorrowAmount,
  collateral,
}) => {
  const columns: ColumnType<EnhancedBorrowOffer>[] = [
    {
      key: 'borrow',
      title: (
        <div className={styles.checkboxRow}>
          <Checkbox className={styles.checkbox} onChange={onSelectAll} checked={!isCartEmpty} />
          <HeaderCell label="To borrow" />
        </div>
      ),
      render: (offer) => {
        const selectedOffer = findSelectedOffer(offer.publicKey)

        return (
          <div className={styles.checkboxRow}>
            <Checkbox
              className={styles.checkbox}
              onChange={() => toggleOfferInSelection(offer)}
              checked={!!selectedOffer}
            />
            <BorrowCell
              offer={offer}
              selectedOffer={selectedOffer}
              remainingBorrowAmount={remainingBorrowAmount}
            />
          </div>
        )
      },
    },
    {
      key: 'apr',
      title: <HeaderCell label="APR" />,
      render: (offer) => (
        <AprCell offer={offer} marketPubkey={collateral?.marketPubkey ?? PUBKEY_PLACEHOLDER} />
      ),
    },
    {
      key: 'maxLtv',
      title: <HeaderCell label="Max ltv" />,
      render: (offer) => (
        <span className={styles.ltvValue}>
          {createPercentValueJSX(Math.trunc(parseFloat(offer.maxLtv) / 100))}
        </span>
      ),
    },
    {
      key: 'offerSize',
      title: <HeaderCell label="Offer size" />,
      render: (offer) => (
        <span className={styles.offerSizeValue}>
          <DisplayValue value={parseFloat(offer.maxTokenToGet)} />
        </span>
      ),
    },
  ]

  return columns
}
