import { FC } from 'react'

import EmptyList from '@banx/components/EmptyList'
import Table from '@banx/components/Table'

import { CollateralToken } from '@banx/api/tokens'

import { LeverageSimpleOffer } from '../../types'
import { getTableColumns } from './getTableColumns'

import styles from './OrderBook.module.less'

interface OrderBookProps {
  simpleOffers: LeverageSimpleOffer[]
  selectedOffer: LeverageSimpleOffer | undefined
  collateral: CollateralToken | undefined
  loading: boolean
}

export const OrderBook: FC<OrderBookProps> = ({
  simpleOffers,
  selectedOffer,
  collateral,
  loading,
}) => {
  const columns = getTableColumns({ collateral })

  const hasOffers = simpleOffers.length > 0
  const isLoading = !hasOffers && loading

  const rowParams = {
    activeRowParams: [
      {
        condition: (offer: LeverageSimpleOffer) =>
          offer.publicKey.toBase58() === selectedOffer?.publicKey.toBase58(),
        className: styles.selectedOffer,
      },
    ],
  }

  return (
    <div className={styles.container}>
      <Table
        data={simpleOffers}
        columns={columns}
        rowParams={rowParams}
        className={styles.table}
        classNameTableWrapper={styles.tableWrapper}
        loading={isLoading}
        loaderClassName={styles.loader}
        emptyMessage={
          hasOffers ? undefined : (
            <EmptyList message="There are no available offers at the moment" />
          )
        }
      />
    </div>
  )
}

export default OrderBook
