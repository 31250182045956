import { BN } from 'fbonds-core'
import { BASE_POINTS } from 'fbonds-core/lib/fbond-protocol/constants'

import { TokenLoan } from '@banx/api/tokens'
import { calcTokenLoanAprWithRepayFee, getTokenDecimals } from '@banx/utils'

import { calculateNetApr } from '../../LeveragePage'

export const calculateNetAprByLoan = (
  loan: TokenLoan,
  conversionRate: number,
  collateralYield: BN,
) => {
  const { decimals: collateralDecimals } = loan.collateral
  const tokenDecimals = Math.log10(getTokenDecimals(loan.bondTradeTransaction.lendingToken))
  const aprRate = calcTokenLoanAprWithRepayFee(loan)

  const leverage = loan.fraktBond.leverageBasePoints / BASE_POINTS / 100
  const userCollateralAmount = new BN(loan.fraktBond.fbondTokenSupply / leverage)

  return calculateNetApr({
    totalCollateralAmount: new BN(loan.fraktBond.fbondTokenSupply),
    userEnteredCollateralAmount: userCollateralAmount,
    conversionRate,
    collateralYield,
    aprRate,
    tokenDecimals,
    collateralDecimals,
  })
}
