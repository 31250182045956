import { FC, SVGProps } from 'react'

export const CardView: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.33536 3.79981H14.6641C15.0914 3.7998 15.4481 3.79979 15.7394 3.8236C16.0434 3.84843 16.3287 3.90212 16.5985 4.03959C17.0124 4.25051 17.349 4.58707 17.5599 5.00103C17.6974 5.27084 17.7511 5.55616 17.7759 5.86007C17.7997 6.15145 17.7997 6.50813 17.7997 6.93544V13.0642C17.7997 13.4915 17.7997 13.8482 17.7759 14.1395C17.7511 14.4434 17.6974 14.7288 17.5599 14.9986C17.349 15.4125 17.0124 15.7491 16.5985 15.96C16.3287 16.0975 16.0434 16.1512 15.7394 16.176C15.4481 16.1998 15.0914 16.1998 14.6641 16.1998H5.33535C4.90803 16.1998 4.55135 16.1998 4.25997 16.176C3.95606 16.1512 3.67074 16.0975 3.40093 15.96C2.98697 15.7491 2.65041 15.4125 2.43949 14.9986C2.30202 14.7288 2.24833 14.4434 2.2235 14.1395C2.19969 13.8482 2.1997 13.4915 2.19971 13.0642V6.93546C2.1997 6.50813 2.19969 6.15146 2.2235 5.86007C2.24833 5.55616 2.30202 5.27084 2.43949 5.00103C2.65041 4.58707 2.98697 4.25051 3.40093 4.03959C3.67074 3.90212 3.95606 3.84843 4.25997 3.8236C4.55136 3.79979 4.90804 3.7998 5.33536 3.79981ZM4.35769 5.01961C4.12799 5.03838 4.01817 5.07188 3.94572 5.1088C3.75755 5.20467 3.60457 5.35765 3.5087 5.54581C3.47179 5.61826 3.43828 5.72809 3.41951 5.95779C3.40017 6.19449 3.39971 6.50186 3.39971 6.95981V13.0398C3.39971 13.4977 3.40017 13.8051 3.41951 14.0418C3.43828 14.2715 3.47179 14.3813 3.5087 14.4538C3.60457 14.642 3.75755 14.7949 3.94572 14.8908C4.01817 14.9277 4.12799 14.9612 4.35769 14.98C4.59439 14.9993 4.90176 14.9998 5.35971 14.9998H14.6397C15.0976 14.9998 15.405 14.9993 15.6417 14.98C15.8714 14.9612 15.9812 14.9277 16.0537 14.8908C16.2419 14.7949 16.3948 14.642 16.4907 14.4538C16.5276 14.3813 16.5611 14.2715 16.5799 14.0418C16.5992 13.8051 16.5997 13.4977 16.5997 13.0398V6.95981C16.5997 6.50186 16.5992 6.19449 16.5799 5.95779C16.5611 5.72809 16.5276 5.61826 16.4907 5.54581C16.3948 5.35765 16.2419 5.20467 16.0537 5.1088C15.9812 5.07188 15.8714 5.03838 15.6417 5.01961C15.405 5.00027 15.0976 4.99981 14.6397 4.99981H5.35971C4.90176 4.99981 4.59439 5.00027 4.35769 5.01961Z"
      fill="black"
    />
  </svg>
)
