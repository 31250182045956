import { FC, SVGProps } from 'react'

export const Discord: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <g clipPath="url(#clip0_2810_7590)">
      <path
        d="M15.2378 3.28184C14.0905 2.75543 12.8602 2.36759 11.5739 2.14546C11.5505 2.14118 11.5271 2.15189 11.515 2.17332C11.3568 2.45473 11.1815 2.82185 11.0588 3.11041C9.6753 2.90328 8.29889 2.90328 6.94374 3.11041C6.82099 2.81544 6.63936 2.45473 6.48043 2.17332C6.46836 2.1526 6.44496 2.14189 6.42154 2.14546C5.13593 2.36688 3.90567 2.75472 2.7577 3.28184C2.74776 3.28613 2.73925 3.29327 2.73359 3.30255C0.400044 6.78883 -0.239213 10.1894 0.0743851 13.5478C0.075804 13.5643 0.0850274 13.58 0.0977985 13.59C1.63741 14.7206 3.12878 15.407 4.59246 15.862C4.61588 15.8691 4.6407 15.8606 4.65561 15.8413C5.00184 15.3685 5.31048 14.8699 5.57511 14.3456C5.59072 14.3149 5.57582 14.2785 5.5439 14.2664C5.05435 14.0807 4.5882 13.8542 4.1398 13.5971C4.10433 13.5764 4.10149 13.5257 4.13412 13.5014C4.22848 13.4307 4.32287 13.3571 4.41297 13.2828C4.42927 13.2693 4.45198 13.2664 4.47115 13.275C7.41696 14.6199 10.6062 14.6199 13.5172 13.275C13.5364 13.2657 13.5591 13.2685 13.5761 13.2821C13.6662 13.3564 13.7606 13.4307 13.8557 13.5014C13.8883 13.5257 13.8862 13.5764 13.8507 13.5971C13.4023 13.8592 12.9361 14.0807 12.4459 14.2656C12.414 14.2778 12.3998 14.3149 12.4154 14.3456C12.6857 14.8692 12.9943 15.3677 13.3342 15.8406C13.3484 15.8606 13.3739 15.8691 13.3973 15.862C14.8681 15.407 16.3595 14.7206 17.8991 13.59C17.9126 13.58 17.9211 13.565 17.9225 13.5485C18.2978 9.66583 17.2939 6.29314 15.2612 3.30326C15.2562 3.29328 15.2477 3.28613 15.2378 3.28184Z"
        fill="#8C8C8C"
      />
      <path
        d="M4.39723 9.68853C4.39723 10.6885 5.12801 11.5027 6.0149 11.5027C6.91594 11.5027 7.63254 10.6885 7.63254 9.68853C7.64674 8.69571 6.92304 7.87433 6.0149 7.87433C5.11383 7.87433 4.39723 8.68856 4.39723 9.68853Z"
        fill="#FEFEFF"
      />
      <path
        d="M10.3783 9.68853C10.3783 10.6885 11.1091 11.5027 11.9959 11.5027C12.9041 11.5027 13.6136 10.6885 13.6136 9.68853C13.6278 8.69571 12.9041 7.87433 11.9959 7.87433C11.0949 7.87433 10.3783 8.68856 10.3783 9.68853Z"
        fill="#FEFEFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_2810_7590">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
