import { FC, SVGProps } from 'react'

export const TableView: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.19922 5.2002C2.19922 4.64791 2.64693 4.2002 3.19922 4.2002C3.7515 4.2002 4.19922 4.64791 4.19922 5.2002C4.19922 5.75248 3.7515 6.2002 3.19922 6.2002C2.64693 6.2002 2.19922 5.75248 2.19922 5.2002ZM6.19922 5.20069C6.19924 4.86932 6.46788 4.60071 6.79925 4.60073L17.1992 4.60131C17.5306 4.60133 17.7992 4.86997 17.7992 5.20134C17.7992 5.53271 17.5305 5.80133 17.1992 5.80131L6.79919 5.80073C6.46781 5.80071 6.1992 5.53207 6.19922 5.20069ZM2.19922 10.0002C2.19922 9.44791 2.64693 9.0002 3.19922 9.0002C3.7515 9.0002 4.19922 9.44791 4.19922 10.0002C4.19922 10.5525 3.7515 11.0002 3.19922 11.0002C2.64693 11.0002 2.19922 10.5525 2.19922 10.0002ZM6.19922 10.0007C6.19924 9.66937 6.46788 9.40075 6.79925 9.40077L17.1993 9.4014C17.5307 9.40142 17.7993 9.67006 17.7993 10.0014C17.7992 10.3328 17.5306 10.6014 17.1992 10.6014L6.79918 10.6008C6.46781 10.6008 6.1992 10.3321 6.19922 10.0007ZM2.19922 14.8002C2.19922 14.2479 2.64693 13.8002 3.19922 13.8002C3.7515 13.8002 4.19922 14.2479 4.19922 14.8002C4.19922 15.3525 3.7515 15.8002 3.19922 15.8002C2.64693 15.8002 2.19922 15.3525 2.19922 14.8002ZM6.19922 14.8007C6.19924 14.4694 6.46788 14.2008 6.79925 14.2008L17.1993 14.2014C17.5306 14.2014 17.7993 14.4701 17.7992 14.8014C17.7992 15.1328 17.5306 15.4014 17.1992 15.4014L6.79918 15.4008C6.46781 15.4008 6.1992 15.1321 6.19922 14.8007Z"
      fill="black"
    />
  </svg>
)
