import { FC, SVGProps } from 'react'

export const Pencil: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.76986 1.37486C8.59412 1.31776 8.40481 1.31776 8.22908 1.37486C8.10616 1.41479 8.00963 1.48314 7.92812 1.55234C7.85251 1.61652 7.76891 1.70014 7.67788 1.79118L6.82177 2.64729L9.3521 5.17762L10.2082 4.32148C10.2993 4.23046 10.3829 4.14687 10.4471 4.07127C10.5163 3.98976 10.5846 3.89323 10.6245 3.77031C10.6816 3.59458 10.6816 3.40527 10.6245 3.22953C10.5846 3.10662 10.5163 3.01009 10.4471 2.92858C10.3829 2.85298 10.2993 2.76938 10.2082 2.67835L9.32105 1.79118C9.23002 1.70013 9.14642 1.61652 9.07081 1.55234C8.9893 1.48314 8.89277 1.41479 8.76986 1.37486ZM8.67713 5.85259L6.1468 3.32226L2.4194 7.04966L2.39529 7.07376C2.16361 7.30539 2.01422 7.45474 1.89384 7.62734C1.7872 7.78024 1.69894 7.94517 1.63087 8.11871C1.55403 8.31462 1.51263 8.52176 1.44842 8.84301L1.44173 8.87645L1.24687 9.85075C1.22941 9.93797 1.2117 10.0265 1.20364 10.1017C1.1951 10.1814 1.19029 10.2985 1.24063 10.4202C1.30407 10.5735 1.42588 10.6953 1.57922 10.7588C1.70089 10.8091 1.818 10.8043 1.89768 10.7958C1.97292 10.7877 2.06141 10.77 2.14862 10.7525L3.12294 10.5577L3.15637 10.551C3.47763 10.4868 3.68477 10.4454 3.88068 10.3685C4.05422 10.3005 4.21915 10.2122 4.37205 10.1055C4.54465 9.98517 4.69401 9.83578 4.92563 9.60409L4.94973 9.57999L8.67713 5.85259Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_19567_290487">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
