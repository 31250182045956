import { FC, SVGProps } from 'react'

export const ArrowDown: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9995 3.25061C12.4137 3.25061 12.7495 3.5864 12.7495 4.00061V18.19L15.4692 15.4703C15.7621 15.1774 16.2369 15.1774 16.5298 15.4703C16.8227 15.7632 16.8227 16.238 16.5298 16.5309L12.5298 20.5309C12.3892 20.6716 12.1984 20.7506 11.9995 20.7506C11.8006 20.7506 11.6098 20.6716 11.4692 20.5309L7.46918 16.5309C7.17629 16.238 7.17629 15.7632 7.46918 15.4703C7.76207 15.1774 8.23695 15.1774 8.52984 15.4703L11.2495 18.19V4.00061C11.2495 3.5864 11.5853 3.25061 11.9995 3.25061Z"
      fill="#CECECE"
    />
  </svg>
)
