import { FC } from 'react'

import classNames from 'classnames'
import { LendingTokenType } from 'fbonds-core/lib/fbond-protocol/types'

import { Button } from '@banx/components/Buttons'
import {
  FilterDropdown,
  MARKET_OPTIONS_WITHOUT_ALL,
  TokenDropdown,
} from '@banx/components/Dropdowns'
import { Search } from '@banx/components/Search'
import { SortDropdown, SortDropdownProps } from '@banx/components/SortDropdown'
import { TooltipWrapper } from '@banx/components/Tooltip'

import { core } from '@banx/api/nft'
import { Fire } from '@banx/icons'

import { SortField } from '../../hooks'

import styles from './FilterSection.module.less'

interface FilterSectionProps {
  sortParams: SortDropdownProps<SortField>
  searchQuery: string
  setSearchQuery: (query: string) => void
  selectedLendingToken: LendingTokenType
  handleSelectedTokenChange: (value: LendingTokenType) => void
  onToggleHotFilter: () => void
  isHotFilterActive: boolean
  hotMarkets: core.MarketPreview[]
}

const FilterSection: FC<FilterSectionProps> = ({
  sortParams,
  searchQuery,
  setSearchQuery,
  onToggleHotFilter,
  isHotFilterActive,
  hotMarkets,
  selectedLendingToken,
  handleSelectedTokenChange,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.filterWrapper}>
        <TokenDropdown
          option={selectedLendingToken}
          options={MARKET_OPTIONS_WITHOUT_ALL}
          onChange={handleSelectedTokenChange}
        />
        <FilterDropdown>
          <div className={styles.filterButtonsContainer}>
            <span className={styles.filterButtonsTitle}>Tags</span>
            <HotFilterButton
              isActive={isHotFilterActive}
              isDisabled={!hotMarkets?.length}
              onClick={onToggleHotFilter}
            />
          </div>
        </FilterDropdown>
        <Search value={searchQuery} onChange={setSearchQuery} />
      </div>
      <SortDropdown {...sortParams} />
    </div>
  )
}

export default FilterSection

interface FilterButtonProps {
  isActive: boolean
  isDisabled: boolean
  onClick: () => void
}

const HotFilterButton: FC<FilterButtonProps> = ({ isActive, isDisabled, onClick }) => (
  <TooltipWrapper title={isDisabled ? 'No hot collections currently' : 'Hot collections'}>
    <Button
      className={classNames(styles.filterButton, {
        [styles.active]: isActive,
        [styles.disabled]: isDisabled,
      })}
      onClick={onClick}
      disabled={isDisabled}
      variant="tertiary"
      type="circle"
    >
      <Fire />
      Hot
    </Button>
  </TooltipWrapper>
)
