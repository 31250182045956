import { useMemo, useState } from 'react'

import { filter, size } from 'lodash'

import { filterBySearchQuery } from '@banx/components/Search'

import { core } from '@banx/api/nft'
import { isLoanRepaymentCallActive, isLoanTerminating } from '@banx/utils'

export const useFilterLoans = (loans: core.Loan[]) => {
  const [isTerminationFilterEnabled, setTerminationFilterState] = useState(false)
  const [isRepaymentCallFilterEnabled, setIsRepaymentCallFilterState] = useState(false)

  const [searchQuery, setSearchQuery] = useState<string>('')

  const toggleTerminationFilter = () => {
    setIsRepaymentCallFilterState(false)
    setTerminationFilterState(!isTerminationFilterEnabled)
  }

  const toggleRepaymentCallFilter = () => {
    setTerminationFilterState(false)
    setIsRepaymentCallFilterState(!isRepaymentCallFilterEnabled)
  }

  const filteredBySearchQuery = useMemo(() => {
    return filterBySearchQuery(loans, searchQuery, [
      (loan) => loan.nft.meta.collectionName,
      (loan) => loan.nft.meta.name,
      (loan) => loan.nft.mint,
    ])
  }, [loans, searchQuery])

  const filteredLoans = useMemo(() => {
    if (isTerminationFilterEnabled) return filter(filteredBySearchQuery, isLoanTerminating)
    if (isRepaymentCallFilterEnabled)
      return filter(filteredBySearchQuery, isLoanRepaymentCallActive)

    return filteredBySearchQuery
  }, [isTerminationFilterEnabled, isRepaymentCallFilterEnabled, filteredBySearchQuery])

  const terminatingLoansAmount = useMemo(
    () => size(filter(filteredBySearchQuery, isLoanTerminating)) || null,
    [filteredBySearchQuery],
  )

  const repaymentCallsAmount = useMemo(
    () => size(filter(filteredBySearchQuery, isLoanRepaymentCallActive)) || null,
    [filteredBySearchQuery],
  )

  return {
    filteredLoans,

    searchQuery,
    setSearchQuery,

    terminatingLoansAmount,
    repaymentCallsAmount,

    isTerminationFilterEnabled,
    toggleTerminationFilter,

    isRepaymentCallFilterEnabled,
    toggleRepaymentCallFilter,
  }
}
