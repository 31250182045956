import { ComponentType, FC } from 'react'

import { useLocation } from 'react-router-dom'

import { AdventuresPage, DashboardPage, LeaderboardPage } from '@banx/pages/common'
import * as Page from '@banx/pages/nftLending'
import * as TokenPage from '@banx/pages/tokenLending'
import { getAssetModeFromUrl } from '@banx/store'
import { AssetMode } from '@banx/store/common'

import { PATHS } from './paths'

interface Route {
  path: string
  component: FC
}

interface AssetModeComponentProps {
  nftView: ComponentType
  tokenView: ComponentType
}

const AssetModeComponent: FC<AssetModeComponentProps> = ({
  nftView: NftView,
  tokenView: TokenView,
}) => {
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const assetMode = getAssetModeFromUrl(urlParams)

  return assetMode === AssetMode.Token ? <TokenView /> : <NftView />
}

const createRoute = (path: string, nftView: ComponentType, tokenView: ComponentType) => ({
  path,
  component: () => <AssetModeComponent nftView={nftView} tokenView={tokenView} />,
})

export const routes: Route[] = [
  //? Leverage Routes
  { path: PATHS.LEVERAGE, component: TokenPage.LeveragePage },
  { path: PATHS.LEVERAGE_BASE, component: TokenPage.LeverageLanding },

  //? Borrow Routes
  createRoute(PATHS.BORROW, Page.BorrowPage, TokenPage.BorrowTokenPage),

  //? Lend Routes
  createRoute(PATHS.LEND, Page.LendLandingPage, TokenPage.LendLandingPage),
  createRoute(PATHS.LEND_VAULTS, Page.LendLandingPage, TokenPage.LendVaultsPage),
  createRoute(PATHS.LEND_VAULT, Page.LendLandingPage, TokenPage.LendVaultPage),
  createRoute(PATHS.LEND_MARKERS, Page.LendPlaceOfferPage, TokenPage.TokenMarketsPage),
  createRoute(PATHS.LEND_MARKET, Page.LendLoanMarketPage, TokenPage.TokenMarketPage),
  createRoute(PATHS.LEND_LOANS_MARKET, Page.LendLoanMarketPage, TokenPage.TokenLoansMarketPage),

  //? Offers Routes
  createRoute(PATHS.OFFERS, Page.OffersPage, TokenPage.TokenOffersPage),

  //? Loans Routes
  createRoute(PATHS.LOANS, Page.LoansPage, TokenPage.TokenLoansPage),

  //? Positions Routes
  createRoute(PATHS.POSITIONS, Page.BorrowPage, TokenPage.TokenPositionsPage),

  //? Common Routes
  { path: PATHS.ROOT, component: TokenPage.LeverageLanding },
  { path: PATHS.PORTFOLIO, component: DashboardPage },
  { path: PATHS.ADVENTURES, component: AdventuresPage },
  { path: PATHS.LEADERBOARD, component: LeaderboardPage },

  //? Fallback Routes
  { path: PATHS.PAGE_404, component: TokenPage.LeverageLanding }, //TODO: Implement 404 page
  { path: '*', component: TokenPage.LeverageLanding },
]
