import { useEffect } from 'react'

import { BreadcrumbHeader } from '@banx/components/BreadcrumbHeader'
import { Tabs, useTabs } from '@banx/components/Tabs'

import { PATHS } from '@banx/router'

import { LoansActiveTable } from './components/LoansActiveTable'
import { LoansHistoryTable } from './components/LoansHistoryTable'
import { RequestsTable } from './components/RequestLoansTable'
import { LOANS_TABS, LoansTabsNames } from './constants'
import { useLoansTabs } from './hooks'

import styles from './LoansPage.module.less'

export const LoansPage = () => {
  //? Used to set default tab when user is redirected to LoansPage.
  const { tab: storeTab, setTab } = useLoansTabs()

  const { value: currentTabValue, ...tabsProps } = useTabs({
    tabs: LOANS_TABS,
    defaultValue: storeTab ?? LoansTabsNames.LOANS,
  })

  //? Used hook to reset store when the component is unmounted
  useEffect(() => {
    if (!storeTab) return

    return () => setTab(null)
  }, [setTab, storeTab])

  return (
    <div className={styles.pageWrapper}>
      <BreadcrumbHeader
        breadcrumbs={[{ title: 'Portfolio', path: PATHS.PORTFOLIO }, { title: 'My loans' }]}
        onboardContentType="loans"
      />
      <Tabs value={currentTabValue} {...tabsProps} type="secondary" />
      {currentTabValue === LoansTabsNames.LOANS && <LoansActiveTable />}
      {currentTabValue === LoansTabsNames.REQUESTS && <RequestsTable />}
      {currentTabValue === LoansTabsNames.HISTORY && <LoansHistoryTable />}
    </div>
  )
}
