import { FC, useCallback, useEffect, useMemo } from 'react'

import { BN } from 'fbonds-core'

import Table from '@banx/components/Table'

import { CollateralToken } from '@banx/api/tokens'
import { useTokenType } from '@banx/store/common'
import { getTokenDecimals } from '@banx/utils'

import { calculateRemainingAmount } from '../helpers'
import { EnhancedBorrowOffer } from '../hooks'
import { useSelectedOffers } from '../hooks/useSelectedOffers'
import { getTableColumns } from './columns'
import { distributeBorrowAmountToOffers, getMaxBorrowableAmount } from './helpers'

import styles from './OrderBook.module.less'

interface OrderBookProps {
  offers: EnhancedBorrowOffer[]
  requiredBorrowAmount: BN
  collateral: CollateralToken | undefined
  loading: boolean
}

const OrderBook: FC<OrderBookProps> = ({ offers, requiredBorrowAmount, collateral, loading }) => {
  const { tokenType } = useTokenType()

  const marketTokenDecimals = Math.log10(getTokenDecimals(tokenType)) //? 1e9 => 9, 1e6 => 6

  const {
    selectedOffers,
    toggleSelectedOffer,
    findSelectedOffer,
    clearSelection,
    setSelection,
    setOffersData,
  } = useSelectedOffers()

  useEffect(() => {
    if (requiredBorrowAmount.isZero() || !offers.length) return
    setOffersData(offers, requiredBorrowAmount)
  }, [offers, requiredBorrowAmount, setOffersData])

  useEffect(() => {
    if (!collateral || !offers.length) return

    const activeOffers = offers.filter((offer) => !offer.disabled)

    const maxBorrowableAmount = getMaxBorrowableAmount({
      offers: activeOffers,
      collateralAmount: collateral.amountInWallet,
      tokenDecimals: marketTokenDecimals,
    })

    const updatedOffers = distributeBorrowAmountToOffers({
      borrowAmount: BN.min(requiredBorrowAmount, maxBorrowableAmount),
      offers: activeOffers,
      tokenDecimals: marketTokenDecimals,
    })

    if (requiredBorrowAmount.isZero()) {
      setSelection([])
      return
    }

    setSelection(updatedOffers)
  }, [requiredBorrowAmount, collateral, marketTokenDecimals, offers, clearSelection, setSelection])

  //? Clear selection when tokenType changes
  //? To prevent selection transfering from one tokenType to another
  useEffect(() => {
    clearSelection()
  }, [clearSelection, tokenType, collateral])

  const isCartEmpty = !selectedOffers?.length

  const onSelectAll = useCallback(() => {
    if (!isCartEmpty) return clearSelection()

    const offersToUpdate = offers.filter((offer) => !offer.disabled)
    const updatedOffers = distributeBorrowAmountToOffers({
      borrowAmount: requiredBorrowAmount,
      offers: offersToUpdate,
      tokenDecimals: marketTokenDecimals,
    })

    setSelection(updatedOffers)
  }, [isCartEmpty, clearSelection, requiredBorrowAmount, offers, marketTokenDecimals, setSelection])

  const remainingBorrowAmount = useMemo(() => {
    return calculateRemainingAmount(selectedOffers, requiredBorrowAmount)
  }, [selectedOffers, requiredBorrowAmount])

  const onRowClick = useCallback(
    (offer: EnhancedBorrowOffer) => {
      if (!findSelectedOffer(offer.publicKey) && remainingBorrowAmount.isZero()) return

      const updatedOffer = distributeBorrowAmountToOffers({
        borrowAmount: remainingBorrowAmount,
        offers: [offer],
        tokenDecimals: marketTokenDecimals,
      })[0]

      return toggleSelectedOffer(updatedOffer)
    },
    [findSelectedOffer, marketTokenDecimals, remainingBorrowAmount, toggleSelectedOffer],
  )

  const columns = getTableColumns({
    onSelectAll,
    findSelectedOffer,
    toggleOfferInSelection: onRowClick,
    isCartEmpty,
    remainingBorrowAmount,
    tokenType,
    collateral,
  })

  const rowParams = useMemo(() => {
    return {
      onRowClick,
      activeRowParams: [
        {
          condition: (offer: EnhancedBorrowOffer) => offer.disabled,
          className: styles.disabledOffer,
        },
      ],
    }
  }, [onRowClick])

  const hasOffers = offers.length > 0
  const isLoading = !hasOffers && loading

  return (
    <div className={styles.container}>
      <Table
        data={offers}
        columns={columns}
        rowParams={rowParams}
        className={styles.table}
        classNameTableWrapper={styles.tableWrapper}
        loading={isLoading}
        loaderClassName={styles.loader}
        emptyMessage={hasOffers ? undefined : 'There are no available offers at the moment'}
      />
    </div>
  )
}

export default OrderBook
